import React from 'react'
import { Typography, Box, ButtonBase, Tooltip } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SetupDashboardSchemaType } from 'schemas/userDeviceSettings'

export type SetupDashboardDeviceCardTypes = {
  // eslint-disable-next-line
  form: UseFormReturn<SetupDashboardSchemaType, any>
  index: number
}

const SetupDashboardDeviceCard: React.FC<SetupDashboardDeviceCardTypes> = ({
  form: { watch, setValue },
  index
}: SetupDashboardDeviceCardTypes) => {
  const { t } = useTranslation()
  const devicesSettings = watch('user_device_settings')
  const deviceSettings = devicesSettings?.[index]
  const showInDashboard = deviceSettings?.show_in_dashboard
  const bgcolor = showInDashboard === true ? 'success.main' : 'error.main'
  const toggleShowInDashboardValue = showInDashboard === true ? false : true
  const toggleShowInDashboardTooltip =
    showInDashboard === true ? 'hide' : 'show'

  return (
    <Tooltip title={t(toggleShowInDashboardTooltip)}>
      <ButtonBase
        onClick={() => {
          setValue(
            `user_device_settings.${index}.show_in_dashboard`,
            toggleShowInDashboardValue
          )
        }}
      >
        <Box bgcolor={bgcolor}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            px={1}
            minWidth={150}
            minHeight={50}
          >
            <Typography variant='caption' component='div'>
              {deviceSettings?.device?.name}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    </Tooltip>
  )
}

export default SetupDashboardDeviceCard
