import React, { Fragment, useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Skeleton,
  LinearProgress,
  Grid,
  Divider
} from '@mui/material'
import {
  Memory as MemoryIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash.groupby'
import { format, differenceInSeconds } from 'date-fns'

import { LineChartMetric, MuiLink, PieChartCard } from 'components'
import {
  Agent_Type_Enum,
  GetDeviceStatusSubscription,
  Metric_Type_Enum
} from 'api/generated'
import { dateUtils, metricUtils } from 'utils'

type DeviceMetricChartsProps = {
  data: GetDeviceStatusSubscription | undefined
  loading: boolean
}

const DeviceMetricCharts: React.FC<DeviceMetricChartsProps> = ({
  data,
  loading
}: DeviceMetricChartsProps) => {
  const { t } = useTranslation()
  const [durationTimeInSeconds, setDurationTimeInSeconds] = useState<
    number | null
  >(null)
  const device = data?.device
  const isOn = !!device?.cooks?.length
  const cook = device?.cooks?.[0]
  const [currentStep] = cook?.currentStep || []
  const recipeStepsCount =
    cook?.recipe?.recipe_steps_aggregate?.aggregate?.count || 0
  const agentsGroupedByType = groupBy(device?.agents, 'type')
  const diskUsagePercentage =
    ((device?.info?.total_disk_used || 0) * 100) /
    (device?.info?.total_disk || 0)
  const ramUsagePercentage =
    ((device?.info?.total_ram_used || 0) * 100) / (device?.info?.total_ram || 0)
  const cpuUsagePercentage = device?.info?.cpu_usage_percentage
  const pieChartRamData = [
    {
      name: `${t('used')} ${
        ramUsagePercentage ? `${Math.round(ramUsagePercentage)}%` : ''
      }`,
      value: device?.info?.total_ram_used || 0
    },
    {
      name: `${t('available')} ${
        ramUsagePercentage ? `${100 - Math.round(ramUsagePercentage)}%` : ''
      }`,
      value: device?.info?.total_ram_available || 0
    }
  ]
  const pieChartDiskData = [
    {
      name: `${t('used')} ${
        diskUsagePercentage ? `${Math.round(diskUsagePercentage)}%` : ''
      }`,
      value: device?.info?.total_disk_used || 0
    },
    {
      name: `${t('available')} ${
        diskUsagePercentage ? `${100 - Math.round(diskUsagePercentage)}%` : ''
      }`,
      value: device?.info?.total_disk_available || 0
    }
  ]
  const pieCharyCpuData = [
    { name: t('used'), value: cpuUsagePercentage || 0 },
    {
      name: t('available'),
      value: 100 - (cpuUsagePercentage || 0)
    }
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if (cook?.started_at) {
        const difference = differenceInSeconds(
          new Date(),
          new Date(cook.started_at)
        )

        setDurationTimeInSeconds(difference)
      } else {
        setDurationTimeInSeconds(null)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [cook])

  return (
    <>
      {loading ? (
        [...Array(4)].map((_time, key) => {
          return (
            <Box key={key} width='100%' height={250} mb={1}>
              <Skeleton variant='rectangular' width='100%' height={250} />
            </Box>
          )
        })
      ) : (
        <Fragment>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography variant='h5'>{device?.name}</Typography>

            {durationTimeInSeconds ? (
              <Typography variant='subtitle1'>
                {`${t('time')} ${dateUtils.formatDurationTime(
                  durationTimeInSeconds,
                  t
                )}`}
              </Typography>
            ) : null}
          </Box>

          {isOn ? (
            <>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                mb={2}
              >
                <Typography
                  variant='subtitle1'
                  component={MuiLink}
                  to={`/recipes/${cook?.recipe?.id}`}
                >
                  {`${t('recipe')}: ${cook?.recipe?.name}`}
                </Typography>

                <Typography variant='subtitle1'>
                  {`${t('step')} ${
                    recipeStepsCount > 0 ? currentStep?.recipe_step?.step : '∞'
                  }`}
                </Typography>
              </Box>
              <LinearProgress color='error' />
            </>
          ) : null}

          <Box display='flex' mt={2} mb={1}>
            <Typography variant='body2'>{t('monitor')}</Typography>
          </Box>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <PieChartCard
                title={
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='body2'>{t('cpu')}</Typography>
                    <MemoryIcon />
                  </Box>
                }
                data={pieCharyCpuData}
                label={data => `${Math.round(data?.value)}%`}
                unit='%'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <PieChartCard
                title={
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='body2'>{t('ram')}</Typography>
                    <SpeedIcon />
                  </Box>
                }
                data={pieChartRamData}
                label={data => `${data?.value}gb`}
                unit='gb'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <PieChartCard
                title={
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='body2'>{t('disk')}</Typography>
                    <StorageIcon />
                  </Box>
                }
                data={pieChartDiskData}
                label={data => `${data?.value}gb`}
                unit='gb'
              />
            </Grid>
          </Grid>

          {Object.keys(agentsGroupedByType).map(type => {
            const agents = agentsGroupedByType[type]
            const noHasAverage = [
              Agent_Type_Enum.Relay,
              Agent_Type_Enum.EzoHum
            ].includes(type as Agent_Type_Enum)

            const agentsMetrics: number[] = []

            if (!noHasAverage) {
              agents?.map(({ metrics }) => {
                metrics.map(({ value }) => {
                  agentsMetrics.push(value)
                })
              })
            }

            const average =
              noHasAverage || !agentsMetrics?.length
                ? null
                : metricUtils.getAverage(agentsMetrics)?.toFixed(2)

            return (
              <Fragment key={type}>
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'
                  mt={2}
                  mb={1}
                >
                  <Typography variant='body2'>{t(type)}</Typography>
                  {average ? (
                    <Typography variant='body2'>{`${t(
                      'average'
                    )}: ${average}`}</Typography>
                  ) : null}
                </Box>

                <Grid container spacing={2} mb={2}>
                  {agents.map(agent => {
                    const metricsGroupedByType = Object.fromEntries(
                      Object.entries(groupBy(agent?.metrics, 'type')).sort()
                    )

                    return (
                      <Fragment key={agent.id}>
                        {Object.keys(metricsGroupedByType).map(type => {
                          const metrics = metricsGroupedByType?.[type]
                          const lineChartData = metrics
                            ?.map(({ created_at, value }) => {
                              return {
                                time: format(new Date(created_at), 'HH:mm:ss'),
                                [t(type)]: value,
                                createdAt: created_at
                              }
                            })
                            ?.sort(
                              (a, b) =>
                                new Date(a.createdAt).getTime() -
                                new Date(b.createdAt).getTime()
                            )

                          return (
                            <Grid key={type} item xs={12} sm={6} lg={4}>
                              <LineChartMetric
                                key={type}
                                data={agent}
                                lineChartData={lineChartData}
                                type={type as Metric_Type_Enum}
                                withLink
                              />
                            </Grid>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </Grid>
                <Divider />
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </>
  )
}

export default DeviceMetricCharts
