import React from 'react'
import { CardHeader, CardContent, Typography, Box } from '@mui/material'

import { MuiLink, MuiCard, LineChart, MetricIcon } from 'components'
import { LineChartData } from 'components/LineChart'
import { AgentStatusFragmentFragment, Metric_Type_Enum } from 'api/generated'
import { useTranslation } from 'react-i18next'

type LineChartMetricProps = {
  data?: AgentStatusFragmentFragment
  lineChartData: LineChartData[]
  type: Metric_Type_Enum
  withLink?: boolean
}

const LineChartMetric: React.FC<LineChartMetricProps> = ({
  data,
  lineChartData,
  type,
  withLink = false
}: LineChartMetricProps) => {
  const { t } = useTranslation()

  return (
    <MuiCard>
      <CardHeader
        title={
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            {withLink ? (
              <Typography
                variant='body2'
                component={MuiLink}
                to={`/agents/${data?.id}/metrics`}
              >
                {data?.name}
              </Typography>
            ) : (
              <Typography variant='body2'>{t(type)}</Typography>
            )}

            <MetricIcon type={type} />
          </Box>
        }
      />

      <CardContent>
        <LineChart data={lineChartData} type={type} />
      </CardContent>
    </MuiCard>
  )
}

export default LineChartMetric
