import React, { useState, useEffect, useCallback } from 'react'
import {
  CardHeader,
  CardContent,
  Box,
  Typography,
  CardActions,
  Stack,
  Divider,
  LinearProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
  Sync as SyncIcon,
  Power as PowerIcon,
  PowerOff as PowerOffIcon,
  Memory as MemoryIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon
} from '@mui/icons-material'

import groupBy from 'lodash.groupby'
import { differenceInSeconds } from 'date-fns'
import { httpsCallable } from 'firebase/functions'

import {
  DevicesStatusFragmentFragment,
  Agent_Type_Enum,
  Metric_Type_Enum,
  User_Role_Enum
} from 'api/generated'
import {
  MuiLink,
  MuiButton,
  MuiCard,
  MuiSnackbar,
  ProtectedComponent,
  MetricIcon,
  MuiCircularProgress,
  PaperMetric
} from 'components'
import { dateUtils, metricUtils } from 'utils'
import firebaseLib from 'lib/firebase'

type DeviceListItemProps = {
  data: { __typename?: 'device' } & DevicesStatusFragmentFragment
  start(id: string): void
  stop(id: string): void
}

const DeviceListItem: React.FC<DeviceListItemProps> = ({
  data,
  start,
  stop
}: DeviceListItemProps) => {
  const { t } = useTranslation()
  const [durationTimeInSeconds, setDurationTimeInSeconds] = useState<
    number | null
  >(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [isEmittingMetrics, setIsEmittingMetrics] = useState(false)
  const isOn = !!data?.cooks?.length
  const agentsGroupedByType = groupBy(data.agents, 'type')
  const cook = data?.cooks?.[0]
  const [currentStep] = cook?.currentStep || []
  const recipeStepsCount =
    cook?.recipe?.recipe_steps_aggregate?.aggregate?.count || 0
  const diskUsagePercentage =
    ((data?.info?.total_disk_used || 0) * 100) / (data?.info?.total_disk || 0)
  const ramUsagePercentage =
    ((data?.info?.total_ram_used || 0) * 100) / (data?.info?.total_ram || 0)
  const cpuUsagePercentage = data?.info?.cpu_usage_percentage

  useEffect(() => {
    const interval = setInterval(() => {
      const emitMetricsAt = data?.emit_metrics_at

      const isEmittingMetrics = emitMetricsAt
        ? differenceInSeconds(new Date(), new Date(emitMetricsAt)) < 30
        : false

      setIsEmittingMetrics(isEmittingMetrics)
    }, 1000)

    return () => clearInterval(interval)
  }, [data?.emit_metrics_at])

  useEffect(() => {
    const interval = setInterval(() => {
      if (cook?.started_at) {
        const difference = differenceInSeconds(
          new Date(),
          new Date(cook.started_at)
        )

        setDurationTimeInSeconds(difference)
      } else {
        setDurationTimeInSeconds(null)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [cook])

  const handleEmitMetrics = useCallback(async () => {
    try {
      setError(undefined)
      setLoading(true)
      const emitMetricsFunction = httpsCallable(
        firebaseLib.functions,
        'emitMetricsCallable'
      )
      await emitMetricsFunction({
        deviceId: data?.id
      })
    } catch (e) {
      const error = e as Error
      setError(error?.message)
    } finally {
      setLoading(false)
    }
  }, [data])

  return (
    <>
      <MuiSnackbar
        snackbarProps={{
          open: !!error
        }}
        alertProps={{
          severity: 'error'
        }}
        message={error}
      />

      <MuiCard>
        <CardHeader
          title={
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography
                variant='h5'
                component={MuiLink}
                to={`/devices/${data.id}/metrics`}
              >
                {data.name}
              </Typography>

              {durationTimeInSeconds ? (
                <Typography variant='subtitle1'>
                  {`${t('time')} ${dateUtils.formatDurationTime(
                    durationTimeInSeconds,
                    t
                  )}`}
                </Typography>
              ) : null}
            </Box>
          }
          subheader={
            isOn ? (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography
                  variant='subtitle1'
                  component={MuiLink}
                  to={`/recipes/${cook?.recipe?.id}`}
                >
                  {`${t('recipe')}: ${cook?.recipe?.name}`}
                </Typography>

                <Typography variant='subtitle1'>
                  {`${t('step')} ${
                    recipeStepsCount > 0 ? currentStep?.recipe_step?.step : '∞'
                  }`}
                </Typography>
              </Box>
            ) : undefined
          }
        />

        {isOn ? <LinearProgress color='error' /> : null}

        <CardContent>
          <Stack spacing={1}>
            <Box>
              <Typography variant='caption'>{t('monitor')}</Typography>

              <Stack direction='row' spacing={1} mb={1}>
                <PaperMetric label={t('cpu')}>
                  <MemoryIcon />

                  <Typography variant='subtitle2' component='div'>
                    <Box ml={0.5}>
                      {cpuUsagePercentage ? (
                        <MuiCircularProgress value={cpuUsagePercentage} />
                      ) : null}
                    </Box>
                  </Typography>
                </PaperMetric>

                <PaperMetric label={t('ram')}>
                  <SpeedIcon />

                  <Typography variant='subtitle2' component='div'>
                    <Box ml={0.5}>
                      {ramUsagePercentage ? (
                        <MuiCircularProgress value={ramUsagePercentage} />
                      ) : null}
                    </Box>
                  </Typography>
                </PaperMetric>

                <PaperMetric label={t('disk')}>
                  <StorageIcon />

                  <Typography variant='subtitle2' component='div'>
                    <Box ml={0.5}>
                      {diskUsagePercentage ? (
                        <MuiCircularProgress value={diskUsagePercentage} />
                      ) : null}
                    </Box>
                  </Typography>
                </PaperMetric>
              </Stack>
              <Divider />
            </Box>

            {Object.keys(agentsGroupedByType).map(type => {
              const agents = agentsGroupedByType[type]
              const noHasAverage = [
                Agent_Type_Enum.Relay,
                Agent_Type_Enum.EzoHum
              ].includes(type as Agent_Type_Enum)
              const agentsMetrics: number[] = []

              if (!noHasAverage) {
                agents?.map(({ metrics }) => {
                  metrics.map(({ value }) => {
                    agentsMetrics.push(value)
                  })
                })
              }

              const average =
                noHasAverage || !agentsMetrics?.length
                  ? null
                  : metricUtils.getAverage(agentsMetrics)?.toFixed(2)

              return (
                <Box key={type}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={0.5}
                  >
                    <Typography variant='caption'>{`${t(type)}`}</Typography>
                    {average ? (
                      <Typography variant='caption'>{`${t(
                        'average'
                      )}: ${average}`}</Typography>
                    ) : null}
                  </Box>

                  <Stack direction='row' spacing={1} mb={1}>
                    {agents.map(({ id: agentId, name, metrics }) => {
                      return metrics?.map(({ id, type, value }) => {
                        const isRelay = type === Metric_Type_Enum.Relay
                        const isRelayOn = value === 0

                        return (
                          <Box
                            key={id}
                            component={MuiLink}
                            to={`/agents/${agentId}/metrics`}
                          >
                            <PaperMetric label={name}>
                              {isRelay ? (
                                isRelayOn ? (
                                  <PowerIcon color='error' />
                                ) : (
                                  <PowerOffIcon />
                                )
                              ) : (
                                <>
                                  <MetricIcon type={type} />

                                  <Typography
                                    variant='subtitle2'
                                    component='div'
                                  >
                                    <Box ml={0.5}>{`${value?.toFixed(2)}`}</Box>
                                  </Typography>
                                </>
                              )}
                            </PaperMetric>
                          </Box>
                        )
                      })
                    })}
                  </Stack>

                  <Divider />
                </Box>
              )
            })}
          </Stack>
        </CardContent>
        <ProtectedComponent
          allowedRoles={[
            User_Role_Enum.Admin,
            User_Role_Enum.TenantAdmin,
            User_Role_Enum.LocationAdmin
          ]}
        >
          <CardActions>
            <MuiButton
              startIcon={isOn ? <StopIcon /> : <PlayArrowIcon />}
              onClick={() => (isOn ? stop(data.id) : start(data.id))}
              color={isOn ? 'error' : 'primary'}
              disabled={isEmittingMetrics}
            >
              {t(isOn ? 'stop' : 'start')}
            </MuiButton>

            <MuiButton
              startIcon={<SyncIcon />}
              onClick={handleEmitMetrics}
              color='primary'
              disabled={loading || isEmittingMetrics || isOn}
            >
              {t(isEmittingMetrics ? 'refreshing' : 'refreshMetrics')}
            </MuiButton>
          </CardActions>
        </ProtectedComponent>

        <Typography>{error}</Typography>
      </MuiCard>
    </>
  )
}

export default DeviceListItem
