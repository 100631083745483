import React from 'react'
import { Box, Typography, Paper } from '@mui/material'

type PaperMetricProps = {
  label: string
  children: JSX.Element | JSX.Element[]
}

const PaperMetric: React.FC<PaperMetricProps> = ({
  label,
  children
}: PaperMetricProps) => {
  return (
    <Paper>
      <Box px={1} minWidth={50} minHeight={50}>
        <Typography variant='caption' component='div'>
          {label}
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='center'>
          {children}
        </Box>
      </Box>
    </Paper>
  )
}

export default PaperMetric
