import { Agent_Type_Enum, Metric_Type_Enum } from 'api/generated'

const getAverage = (values: number[]): number => {
  const noZeros = values.filter(
    value => value !== 0 || typeof value !== 'number' || !isNaN(value)
  )

  const sum = noZeros?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )

  return sum / noZeros.length
}

const roundNearestNumber = (num: number, roundNumber = 5): number =>
  Math.round(num / roundNumber) * roundNumber

const roundNearest5 = (num: number) => roundNearestNumber(num)

const metricByAgentType = {
  [Agent_Type_Enum.Relay]: {
    metrics: [Metric_Type_Enum.Relay]
  },
  [Agent_Type_Enum.Pt_1000]: {
    metrics: [Metric_Type_Enum.Temp]
  },
  [Agent_Type_Enum.EzoHum]: {
    metrics: [Metric_Type_Enum.Temp, Metric_Type_Enum.Humidity]
  },
  [Agent_Type_Enum.EzoOrp]: {
    metrics: [Metric_Type_Enum.Orp]
  },
  [Agent_Type_Enum.EzoPh]: {
    metrics: [Metric_Type_Enum.Ph]
  }
}

const isMetricAvailableForAgentType = (
  agentType: Agent_Type_Enum,
  metricType: Metric_Type_Enum
): boolean => {
  return !!metricByAgentType?.[agentType]?.metrics?.includes(metricType)
}

export default {
  getAverage,
  roundNearestNumber,
  roundNearest5,
  isMetricAvailableForAgentType,
  metricByAgentType
}
