import { TFunction } from 'react-i18next'

export enum LOCAL_STORAGE_KEYS {
  THEME_TYPE = 'THEME_TYPE'
}

export enum TABLE_PREFERENCES_LOCAL_STORAGE_KEYS {
  TABLE_PREFERENCES = 'TABLE_PREFERENCES',
  DEVICE_TABLE_PREFERENCES = 'DEVICE_TABLE_PREFERENCES',
  RECIPE_TABLE_PREFERENCES = 'RECIPE_TABLE_PREFERENCES',
  AGENT_TABLE_PREFERENCES = 'AGENT_TABLE_PREFERENCES',
  COOK_TABLE_PREFERENCES = 'COOK_TABLE_PREFERENCES',
  USER_TABLE_PREFERENCES = 'USER_TABLE_PREFERENCES',
  LOCATION_TABLE_PREFERENCES = 'LOCATION_TABLE_PREFERENCES',
  TENANT_TABLE_PREFERENCES = 'TENANT_TABLE_PREFERENCES',
  ALARMS_TABLE_PREFERENCES = 'ALARMS_TABLE_PREFERENCES',
  USER_DEVICE_SETTING_TABLE_PREFERENCES = 'USER_DEVICE_SETTING_TABLE_PREFERENCES'
}

export enum RECIPE_OPERATORS {
  EQUAL = 'EQUAL',
  MORE_OR_EQUAL_THAN = 'MORE_OR_EQUAL_THAN',
  MORE_THAN = 'MORE_THAN',
  LESS_OR_EQUAL_THAN = 'LESS_OR_EQUAL_THAN',
  LESS_THAN = 'LESS_THAN',
  BETWEEN = 'BETWEEN'
}

export enum RECIPE_CONDITION_RULE_TYPE {
  AGENT = 'AGENT',
  AVERAGE = 'AVERAGE'
}

export enum ALARM_SENDER_TYPE {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  WHATS_APP = 'WHATS_APP'
}

type RecipeOperators = {
  [key: string]: {
    key: string
    operator: string
  }
}

export const recipeOperators: RecipeOperators = {
  EQUAL: {
    key: 'EQUAL',
    operator: '='
  },
  MORE_OR_EQUAL_THAN: {
    key: 'MORE_OR_EQUAL_THAN',
    operator: '>='
  },
  MORE_THAN: {
    key: 'MORE_THAN',
    operator: '>'
  },
  LESS_OR_EQUAL_THAN: {
    key: 'LESS_OR_EQUAL_THAN',
    operator: '<='
  },
  LESS_THAN: {
    key: 'LESS_THAN',
    operator: '<'
  },
  BETWEEN: {
    key: 'BETWEEN',
    operator: '<>'
  }
}

type CommonCommands = {
  [key: string]: {
    key: string
    label: string
    getValue(t: TFunction): string
    waitTime: number
  }
}

export const commonCommands: CommonCommands = {
  CHANGE_I2C_ADDRESS: {
    key: 'CHANGE_I2C_ADDRESS',
    label: 'changeI2CAddress',
    getValue: t => `I2C,${t('value')}`,
    waitTime: 300
  },
  CALIBRATE: {
    key: 'CALIBRATE',
    label: 'calibrate',
    getValue: t => `CAL,${t('value')}`,
    waitTime: 600
  }
}

export default {
  LOCAL_STORAGE_KEYS,
  TABLE_PREFERENCES_LOCAL_STORAGE_KEYS,
  RECIPE_OPERATORS,
  RECIPE_CONDITION_RULE_TYPE,
  recipeOperators,
  commonCommands
}
