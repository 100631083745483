import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const FullPageLoading: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='80vh'
    >
      <Typography color='primary' variant='subtitle1'>
        {t('loading')}
      </Typography>
    </Box>
  )
}

export default FullPageLoading
