import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  jsonb: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>
  _gt?: InputMaybe<Scalars['Float']>
  _gte?: InputMaybe<Scalars['Float']>
  _in?: InputMaybe<Array<Scalars['Float']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Float']>
  _lte?: InputMaybe<Scalars['Float']>
  _neq?: InputMaybe<Scalars['Float']>
  _nin?: InputMaybe<Array<Scalars['Float']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "agent" */
export type Agent = {
  __typename?: 'agent'
  /** An array relationship */
  agent_metric_types: Array<Agent_Metric_Type>
  /** An aggregate relationship */
  agent_metric_types_aggregate: Agent_Metric_Type_Aggregate
  /** An object relationship */
  agent_type: Agent_Type
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']
  id: Scalars['uuid']
  /** An array relationship */
  metrics: Array<Metric>
  /** An aggregate relationship */
  metrics_aggregate: Metric_Aggregate
  name: Scalars['String']
  pin_id: Scalars['String']
  type: Agent_Type_Enum
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "agent" */
export type AgentAgent_Metric_TypesArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentAgent_Metric_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentMetricsArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

/** aggregated selection of "agent" */
export type Agent_Aggregate = {
  __typename?: 'agent_aggregate'
  aggregate?: Maybe<Agent_Aggregate_Fields>
  nodes: Array<Agent>
}

export type Agent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agent_Aggregate_Bool_Exp_Count>
}

export type Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agent_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Agent_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "agent" */
export type Agent_Aggregate_Fields = {
  __typename?: 'agent_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Agent_Max_Fields>
  min?: Maybe<Agent_Min_Fields>
}

/** aggregate fields of "agent" */
export type Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "agent" */
export type Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Agent_Max_Order_By>
  min?: InputMaybe<Agent_Min_Order_By>
}

/** input type for inserting array relation for remote table "agent" */
export type Agent_Arr_Rel_Insert_Input = {
  data: Array<Agent_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** Boolean expression to filter rows from the table "agent". All fields are combined with a logical 'AND'. */
export type Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Bool_Exp>>
  _not?: InputMaybe<Agent_Bool_Exp>
  _or?: InputMaybe<Array<Agent_Bool_Exp>>
  agent_metric_types?: InputMaybe<Agent_Metric_Type_Bool_Exp>
  agent_metric_types_aggregate?: InputMaybe<Agent_Metric_Type_Aggregate_Bool_Exp>
  agent_type?: InputMaybe<Agent_Type_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  metrics?: InputMaybe<Metric_Bool_Exp>
  metrics_aggregate?: InputMaybe<Metric_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  pin_id?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<Agent_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "agent" */
export enum Agent_Constraint {
  /** unique or primary key constraint on columns "id" */
  AgentPkey = 'agent_pkey'
}

/** input type for inserting data into table "agent" */
export type Agent_Insert_Input = {
  agent_metric_types?: InputMaybe<Agent_Metric_Type_Arr_Rel_Insert_Input>
  agent_type?: InputMaybe<Agent_Type_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  metrics?: InputMaybe<Metric_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']>
  pin_id?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Agent_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Agent_Max_Fields = {
  __typename?: 'agent_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  pin_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "agent" */
export type Agent_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  pin_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** columns and relationships of "agent_metric_type" */
export type Agent_Metric_Type = {
  __typename?: 'agent_metric_type'
  /** An object relationship */
  agent: Agent
  agent_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  metricTypeByMetricType: Metric_Type
  metric_type: Metric_Type_Enum
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "agent_metric_type" */
export type Agent_Metric_Type_Aggregate = {
  __typename?: 'agent_metric_type_aggregate'
  aggregate?: Maybe<Agent_Metric_Type_Aggregate_Fields>
  nodes: Array<Agent_Metric_Type>
}

export type Agent_Metric_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agent_Metric_Type_Aggregate_Bool_Exp_Count>
}

export type Agent_Metric_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Agent_Metric_Type_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "agent_metric_type" */
export type Agent_Metric_Type_Aggregate_Fields = {
  __typename?: 'agent_metric_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Agent_Metric_Type_Max_Fields>
  min?: Maybe<Agent_Metric_Type_Min_Fields>
}

/** aggregate fields of "agent_metric_type" */
export type Agent_Metric_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "agent_metric_type" */
export type Agent_Metric_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Agent_Metric_Type_Max_Order_By>
  min?: InputMaybe<Agent_Metric_Type_Min_Order_By>
}

/** input type for inserting array relation for remote table "agent_metric_type" */
export type Agent_Metric_Type_Arr_Rel_Insert_Input = {
  data: Array<Agent_Metric_Type_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_Metric_Type_On_Conflict>
}

/** Boolean expression to filter rows from the table "agent_metric_type". All fields are combined with a logical 'AND'. */
export type Agent_Metric_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Metric_Type_Bool_Exp>>
  _not?: InputMaybe<Agent_Metric_Type_Bool_Exp>
  _or?: InputMaybe<Array<Agent_Metric_Type_Bool_Exp>>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  metricTypeByMetricType?: InputMaybe<Metric_Type_Bool_Exp>
  metric_type?: InputMaybe<Metric_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "agent_metric_type" */
export enum Agent_Metric_Type_Constraint {
  /** unique or primary key constraint on columns "agent_id", "metric_type" */
  AgentMetricTypePkey = 'agent_metric_type_pkey'
}

/** input type for inserting data into table "agent_metric_type" */
export type Agent_Metric_Type_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  metricTypeByMetricType?: InputMaybe<Metric_Type_Obj_Rel_Insert_Input>
  metric_type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Agent_Metric_Type_Max_Fields = {
  __typename?: 'agent_metric_type_max_fields'
  agent_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "agent_metric_type" */
export type Agent_Metric_Type_Max_Order_By = {
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Agent_Metric_Type_Min_Fields = {
  __typename?: 'agent_metric_type_min_fields'
  agent_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "agent_metric_type" */
export type Agent_Metric_Type_Min_Order_By = {
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "agent_metric_type" */
export type Agent_Metric_Type_Mutation_Response = {
  __typename?: 'agent_metric_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Metric_Type>
}

/** on_conflict condition type for table "agent_metric_type" */
export type Agent_Metric_Type_On_Conflict = {
  constraint: Agent_Metric_Type_Constraint
  update_columns?: Array<Agent_Metric_Type_Update_Column>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

/** Ordering options when selecting data from "agent_metric_type". */
export type Agent_Metric_Type_Order_By = {
  agent?: InputMaybe<Agent_Order_By>
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  metricTypeByMetricType?: InputMaybe<Metric_Type_Order_By>
  metric_type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: agent_metric_type */
export type Agent_Metric_Type_Pk_Columns_Input = {
  agent_id: Scalars['uuid']
  metric_type: Metric_Type_Enum
}

/** select columns of table "agent_metric_type" */
export enum Agent_Metric_Type_Select_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MetricType = 'metric_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "agent_metric_type" */
export type Agent_Metric_Type_Set_Input = {
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  metric_type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "agent_metric_type" */
export type Agent_Metric_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Metric_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Agent_Metric_Type_Stream_Cursor_Value_Input = {
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  metric_type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "agent_metric_type" */
export enum Agent_Metric_Type_Update_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MetricType = 'metric_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Agent_Metric_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agent_Metric_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Agent_Metric_Type_Bool_Exp
}

/** aggregate min on columns */
export type Agent_Min_Fields = {
  __typename?: 'agent_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  pin_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "agent" */
export type Agent_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  pin_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "agent" */
export type Agent_Mutation_Response = {
  __typename?: 'agent_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Agent>
}

/** input type for inserting object relation for remote table "agent" */
export type Agent_Obj_Rel_Insert_Input = {
  data: Agent_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** on_conflict condition type for table "agent" */
export type Agent_On_Conflict = {
  constraint: Agent_Constraint
  update_columns?: Array<Agent_Update_Column>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** Ordering options when selecting data from "agent". */
export type Agent_Order_By = {
  agent_metric_types_aggregate?: InputMaybe<Agent_Metric_Type_Aggregate_Order_By>
  agent_type?: InputMaybe<Agent_Type_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  metrics_aggregate?: InputMaybe<Metric_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  pin_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: agent */
export type Agent_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "agent" */
export enum Agent_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PinId = 'pin_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "agent" */
export type Agent_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  pin_id?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Agent_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "agent" */
export type Agent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Agent_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  pin_id?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Agent_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "agent_type" */
export type Agent_Type = {
  __typename?: 'agent_type'
  /** An array relationship */
  agents: Array<Agent>
  /** An aggregate relationship */
  agents_aggregate: Agent_Aggregate
  comment?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** columns and relationships of "agent_type" */
export type Agent_TypeAgentsArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** columns and relationships of "agent_type" */
export type Agent_TypeAgents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** aggregated selection of "agent_type" */
export type Agent_Type_Aggregate = {
  __typename?: 'agent_type_aggregate'
  aggregate?: Maybe<Agent_Type_Aggregate_Fields>
  nodes: Array<Agent_Type>
}

/** aggregate fields of "agent_type" */
export type Agent_Type_Aggregate_Fields = {
  __typename?: 'agent_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Agent_Type_Max_Fields>
  min?: Maybe<Agent_Type_Min_Fields>
}

/** aggregate fields of "agent_type" */
export type Agent_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "agent_type". All fields are combined with a logical 'AND'. */
export type Agent_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Type_Bool_Exp>>
  _not?: InputMaybe<Agent_Type_Bool_Exp>
  _or?: InputMaybe<Array<Agent_Type_Bool_Exp>>
  agents?: InputMaybe<Agent_Bool_Exp>
  agents_aggregate?: InputMaybe<Agent_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "agent_type" */
export enum Agent_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  AgentTypePkey = 'agent_type_pkey'
}

export enum Agent_Type_Enum {
  /** EZO_HUM */
  EzoHum = 'EZO_HUM',
  /** EZO_ORP */
  EzoOrp = 'EZO_ORP',
  /** EZO_PH */
  EzoPh = 'EZO_PH',
  /** PT_1000 */
  Pt_1000 = 'PT_1000',
  /** RELAY */
  Relay = 'RELAY'
}

/** Boolean expression to compare columns of type "agent_type_enum". All fields are combined with logical 'AND'. */
export type Agent_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Agent_Type_Enum>
  _in?: InputMaybe<Array<Agent_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Agent_Type_Enum>
  _nin?: InputMaybe<Array<Agent_Type_Enum>>
}

/** input type for inserting data into table "agent_type" */
export type Agent_Type_Insert_Input = {
  agents?: InputMaybe<Agent_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Agent_Type_Max_Fields = {
  __typename?: 'agent_type_max_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Agent_Type_Min_Fields = {
  __typename?: 'agent_type_min_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "agent_type" */
export type Agent_Type_Mutation_Response = {
  __typename?: 'agent_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Type>
}

/** input type for inserting object relation for remote table "agent_type" */
export type Agent_Type_Obj_Rel_Insert_Input = {
  data: Agent_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_Type_On_Conflict>
}

/** on_conflict condition type for table "agent_type" */
export type Agent_Type_On_Conflict = {
  constraint: Agent_Type_Constraint
  update_columns?: Array<Agent_Type_Update_Column>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

/** Ordering options when selecting data from "agent_type". */
export type Agent_Type_Order_By = {
  agents_aggregate?: InputMaybe<Agent_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: agent_type */
export type Agent_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "agent_type" */
export enum Agent_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "agent_type" */
export type Agent_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "agent_type" */
export type Agent_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Agent_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** update columns of table "agent_type" */
export enum Agent_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Agent_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agent_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Agent_Type_Bool_Exp
}

/** update columns of table "agent" */
export enum Agent_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PinId = 'pin_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Agent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agent_Set_Input>
  /** filter the rows which have to be updated */
  where: Agent_Bool_Exp
}

/** columns and relationships of "alarm" */
export type Alarm = {
  __typename?: 'alarm'
  alarm: Scalars['String']
  /** An object relationship */
  cook: Cook
  cook_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  message: Scalars['String']
  progress_time: Scalars['Float']
  sender_response?: Maybe<Scalars['jsonb']>
  sender_type: Scalars['String']
  sender_value: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "alarm" */
export type AlarmSender_ResponseArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "alarm" */
export type Alarm_Aggregate = {
  __typename?: 'alarm_aggregate'
  aggregate?: Maybe<Alarm_Aggregate_Fields>
  nodes: Array<Alarm>
}

export type Alarm_Aggregate_Bool_Exp = {
  count?: InputMaybe<Alarm_Aggregate_Bool_Exp_Count>
}

export type Alarm_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Alarm_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Alarm_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "alarm" */
export type Alarm_Aggregate_Fields = {
  __typename?: 'alarm_aggregate_fields'
  avg?: Maybe<Alarm_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Alarm_Max_Fields>
  min?: Maybe<Alarm_Min_Fields>
  stddev?: Maybe<Alarm_Stddev_Fields>
  stddev_pop?: Maybe<Alarm_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Alarm_Stddev_Samp_Fields>
  sum?: Maybe<Alarm_Sum_Fields>
  var_pop?: Maybe<Alarm_Var_Pop_Fields>
  var_samp?: Maybe<Alarm_Var_Samp_Fields>
  variance?: Maybe<Alarm_Variance_Fields>
}

/** aggregate fields of "alarm" */
export type Alarm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alarm_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "alarm" */
export type Alarm_Aggregate_Order_By = {
  avg?: InputMaybe<Alarm_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Alarm_Max_Order_By>
  min?: InputMaybe<Alarm_Min_Order_By>
  stddev?: InputMaybe<Alarm_Stddev_Order_By>
  stddev_pop?: InputMaybe<Alarm_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Alarm_Stddev_Samp_Order_By>
  sum?: InputMaybe<Alarm_Sum_Order_By>
  var_pop?: InputMaybe<Alarm_Var_Pop_Order_By>
  var_samp?: InputMaybe<Alarm_Var_Samp_Order_By>
  variance?: InputMaybe<Alarm_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Alarm_Append_Input = {
  sender_response?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "alarm" */
export type Alarm_Arr_Rel_Insert_Input = {
  data: Array<Alarm_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Alarm_On_Conflict>
}

/** aggregate avg on columns */
export type Alarm_Avg_Fields = {
  __typename?: 'alarm_avg_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "alarm" */
export type Alarm_Avg_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "alarm". All fields are combined with a logical 'AND'. */
export type Alarm_Bool_Exp = {
  _and?: InputMaybe<Array<Alarm_Bool_Exp>>
  _not?: InputMaybe<Alarm_Bool_Exp>
  _or?: InputMaybe<Array<Alarm_Bool_Exp>>
  alarm?: InputMaybe<String_Comparison_Exp>
  cook?: InputMaybe<Cook_Bool_Exp>
  cook_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  message?: InputMaybe<String_Comparison_Exp>
  progress_time?: InputMaybe<Float_Comparison_Exp>
  sender_response?: InputMaybe<Jsonb_Comparison_Exp>
  sender_type?: InputMaybe<String_Comparison_Exp>
  sender_value?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "alarm" */
export enum Alarm_Constraint {
  /** unique or primary key constraint on columns "id" */
  AlarmPkey = 'alarm_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Alarm_Delete_At_Path_Input = {
  sender_response?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Alarm_Delete_Elem_Input = {
  sender_response?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Alarm_Delete_Key_Input = {
  sender_response?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "alarm" */
export type Alarm_Inc_Input = {
  progress_time?: InputMaybe<Scalars['Float']>
}

/** input type for inserting data into table "alarm" */
export type Alarm_Insert_Input = {
  alarm?: InputMaybe<Scalars['String']>
  cook?: InputMaybe<Cook_Obj_Rel_Insert_Input>
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  progress_time?: InputMaybe<Scalars['Float']>
  sender_response?: InputMaybe<Scalars['jsonb']>
  sender_type?: InputMaybe<Scalars['String']>
  sender_value?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Alarm_Max_Fields = {
  __typename?: 'alarm_max_fields'
  alarm?: Maybe<Scalars['String']>
  cook_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  progress_time?: Maybe<Scalars['Float']>
  sender_type?: Maybe<Scalars['String']>
  sender_value?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "alarm" */
export type Alarm_Max_Order_By = {
  alarm?: InputMaybe<Order_By>
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  progress_time?: InputMaybe<Order_By>
  sender_type?: InputMaybe<Order_By>
  sender_value?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Alarm_Min_Fields = {
  __typename?: 'alarm_min_fields'
  alarm?: Maybe<Scalars['String']>
  cook_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  progress_time?: Maybe<Scalars['Float']>
  sender_type?: Maybe<Scalars['String']>
  sender_value?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "alarm" */
export type Alarm_Min_Order_By = {
  alarm?: InputMaybe<Order_By>
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  progress_time?: InputMaybe<Order_By>
  sender_type?: InputMaybe<Order_By>
  sender_value?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "alarm" */
export type Alarm_Mutation_Response = {
  __typename?: 'alarm_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Alarm>
}

/** on_conflict condition type for table "alarm" */
export type Alarm_On_Conflict = {
  constraint: Alarm_Constraint
  update_columns?: Array<Alarm_Update_Column>
  where?: InputMaybe<Alarm_Bool_Exp>
}

/** Ordering options when selecting data from "alarm". */
export type Alarm_Order_By = {
  alarm?: InputMaybe<Order_By>
  cook?: InputMaybe<Cook_Order_By>
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  progress_time?: InputMaybe<Order_By>
  sender_response?: InputMaybe<Order_By>
  sender_type?: InputMaybe<Order_By>
  sender_value?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: alarm */
export type Alarm_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Alarm_Prepend_Input = {
  sender_response?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "alarm" */
export enum Alarm_Select_Column {
  /** column name */
  Alarm = 'alarm',
  /** column name */
  CookId = 'cook_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProgressTime = 'progress_time',
  /** column name */
  SenderResponse = 'sender_response',
  /** column name */
  SenderType = 'sender_type',
  /** column name */
  SenderValue = 'sender_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "alarm" */
export type Alarm_Set_Input = {
  alarm?: InputMaybe<Scalars['String']>
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  progress_time?: InputMaybe<Scalars['Float']>
  sender_response?: InputMaybe<Scalars['jsonb']>
  sender_type?: InputMaybe<Scalars['String']>
  sender_value?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Alarm_Stddev_Fields = {
  __typename?: 'alarm_stddev_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "alarm" */
export type Alarm_Stddev_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Alarm_Stddev_Pop_Fields = {
  __typename?: 'alarm_stddev_pop_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "alarm" */
export type Alarm_Stddev_Pop_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Alarm_Stddev_Samp_Fields = {
  __typename?: 'alarm_stddev_samp_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "alarm" */
export type Alarm_Stddev_Samp_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "alarm" */
export type Alarm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alarm_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Alarm_Stream_Cursor_Value_Input = {
  alarm?: InputMaybe<Scalars['String']>
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  progress_time?: InputMaybe<Scalars['Float']>
  sender_response?: InputMaybe<Scalars['jsonb']>
  sender_type?: InputMaybe<Scalars['String']>
  sender_value?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Alarm_Sum_Fields = {
  __typename?: 'alarm_sum_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by sum() on columns of table "alarm" */
export type Alarm_Sum_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** update columns of table "alarm" */
export enum Alarm_Update_Column {
  /** column name */
  Alarm = 'alarm',
  /** column name */
  CookId = 'cook_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProgressTime = 'progress_time',
  /** column name */
  SenderResponse = 'sender_response',
  /** column name */
  SenderType = 'sender_type',
  /** column name */
  SenderValue = 'sender_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Alarm_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Alarm_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Alarm_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Alarm_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Alarm_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Alarm_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Alarm_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alarm_Set_Input>
  /** filter the rows which have to be updated */
  where: Alarm_Bool_Exp
}

/** aggregate var_pop on columns */
export type Alarm_Var_Pop_Fields = {
  __typename?: 'alarm_var_pop_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "alarm" */
export type Alarm_Var_Pop_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Alarm_Var_Samp_Fields = {
  __typename?: 'alarm_var_samp_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "alarm" */
export type Alarm_Var_Samp_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Alarm_Variance_Fields = {
  __typename?: 'alarm_variance_fields'
  progress_time?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "alarm" */
export type Alarm_Variance_Order_By = {
  progress_time?: InputMaybe<Order_By>
}

/** columns and relationships of "cook" */
export type Cook = {
  __typename?: 'cook'
  /** An array relationship */
  alarms: Array<Alarm>
  /** An aggregate relationship */
  alarms_aggregate: Alarm_Aggregate
  batch_code?: Maybe<Scalars['String']>
  /** An array relationship */
  cook_recipe_steps: Array<Cook_Recipe_Step>
  /** An aggregate relationship */
  cook_recipe_steps_aggregate: Cook_Recipe_Step_Aggregate
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']
  ended_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  metadata?: Maybe<Scalars['jsonb']>
  /** An object relationship */
  recipe: Recipe
  recipe_id: Scalars['uuid']
  started_at?: Maybe<Scalars['timestamptz']>
  stop: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "cook" */
export type CookAlarmsArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

/** columns and relationships of "cook" */
export type CookAlarms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

/** columns and relationships of "cook" */
export type CookCook_Recipe_StepsArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

/** columns and relationships of "cook" */
export type CookCook_Recipe_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

/** columns and relationships of "cook" */
export type CookMetadataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "cook" */
export type Cook_Aggregate = {
  __typename?: 'cook_aggregate'
  aggregate?: Maybe<Cook_Aggregate_Fields>
  nodes: Array<Cook>
}

export type Cook_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Cook_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Cook_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Cook_Aggregate_Bool_Exp_Count>
}

export type Cook_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cook_Select_Column_Cook_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Cook_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cook_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cook_Select_Column_Cook_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Cook_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cook_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cook_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Cook_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cook" */
export type Cook_Aggregate_Fields = {
  __typename?: 'cook_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cook_Max_Fields>
  min?: Maybe<Cook_Min_Fields>
}

/** aggregate fields of "cook" */
export type Cook_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cook_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cook" */
export type Cook_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Cook_Max_Order_By>
  min?: InputMaybe<Cook_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cook_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "cook" */
export type Cook_Arr_Rel_Insert_Input = {
  data: Array<Cook_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Cook_On_Conflict>
}

/** Boolean expression to filter rows from the table "cook". All fields are combined with a logical 'AND'. */
export type Cook_Bool_Exp = {
  _and?: InputMaybe<Array<Cook_Bool_Exp>>
  _not?: InputMaybe<Cook_Bool_Exp>
  _or?: InputMaybe<Array<Cook_Bool_Exp>>
  alarms?: InputMaybe<Alarm_Bool_Exp>
  alarms_aggregate?: InputMaybe<Alarm_Aggregate_Bool_Exp>
  batch_code?: InputMaybe<String_Comparison_Exp>
  cook_recipe_steps?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
  cook_recipe_steps_aggregate?: InputMaybe<Cook_Recipe_Step_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  metadata?: InputMaybe<Jsonb_Comparison_Exp>
  recipe?: InputMaybe<Recipe_Bool_Exp>
  recipe_id?: InputMaybe<Uuid_Comparison_Exp>
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>
  stop?: InputMaybe<Boolean_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cook" */
export enum Cook_Constraint {
  /** unique or primary key constraint on columns "id" */
  CookPkey = 'cook_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cook_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cook_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cook_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "cook" */
export type Cook_Insert_Input = {
  alarms?: InputMaybe<Alarm_Arr_Rel_Insert_Input>
  batch_code?: InputMaybe<Scalars['String']>
  cook_recipe_steps?: InputMaybe<Cook_Recipe_Step_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  metadata?: InputMaybe<Scalars['jsonb']>
  recipe?: InputMaybe<Recipe_Obj_Rel_Insert_Input>
  recipe_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  stop?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Cook_Max_Fields = {
  __typename?: 'cook_max_fields'
  batch_code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  ended_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  recipe_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "cook" */
export type Cook_Max_Order_By = {
  batch_code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  recipe_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Cook_Min_Fields = {
  __typename?: 'cook_min_fields'
  batch_code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  ended_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  recipe_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "cook" */
export type Cook_Min_Order_By = {
  batch_code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  recipe_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "cook" */
export type Cook_Mutation_Response = {
  __typename?: 'cook_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cook>
}

/** input type for inserting object relation for remote table "cook" */
export type Cook_Obj_Rel_Insert_Input = {
  data: Cook_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Cook_On_Conflict>
}

/** on_conflict condition type for table "cook" */
export type Cook_On_Conflict = {
  constraint: Cook_Constraint
  update_columns?: Array<Cook_Update_Column>
  where?: InputMaybe<Cook_Bool_Exp>
}

/** Ordering options when selecting data from "cook". */
export type Cook_Order_By = {
  alarms_aggregate?: InputMaybe<Alarm_Aggregate_Order_By>
  batch_code?: InputMaybe<Order_By>
  cook_recipe_steps_aggregate?: InputMaybe<Cook_Recipe_Step_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  metadata?: InputMaybe<Order_By>
  recipe?: InputMaybe<Recipe_Order_By>
  recipe_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  stop?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cook */
export type Cook_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cook_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>
}

/** columns and relationships of "cook_recipe_step" */
export type Cook_Recipe_Step = {
  __typename?: 'cook_recipe_step'
  /** An object relationship */
  cook: Cook
  cook_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  ended_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  recipe_step: Recipe_Step
  recipe_step_id: Scalars['uuid']
  started_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "cook_recipe_step" */
export type Cook_Recipe_Step_Aggregate = {
  __typename?: 'cook_recipe_step_aggregate'
  aggregate?: Maybe<Cook_Recipe_Step_Aggregate_Fields>
  nodes: Array<Cook_Recipe_Step>
}

export type Cook_Recipe_Step_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cook_Recipe_Step_Aggregate_Bool_Exp_Count>
}

export type Cook_Recipe_Step_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cook_recipe_step" */
export type Cook_Recipe_Step_Aggregate_Fields = {
  __typename?: 'cook_recipe_step_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cook_Recipe_Step_Max_Fields>
  min?: Maybe<Cook_Recipe_Step_Min_Fields>
}

/** aggregate fields of "cook_recipe_step" */
export type Cook_Recipe_Step_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cook_recipe_step" */
export type Cook_Recipe_Step_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Cook_Recipe_Step_Max_Order_By>
  min?: InputMaybe<Cook_Recipe_Step_Min_Order_By>
}

/** input type for inserting array relation for remote table "cook_recipe_step" */
export type Cook_Recipe_Step_Arr_Rel_Insert_Input = {
  data: Array<Cook_Recipe_Step_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Cook_Recipe_Step_On_Conflict>
}

/** Boolean expression to filter rows from the table "cook_recipe_step". All fields are combined with a logical 'AND'. */
export type Cook_Recipe_Step_Bool_Exp = {
  _and?: InputMaybe<Array<Cook_Recipe_Step_Bool_Exp>>
  _not?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
  _or?: InputMaybe<Array<Cook_Recipe_Step_Bool_Exp>>
  cook?: InputMaybe<Cook_Bool_Exp>
  cook_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  recipe_step?: InputMaybe<Recipe_Step_Bool_Exp>
  recipe_step_id?: InputMaybe<Uuid_Comparison_Exp>
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cook_recipe_step" */
export enum Cook_Recipe_Step_Constraint {
  /** unique or primary key constraint on columns "id" */
  CookRecipeStepIdKey = 'cook_recipe_step_id_key',
  /** unique or primary key constraint on columns "recipe_step_id", "cook_id" */
  CookRecipeStepPkey = 'cook_recipe_step_pkey'
}

/** input type for inserting data into table "cook_recipe_step" */
export type Cook_Recipe_Step_Insert_Input = {
  cook?: InputMaybe<Cook_Obj_Rel_Insert_Input>
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  recipe_step?: InputMaybe<Recipe_Step_Obj_Rel_Insert_Input>
  recipe_step_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Cook_Recipe_Step_Max_Fields = {
  __typename?: 'cook_recipe_step_max_fields'
  cook_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  ended_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  recipe_step_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "cook_recipe_step" */
export type Cook_Recipe_Step_Max_Order_By = {
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  recipe_step_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Cook_Recipe_Step_Min_Fields = {
  __typename?: 'cook_recipe_step_min_fields'
  cook_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  ended_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  recipe_step_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "cook_recipe_step" */
export type Cook_Recipe_Step_Min_Order_By = {
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  recipe_step_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "cook_recipe_step" */
export type Cook_Recipe_Step_Mutation_Response = {
  __typename?: 'cook_recipe_step_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cook_Recipe_Step>
}

/** on_conflict condition type for table "cook_recipe_step" */
export type Cook_Recipe_Step_On_Conflict = {
  constraint: Cook_Recipe_Step_Constraint
  update_columns?: Array<Cook_Recipe_Step_Update_Column>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

/** Ordering options when selecting data from "cook_recipe_step". */
export type Cook_Recipe_Step_Order_By = {
  cook?: InputMaybe<Cook_Order_By>
  cook_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  ended_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  recipe_step?: InputMaybe<Recipe_Step_Order_By>
  recipe_step_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cook_recipe_step */
export type Cook_Recipe_Step_Pk_Columns_Input = {
  cook_id: Scalars['uuid']
  recipe_step_id: Scalars['uuid']
}

/** select columns of table "cook_recipe_step" */
export enum Cook_Recipe_Step_Select_Column {
  /** column name */
  CookId = 'cook_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecipeStepId = 'recipe_step_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cook_recipe_step" */
export type Cook_Recipe_Step_Set_Input = {
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  recipe_step_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "cook_recipe_step" */
export type Cook_Recipe_Step_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cook_Recipe_Step_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cook_Recipe_Step_Stream_Cursor_Value_Input = {
  cook_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  recipe_step_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "cook_recipe_step" */
export enum Cook_Recipe_Step_Update_Column {
  /** column name */
  CookId = 'cook_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecipeStepId = 'recipe_step_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Cook_Recipe_Step_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cook_Recipe_Step_Set_Input>
  /** filter the rows which have to be updated */
  where: Cook_Recipe_Step_Bool_Exp
}

/** select columns of table "cook" */
export enum Cook_Select_Column {
  /** column name */
  BatchCode = 'batch_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Stop = 'stop',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "cook_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cook" */
export enum Cook_Select_Column_Cook_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Stop = 'stop'
}

/** select "cook_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cook" */
export enum Cook_Select_Column_Cook_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Stop = 'stop'
}

/** input type for updating data in table "cook" */
export type Cook_Set_Input = {
  batch_code?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  metadata?: InputMaybe<Scalars['jsonb']>
  recipe_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  stop?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "cook" */
export type Cook_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cook_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cook_Stream_Cursor_Value_Input = {
  batch_code?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  ended_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  metadata?: InputMaybe<Scalars['jsonb']>
  recipe_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
  stop?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "cook" */
export enum Cook_Update_Column {
  /** column name */
  BatchCode = 'batch_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Stop = 'stop',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Cook_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cook_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cook_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cook_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cook_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cook_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cook_Set_Input>
  /** filter the rows which have to be updated */
  where: Cook_Bool_Exp
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "device" */
export type Device = {
  __typename?: 'device'
  /** An array relationship */
  agents: Array<Agent>
  /** An aggregate relationship */
  agents_aggregate: Agent_Aggregate
  alarm_email?: Maybe<Scalars['String']>
  alarm_phone_number?: Maybe<Scalars['String']>
  /** An array relationship */
  cooks: Array<Cook>
  /** An aggregate relationship */
  cooks_aggregate: Cook_Aggregate
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  emit_metrics_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  info?: Maybe<Device_Info>
  internal_ip_address?: Maybe<Scalars['String']>
  /** An object relationship */
  location?: Maybe<Location>
  location_id?: Maybe<Scalars['uuid']>
  name: Scalars['String']
  /** An array relationship */
  recipes: Array<Recipe>
  /** An aggregate relationship */
  recipes_aggregate: Recipe_Aggregate
  serial_number?: Maybe<Scalars['String']>
  test_connection_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  /** An array relationship */
  user_device_settings: Array<User_Device_Setting>
  /** An aggregate relationship */
  user_device_settings_aggregate: User_Device_Setting_Aggregate
}

/** columns and relationships of "device" */
export type DeviceAgentsArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceAgents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceCooksArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceCooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceRecipesArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceUser_Device_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceUser_Device_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

/** aggregated selection of "device" */
export type Device_Aggregate = {
  __typename?: 'device_aggregate'
  aggregate?: Maybe<Device_Aggregate_Fields>
  nodes: Array<Device>
}

export type Device_Aggregate_Bool_Exp = {
  count?: InputMaybe<Device_Aggregate_Bool_Exp_Count>
}

export type Device_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Device_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "device" */
export type Device_Aggregate_Fields = {
  __typename?: 'device_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Device_Max_Fields>
  min?: Maybe<Device_Min_Fields>
}

/** aggregate fields of "device" */
export type Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "device" */
export type Device_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Device_Max_Order_By>
  min?: InputMaybe<Device_Min_Order_By>
}

/** input type for inserting array relation for remote table "device" */
export type Device_Arr_Rel_Insert_Input = {
  data: Array<Device_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** Boolean expression to filter rows from the table "device". All fields are combined with a logical 'AND'. */
export type Device_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Bool_Exp>>
  _not?: InputMaybe<Device_Bool_Exp>
  _or?: InputMaybe<Array<Device_Bool_Exp>>
  agents?: InputMaybe<Agent_Bool_Exp>
  agents_aggregate?: InputMaybe<Agent_Aggregate_Bool_Exp>
  alarm_email?: InputMaybe<String_Comparison_Exp>
  alarm_phone_number?: InputMaybe<String_Comparison_Exp>
  cooks?: InputMaybe<Cook_Bool_Exp>
  cooks_aggregate?: InputMaybe<Cook_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  emit_metrics_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  info?: InputMaybe<Device_Info_Bool_Exp>
  internal_ip_address?: InputMaybe<String_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  recipes?: InputMaybe<Recipe_Bool_Exp>
  recipes_aggregate?: InputMaybe<Recipe_Aggregate_Bool_Exp>
  serial_number?: InputMaybe<String_Comparison_Exp>
  test_connection_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_device_settings?: InputMaybe<User_Device_Setting_Bool_Exp>
  user_device_settings_aggregate?: InputMaybe<User_Device_Setting_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "device" */
export enum Device_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlaceNameKey = 'place_name_key',
  /** unique or primary key constraint on columns "id" */
  PlacePkey = 'place_pkey',
  /** unique or primary key constraint on columns "serial_number" */
  PlaceSerialNumberKey = 'place_serial_number_key'
}

/** columns and relationships of "device_info" */
export type Device_Info = {
  __typename?: 'device_info'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']
  external_ip_address?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  internal_ip_address?: Maybe<Scalars['String']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "device_info" */
export type Device_Info_Aggregate = {
  __typename?: 'device_info_aggregate'
  aggregate?: Maybe<Device_Info_Aggregate_Fields>
  nodes: Array<Device_Info>
}

/** aggregate fields of "device_info" */
export type Device_Info_Aggregate_Fields = {
  __typename?: 'device_info_aggregate_fields'
  avg?: Maybe<Device_Info_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Device_Info_Max_Fields>
  min?: Maybe<Device_Info_Min_Fields>
  stddev?: Maybe<Device_Info_Stddev_Fields>
  stddev_pop?: Maybe<Device_Info_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Device_Info_Stddev_Samp_Fields>
  sum?: Maybe<Device_Info_Sum_Fields>
  var_pop?: Maybe<Device_Info_Var_Pop_Fields>
  var_samp?: Maybe<Device_Info_Var_Samp_Fields>
  variance?: Maybe<Device_Info_Variance_Fields>
}

/** aggregate fields of "device_info" */
export type Device_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Info_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Device_Info_Avg_Fields = {
  __typename?: 'device_info_avg_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "device_info". All fields are combined with a logical 'AND'. */
export type Device_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Info_Bool_Exp>>
  _not?: InputMaybe<Device_Info_Bool_Exp>
  _or?: InputMaybe<Array<Device_Info_Bool_Exp>>
  cpu_usage_percentage?: InputMaybe<Float_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  external_ip_address?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  internal_ip_address?: InputMaybe<String_Comparison_Exp>
  total_disk?: InputMaybe<Float_Comparison_Exp>
  total_disk_available?: InputMaybe<Float_Comparison_Exp>
  total_disk_used?: InputMaybe<Float_Comparison_Exp>
  total_ram?: InputMaybe<Float_Comparison_Exp>
  total_ram_available?: InputMaybe<Float_Comparison_Exp>
  total_ram_used?: InputMaybe<Float_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "device_info" */
export enum Device_Info_Constraint {
  /** unique or primary key constraint on columns "device_id" */
  DeviceInfoDeviceIdKey = 'device_info_device_id_key',
  /** unique or primary key constraint on columns "id" */
  DeviceInfoPkey = 'device_info_pkey'
}

/** input type for incrementing numeric columns in table "device_info" */
export type Device_Info_Inc_Input = {
  cpu_usage_percentage?: InputMaybe<Scalars['Float']>
  total_disk?: InputMaybe<Scalars['Float']>
  total_disk_available?: InputMaybe<Scalars['Float']>
  total_disk_used?: InputMaybe<Scalars['Float']>
  total_ram?: InputMaybe<Scalars['Float']>
  total_ram_available?: InputMaybe<Scalars['Float']>
  total_ram_used?: InputMaybe<Scalars['Float']>
}

/** input type for inserting data into table "device_info" */
export type Device_Info_Insert_Input = {
  cpu_usage_percentage?: InputMaybe<Scalars['Float']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']>
  external_ip_address?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  internal_ip_address?: InputMaybe<Scalars['String']>
  total_disk?: InputMaybe<Scalars['Float']>
  total_disk_available?: InputMaybe<Scalars['Float']>
  total_disk_used?: InputMaybe<Scalars['Float']>
  total_ram?: InputMaybe<Scalars['Float']>
  total_ram_available?: InputMaybe<Scalars['Float']>
  total_ram_used?: InputMaybe<Scalars['Float']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Device_Info_Max_Fields = {
  __typename?: 'device_info_max_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  created_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  external_ip_address?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  internal_ip_address?: Maybe<Scalars['String']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Device_Info_Min_Fields = {
  __typename?: 'device_info_min_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  created_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  external_ip_address?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  internal_ip_address?: Maybe<Scalars['String']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "device_info" */
export type Device_Info_Mutation_Response = {
  __typename?: 'device_info_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Info>
}

/** input type for inserting object relation for remote table "device_info" */
export type Device_Info_Obj_Rel_Insert_Input = {
  data: Device_Info_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Device_Info_On_Conflict>
}

/** on_conflict condition type for table "device_info" */
export type Device_Info_On_Conflict = {
  constraint: Device_Info_Constraint
  update_columns?: Array<Device_Info_Update_Column>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

/** Ordering options when selecting data from "device_info". */
export type Device_Info_Order_By = {
  cpu_usage_percentage?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  external_ip_address?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  internal_ip_address?: InputMaybe<Order_By>
  total_disk?: InputMaybe<Order_By>
  total_disk_available?: InputMaybe<Order_By>
  total_disk_used?: InputMaybe<Order_By>
  total_ram?: InputMaybe<Order_By>
  total_ram_available?: InputMaybe<Order_By>
  total_ram_used?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: device_info */
export type Device_Info_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "device_info" */
export enum Device_Info_Select_Column {
  /** column name */
  CpuUsagePercentage = 'cpu_usage_percentage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ExternalIpAddress = 'external_ip_address',
  /** column name */
  Id = 'id',
  /** column name */
  InternalIpAddress = 'internal_ip_address',
  /** column name */
  TotalDisk = 'total_disk',
  /** column name */
  TotalDiskAvailable = 'total_disk_available',
  /** column name */
  TotalDiskUsed = 'total_disk_used',
  /** column name */
  TotalRam = 'total_ram',
  /** column name */
  TotalRamAvailable = 'total_ram_available',
  /** column name */
  TotalRamUsed = 'total_ram_used',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "device_info" */
export type Device_Info_Set_Input = {
  cpu_usage_percentage?: InputMaybe<Scalars['Float']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  external_ip_address?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  internal_ip_address?: InputMaybe<Scalars['String']>
  total_disk?: InputMaybe<Scalars['Float']>
  total_disk_available?: InputMaybe<Scalars['Float']>
  total_disk_used?: InputMaybe<Scalars['Float']>
  total_ram?: InputMaybe<Scalars['Float']>
  total_ram_available?: InputMaybe<Scalars['Float']>
  total_ram_used?: InputMaybe<Scalars['Float']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Device_Info_Stddev_Fields = {
  __typename?: 'device_info_stddev_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Device_Info_Stddev_Pop_Fields = {
  __typename?: 'device_info_stddev_pop_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Device_Info_Stddev_Samp_Fields = {
  __typename?: 'device_info_stddev_samp_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "device_info" */
export type Device_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Info_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Info_Stream_Cursor_Value_Input = {
  cpu_usage_percentage?: InputMaybe<Scalars['Float']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  external_ip_address?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  internal_ip_address?: InputMaybe<Scalars['String']>
  total_disk?: InputMaybe<Scalars['Float']>
  total_disk_available?: InputMaybe<Scalars['Float']>
  total_disk_used?: InputMaybe<Scalars['Float']>
  total_ram?: InputMaybe<Scalars['Float']>
  total_ram_available?: InputMaybe<Scalars['Float']>
  total_ram_used?: InputMaybe<Scalars['Float']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Device_Info_Sum_Fields = {
  __typename?: 'device_info_sum_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** update columns of table "device_info" */
export enum Device_Info_Update_Column {
  /** column name */
  CpuUsagePercentage = 'cpu_usage_percentage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ExternalIpAddress = 'external_ip_address',
  /** column name */
  Id = 'id',
  /** column name */
  InternalIpAddress = 'internal_ip_address',
  /** column name */
  TotalDisk = 'total_disk',
  /** column name */
  TotalDiskAvailable = 'total_disk_available',
  /** column name */
  TotalDiskUsed = 'total_disk_used',
  /** column name */
  TotalRam = 'total_ram',
  /** column name */
  TotalRamAvailable = 'total_ram_available',
  /** column name */
  TotalRamUsed = 'total_ram_used',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Device_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Device_Info_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Info_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Info_Bool_Exp
}

/** aggregate var_pop on columns */
export type Device_Info_Var_Pop_Fields = {
  __typename?: 'device_info_var_pop_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Device_Info_Var_Samp_Fields = {
  __typename?: 'device_info_var_samp_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Device_Info_Variance_Fields = {
  __typename?: 'device_info_variance_fields'
  cpu_usage_percentage?: Maybe<Scalars['Float']>
  total_disk?: Maybe<Scalars['Float']>
  total_disk_available?: Maybe<Scalars['Float']>
  total_disk_used?: Maybe<Scalars['Float']>
  total_ram?: Maybe<Scalars['Float']>
  total_ram_available?: Maybe<Scalars['Float']>
  total_ram_used?: Maybe<Scalars['Float']>
}

/** input type for inserting data into table "device" */
export type Device_Insert_Input = {
  agents?: InputMaybe<Agent_Arr_Rel_Insert_Input>
  alarm_email?: InputMaybe<Scalars['String']>
  alarm_phone_number?: InputMaybe<Scalars['String']>
  cooks?: InputMaybe<Cook_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  emit_metrics_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  info?: InputMaybe<Device_Info_Obj_Rel_Insert_Input>
  internal_ip_address?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  recipes?: InputMaybe<Recipe_Arr_Rel_Insert_Input>
  serial_number?: InputMaybe<Scalars['String']>
  test_connection_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_device_settings?: InputMaybe<User_Device_Setting_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Device_Max_Fields = {
  __typename?: 'device_max_fields'
  alarm_email?: Maybe<Scalars['String']>
  alarm_phone_number?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  emit_metrics_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  internal_ip_address?: Maybe<Scalars['String']>
  location_id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  serial_number?: Maybe<Scalars['String']>
  test_connection_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "device" */
export type Device_Max_Order_By = {
  alarm_email?: InputMaybe<Order_By>
  alarm_phone_number?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  emit_metrics_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  internal_ip_address?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  serial_number?: InputMaybe<Order_By>
  test_connection_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Device_Min_Fields = {
  __typename?: 'device_min_fields'
  alarm_email?: Maybe<Scalars['String']>
  alarm_phone_number?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  emit_metrics_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  internal_ip_address?: Maybe<Scalars['String']>
  location_id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  serial_number?: Maybe<Scalars['String']>
  test_connection_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "device" */
export type Device_Min_Order_By = {
  alarm_email?: InputMaybe<Order_By>
  alarm_phone_number?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  emit_metrics_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  internal_ip_address?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  serial_number?: InputMaybe<Order_By>
  test_connection_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "device" */
export type Device_Mutation_Response = {
  __typename?: 'device_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Device>
}

/** input type for inserting object relation for remote table "device" */
export type Device_Obj_Rel_Insert_Input = {
  data: Device_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** on_conflict condition type for table "device" */
export type Device_On_Conflict = {
  constraint: Device_Constraint
  update_columns?: Array<Device_Update_Column>
  where?: InputMaybe<Device_Bool_Exp>
}

/** Ordering options when selecting data from "device". */
export type Device_Order_By = {
  agents_aggregate?: InputMaybe<Agent_Aggregate_Order_By>
  alarm_email?: InputMaybe<Order_By>
  alarm_phone_number?: InputMaybe<Order_By>
  cooks_aggregate?: InputMaybe<Cook_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  emit_metrics_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  info?: InputMaybe<Device_Info_Order_By>
  internal_ip_address?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  recipes_aggregate?: InputMaybe<Recipe_Aggregate_Order_By>
  serial_number?: InputMaybe<Order_By>
  test_connection_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_device_settings_aggregate?: InputMaybe<User_Device_Setting_Aggregate_Order_By>
}

/** primary key columns input for table: device */
export type Device_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "device" */
export enum Device_Select_Column {
  /** column name */
  AlarmEmail = 'alarm_email',
  /** column name */
  AlarmPhoneNumber = 'alarm_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmitMetricsAt = 'emit_metrics_at',
  /** column name */
  Id = 'id',
  /** column name */
  InternalIpAddress = 'internal_ip_address',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TestConnectionAt = 'test_connection_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "device" */
export type Device_Set_Input = {
  alarm_email?: InputMaybe<Scalars['String']>
  alarm_phone_number?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  emit_metrics_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  internal_ip_address?: InputMaybe<Scalars['String']>
  location_id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  serial_number?: InputMaybe<Scalars['String']>
  test_connection_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "device" */
export type Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Stream_Cursor_Value_Input = {
  alarm_email?: InputMaybe<Scalars['String']>
  alarm_phone_number?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  emit_metrics_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  internal_ip_address?: InputMaybe<Scalars['String']>
  location_id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  serial_number?: InputMaybe<Scalars['String']>
  test_connection_at?: InputMaybe<Scalars['timestamptz']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "device" */
export enum Device_Update_Column {
  /** column name */
  AlarmEmail = 'alarm_email',
  /** column name */
  AlarmPhoneNumber = 'alarm_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmitMetricsAt = 'emit_metrics_at',
  /** column name */
  Id = 'id',
  /** column name */
  InternalIpAddress = 'internal_ip_address',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TestConnectionAt = 'test_connection_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Device_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Bool_Exp
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location'
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  id: Scalars['uuid']
  name: Scalars['String']
  /** An object relationship */
  tenant: Tenant
  tenant_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An array relationship */
  users: Array<User_Location>
  /** An aggregate relationship */
  users_aggregate: User_Location_Aggregate
}

/** columns and relationships of "location" */
export type LocationDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate'
  aggregate?: Maybe<Location_Aggregate_Fields>
  nodes: Array<Location>
}

export type Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Location_Aggregate_Bool_Exp_Count>
}

export type Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Location_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Location_Max_Fields>
  min?: Maybe<Location_Min_Fields>
}

/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Location_Max_Order_By>
  min?: InputMaybe<Location_Min_Order_By>
}

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Bool_Exp>>
  _not?: InputMaybe<Location_Bool_Exp>
  _or?: InputMaybe<Array<Location_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  tenant?: InputMaybe<Tenant_Bool_Exp>
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  users?: InputMaybe<User_Location_Bool_Exp>
  users_aggregate?: InputMaybe<User_Location_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  users?: InputMaybe<User_Location_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Location>
}

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint
  update_columns?: Array<Location_Update_Column>
  where?: InputMaybe<Location_Bool_Exp>
}

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tenant?: InputMaybe<Tenant_Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Location_Aggregate_Order_By>
}

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Location_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp
}

/** columns and relationships of "metric" */
export type Metric = {
  __typename?: 'metric'
  /** An object relationship */
  agent: Agent
  agent_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  metric_type: Metric_Type
  type: Metric_Type_Enum
  updated_at: Scalars['timestamptz']
  value: Scalars['Float']
}

/** aggregated selection of "metric" */
export type Metric_Aggregate = {
  __typename?: 'metric_aggregate'
  aggregate?: Maybe<Metric_Aggregate_Fields>
  nodes: Array<Metric>
}

export type Metric_Aggregate_Bool_Exp = {
  count?: InputMaybe<Metric_Aggregate_Bool_Exp_Count>
}

export type Metric_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Metric_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Metric_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "metric" */
export type Metric_Aggregate_Fields = {
  __typename?: 'metric_aggregate_fields'
  avg?: Maybe<Metric_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Metric_Max_Fields>
  min?: Maybe<Metric_Min_Fields>
  stddev?: Maybe<Metric_Stddev_Fields>
  stddev_pop?: Maybe<Metric_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Metric_Stddev_Samp_Fields>
  sum?: Maybe<Metric_Sum_Fields>
  var_pop?: Maybe<Metric_Var_Pop_Fields>
  var_samp?: Maybe<Metric_Var_Samp_Fields>
  variance?: Maybe<Metric_Variance_Fields>
}

/** aggregate fields of "metric" */
export type Metric_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "metric" */
export type Metric_Aggregate_Order_By = {
  avg?: InputMaybe<Metric_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Metric_Max_Order_By>
  min?: InputMaybe<Metric_Min_Order_By>
  stddev?: InputMaybe<Metric_Stddev_Order_By>
  stddev_pop?: InputMaybe<Metric_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Metric_Stddev_Samp_Order_By>
  sum?: InputMaybe<Metric_Sum_Order_By>
  var_pop?: InputMaybe<Metric_Var_Pop_Order_By>
  var_samp?: InputMaybe<Metric_Var_Samp_Order_By>
  variance?: InputMaybe<Metric_Variance_Order_By>
}

/** input type for inserting array relation for remote table "metric" */
export type Metric_Arr_Rel_Insert_Input = {
  data: Array<Metric_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Metric_On_Conflict>
}

/** aggregate avg on columns */
export type Metric_Avg_Fields = {
  __typename?: 'metric_avg_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "metric" */
export type Metric_Avg_Order_By = {
  value?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "metric". All fields are combined with a logical 'AND'. */
export type Metric_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Bool_Exp>>
  _not?: InputMaybe<Metric_Bool_Exp>
  _or?: InputMaybe<Array<Metric_Bool_Exp>>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  metric_type?: InputMaybe<Metric_Type_Bool_Exp>
  type?: InputMaybe<Metric_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<Float_Comparison_Exp>
}

/** unique or primary key constraints on table "metric" */
export enum Metric_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetricPkey = 'metric_pkey'
}

/** input type for incrementing numeric columns in table "metric" */
export type Metric_Inc_Input = {
  value?: InputMaybe<Scalars['Float']>
}

/** input type for inserting data into table "metric" */
export type Metric_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  metric_type?: InputMaybe<Metric_Type_Obj_Rel_Insert_Input>
  type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['Float']>
}

/** aggregate max on columns */
export type Metric_Max_Fields = {
  __typename?: 'metric_max_fields'
  agent_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  value?: Maybe<Scalars['Float']>
}

/** order by max() on columns of table "metric" */
export type Metric_Max_Order_By = {
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Metric_Min_Fields = {
  __typename?: 'metric_min_fields'
  agent_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  value?: Maybe<Scalars['Float']>
}

/** order by min() on columns of table "metric" */
export type Metric_Min_Order_By = {
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "metric" */
export type Metric_Mutation_Response = {
  __typename?: 'metric_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Metric>
}

/** on_conflict condition type for table "metric" */
export type Metric_On_Conflict = {
  constraint: Metric_Constraint
  update_columns?: Array<Metric_Update_Column>
  where?: InputMaybe<Metric_Bool_Exp>
}

/** Ordering options when selecting data from "metric". */
export type Metric_Order_By = {
  agent?: InputMaybe<Agent_Order_By>
  agent_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  metric_type?: InputMaybe<Metric_Type_Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: metric */
export type Metric_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "metric" */
export enum Metric_Select_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "metric" */
export type Metric_Set_Input = {
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['Float']>
}

/** aggregate stddev on columns */
export type Metric_Stddev_Fields = {
  __typename?: 'metric_stddev_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "metric" */
export type Metric_Stddev_Order_By = {
  value?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Metric_Stddev_Pop_Fields = {
  __typename?: 'metric_stddev_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "metric" */
export type Metric_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Metric_Stddev_Samp_Fields = {
  __typename?: 'metric_stddev_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "metric" */
export type Metric_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "metric" */
export type Metric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Metric_Stream_Cursor_Value_Input = {
  agent_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Metric_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['Float']>
}

/** aggregate sum on columns */
export type Metric_Sum_Fields = {
  __typename?: 'metric_sum_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by sum() on columns of table "metric" */
export type Metric_Sum_Order_By = {
  value?: InputMaybe<Order_By>
}

/** columns and relationships of "metric_type" */
export type Metric_Type = {
  __typename?: 'metric_type'
  /** An array relationship */
  agent_metric_types: Array<Agent_Metric_Type>
  /** An aggregate relationship */
  agent_metric_types_aggregate: Agent_Metric_Type_Aggregate
  comment?: Maybe<Scalars['String']>
  /** An array relationship */
  metrics: Array<Metric>
  /** An aggregate relationship */
  metrics_aggregate: Metric_Aggregate
  value: Scalars['String']
}

/** columns and relationships of "metric_type" */
export type Metric_TypeAgent_Metric_TypesArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

/** columns and relationships of "metric_type" */
export type Metric_TypeAgent_Metric_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

/** columns and relationships of "metric_type" */
export type Metric_TypeMetricsArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

/** columns and relationships of "metric_type" */
export type Metric_TypeMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

/** aggregated selection of "metric_type" */
export type Metric_Type_Aggregate = {
  __typename?: 'metric_type_aggregate'
  aggregate?: Maybe<Metric_Type_Aggregate_Fields>
  nodes: Array<Metric_Type>
}

/** aggregate fields of "metric_type" */
export type Metric_Type_Aggregate_Fields = {
  __typename?: 'metric_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Metric_Type_Max_Fields>
  min?: Maybe<Metric_Type_Min_Fields>
}

/** aggregate fields of "metric_type" */
export type Metric_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "metric_type". All fields are combined with a logical 'AND'. */
export type Metric_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Type_Bool_Exp>>
  _not?: InputMaybe<Metric_Type_Bool_Exp>
  _or?: InputMaybe<Array<Metric_Type_Bool_Exp>>
  agent_metric_types?: InputMaybe<Agent_Metric_Type_Bool_Exp>
  agent_metric_types_aggregate?: InputMaybe<Agent_Metric_Type_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  metrics?: InputMaybe<Metric_Bool_Exp>
  metrics_aggregate?: InputMaybe<Metric_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "metric_type" */
export enum Metric_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MetricTypePkey = 'metric_type_pkey'
}

export enum Metric_Type_Enum {
  /** HUMIDITY */
  Humidity = 'HUMIDITY',
  /** ORP */
  Orp = 'ORP',
  /** PH */
  Ph = 'PH',
  /** RELAY */
  Relay = 'RELAY',
  /** TEMP */
  Temp = 'TEMP'
}

/** Boolean expression to compare columns of type "metric_type_enum". All fields are combined with logical 'AND'. */
export type Metric_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Metric_Type_Enum>
  _in?: InputMaybe<Array<Metric_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Metric_Type_Enum>
  _nin?: InputMaybe<Array<Metric_Type_Enum>>
}

/** input type for inserting data into table "metric_type" */
export type Metric_Type_Insert_Input = {
  agent_metric_types?: InputMaybe<Agent_Metric_Type_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']>
  metrics?: InputMaybe<Metric_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Metric_Type_Max_Fields = {
  __typename?: 'metric_type_max_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Metric_Type_Min_Fields = {
  __typename?: 'metric_type_min_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "metric_type" */
export type Metric_Type_Mutation_Response = {
  __typename?: 'metric_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Metric_Type>
}

/** input type for inserting object relation for remote table "metric_type" */
export type Metric_Type_Obj_Rel_Insert_Input = {
  data: Metric_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Metric_Type_On_Conflict>
}

/** on_conflict condition type for table "metric_type" */
export type Metric_Type_On_Conflict = {
  constraint: Metric_Type_Constraint
  update_columns?: Array<Metric_Type_Update_Column>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

/** Ordering options when selecting data from "metric_type". */
export type Metric_Type_Order_By = {
  agent_metric_types_aggregate?: InputMaybe<Agent_Metric_Type_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  metrics_aggregate?: InputMaybe<Metric_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: metric_type */
export type Metric_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "metric_type" */
export enum Metric_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "metric_type" */
export type Metric_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "metric_type" */
export type Metric_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Metric_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** update columns of table "metric_type" */
export enum Metric_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Metric_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Metric_Type_Bool_Exp
}

/** update columns of table "metric" */
export enum Metric_Update_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Metric_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Metric_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_Set_Input>
  /** filter the rows which have to be updated */
  where: Metric_Bool_Exp
}

/** aggregate var_pop on columns */
export type Metric_Var_Pop_Fields = {
  __typename?: 'metric_var_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "metric" */
export type Metric_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Metric_Var_Samp_Fields = {
  __typename?: 'metric_var_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "metric" */
export type Metric_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Metric_Variance_Fields = {
  __typename?: 'metric_variance_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "metric" */
export type Metric_Variance_Order_By = {
  value?: InputMaybe<Order_By>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "agent" */
  delete_agent?: Maybe<Agent_Mutation_Response>
  /** delete single row from the table: "agent" */
  delete_agent_by_pk?: Maybe<Agent>
  /** delete data from the table: "agent_metric_type" */
  delete_agent_metric_type?: Maybe<Agent_Metric_Type_Mutation_Response>
  /** delete single row from the table: "agent_metric_type" */
  delete_agent_metric_type_by_pk?: Maybe<Agent_Metric_Type>
  /** delete data from the table: "agent_type" */
  delete_agent_type?: Maybe<Agent_Type_Mutation_Response>
  /** delete single row from the table: "agent_type" */
  delete_agent_type_by_pk?: Maybe<Agent_Type>
  /** delete data from the table: "alarm" */
  delete_alarm?: Maybe<Alarm_Mutation_Response>
  /** delete single row from the table: "alarm" */
  delete_alarm_by_pk?: Maybe<Alarm>
  /** delete data from the table: "cook" */
  delete_cook?: Maybe<Cook_Mutation_Response>
  /** delete single row from the table: "cook" */
  delete_cook_by_pk?: Maybe<Cook>
  /** delete data from the table: "cook_recipe_step" */
  delete_cook_recipe_step?: Maybe<Cook_Recipe_Step_Mutation_Response>
  /** delete single row from the table: "cook_recipe_step" */
  delete_cook_recipe_step_by_pk?: Maybe<Cook_Recipe_Step>
  /** delete data from the table: "device" */
  delete_device?: Maybe<Device_Mutation_Response>
  /** delete single row from the table: "device" */
  delete_device_by_pk?: Maybe<Device>
  /** delete data from the table: "device_info" */
  delete_device_info?: Maybe<Device_Info_Mutation_Response>
  /** delete single row from the table: "device_info" */
  delete_device_info_by_pk?: Maybe<Device_Info>
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>
  /** delete data from the table: "metric" */
  delete_metric?: Maybe<Metric_Mutation_Response>
  /** delete single row from the table: "metric" */
  delete_metric_by_pk?: Maybe<Metric>
  /** delete data from the table: "metric_type" */
  delete_metric_type?: Maybe<Metric_Type_Mutation_Response>
  /** delete single row from the table: "metric_type" */
  delete_metric_type_by_pk?: Maybe<Metric_Type>
  /** delete data from the table: "recipe" */
  delete_recipe?: Maybe<Recipe_Mutation_Response>
  /** delete single row from the table: "recipe" */
  delete_recipe_by_pk?: Maybe<Recipe>
  /** delete data from the table: "recipe_step" */
  delete_recipe_step?: Maybe<Recipe_Step_Mutation_Response>
  /** delete single row from the table: "recipe_step" */
  delete_recipe_step_by_pk?: Maybe<Recipe_Step>
  /** delete data from the table: "tenant" */
  delete_tenant?: Maybe<Tenant_Mutation_Response>
  /** delete single row from the table: "tenant" */
  delete_tenant_by_pk?: Maybe<Tenant>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_device_setting" */
  delete_user_device_setting?: Maybe<User_Device_Setting_Mutation_Response>
  /** delete single row from the table: "user_device_setting" */
  delete_user_device_setting_by_pk?: Maybe<User_Device_Setting>
  /** delete data from the table: "user_location" */
  delete_user_location?: Maybe<User_Location_Mutation_Response>
  /** delete single row from the table: "user_location" */
  delete_user_location_by_pk?: Maybe<User_Location>
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>
  /** insert data into the table: "agent" */
  insert_agent?: Maybe<Agent_Mutation_Response>
  /** insert data into the table: "agent_metric_type" */
  insert_agent_metric_type?: Maybe<Agent_Metric_Type_Mutation_Response>
  /** insert a single row into the table: "agent_metric_type" */
  insert_agent_metric_type_one?: Maybe<Agent_Metric_Type>
  /** insert a single row into the table: "agent" */
  insert_agent_one?: Maybe<Agent>
  /** insert data into the table: "agent_type" */
  insert_agent_type?: Maybe<Agent_Type_Mutation_Response>
  /** insert a single row into the table: "agent_type" */
  insert_agent_type_one?: Maybe<Agent_Type>
  /** insert data into the table: "alarm" */
  insert_alarm?: Maybe<Alarm_Mutation_Response>
  /** insert a single row into the table: "alarm" */
  insert_alarm_one?: Maybe<Alarm>
  /** insert data into the table: "cook" */
  insert_cook?: Maybe<Cook_Mutation_Response>
  /** insert a single row into the table: "cook" */
  insert_cook_one?: Maybe<Cook>
  /** insert data into the table: "cook_recipe_step" */
  insert_cook_recipe_step?: Maybe<Cook_Recipe_Step_Mutation_Response>
  /** insert a single row into the table: "cook_recipe_step" */
  insert_cook_recipe_step_one?: Maybe<Cook_Recipe_Step>
  /** insert data into the table: "device" */
  insert_device?: Maybe<Device_Mutation_Response>
  /** insert data into the table: "device_info" */
  insert_device_info?: Maybe<Device_Info_Mutation_Response>
  /** insert a single row into the table: "device_info" */
  insert_device_info_one?: Maybe<Device_Info>
  /** insert a single row into the table: "device" */
  insert_device_one?: Maybe<Device>
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>
  /** insert data into the table: "metric" */
  insert_metric?: Maybe<Metric_Mutation_Response>
  /** insert a single row into the table: "metric" */
  insert_metric_one?: Maybe<Metric>
  /** insert data into the table: "metric_type" */
  insert_metric_type?: Maybe<Metric_Type_Mutation_Response>
  /** insert a single row into the table: "metric_type" */
  insert_metric_type_one?: Maybe<Metric_Type>
  /** insert data into the table: "recipe" */
  insert_recipe?: Maybe<Recipe_Mutation_Response>
  /** insert a single row into the table: "recipe" */
  insert_recipe_one?: Maybe<Recipe>
  /** insert data into the table: "recipe_step" */
  insert_recipe_step?: Maybe<Recipe_Step_Mutation_Response>
  /** insert a single row into the table: "recipe_step" */
  insert_recipe_step_one?: Maybe<Recipe_Step>
  /** insert data into the table: "tenant" */
  insert_tenant?: Maybe<Tenant_Mutation_Response>
  /** insert a single row into the table: "tenant" */
  insert_tenant_one?: Maybe<Tenant>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_device_setting" */
  insert_user_device_setting?: Maybe<User_Device_Setting_Mutation_Response>
  /** insert a single row into the table: "user_device_setting" */
  insert_user_device_setting_one?: Maybe<User_Device_Setting>
  /** insert data into the table: "user_location" */
  insert_user_location?: Maybe<User_Location_Mutation_Response>
  /** insert a single row into the table: "user_location" */
  insert_user_location_one?: Maybe<User_Location>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>
  /** update data of the table: "agent" */
  update_agent?: Maybe<Agent_Mutation_Response>
  /** update single row of the table: "agent" */
  update_agent_by_pk?: Maybe<Agent>
  /** update multiples rows of table: "agent" */
  update_agent_many?: Maybe<Array<Maybe<Agent_Mutation_Response>>>
  /** update data of the table: "agent_metric_type" */
  update_agent_metric_type?: Maybe<Agent_Metric_Type_Mutation_Response>
  /** update single row of the table: "agent_metric_type" */
  update_agent_metric_type_by_pk?: Maybe<Agent_Metric_Type>
  /** update multiples rows of table: "agent_metric_type" */
  update_agent_metric_type_many?: Maybe<
    Array<Maybe<Agent_Metric_Type_Mutation_Response>>
  >
  /** update data of the table: "agent_type" */
  update_agent_type?: Maybe<Agent_Type_Mutation_Response>
  /** update single row of the table: "agent_type" */
  update_agent_type_by_pk?: Maybe<Agent_Type>
  /** update multiples rows of table: "agent_type" */
  update_agent_type_many?: Maybe<Array<Maybe<Agent_Type_Mutation_Response>>>
  /** update data of the table: "alarm" */
  update_alarm?: Maybe<Alarm_Mutation_Response>
  /** update single row of the table: "alarm" */
  update_alarm_by_pk?: Maybe<Alarm>
  /** update multiples rows of table: "alarm" */
  update_alarm_many?: Maybe<Array<Maybe<Alarm_Mutation_Response>>>
  /** update data of the table: "cook" */
  update_cook?: Maybe<Cook_Mutation_Response>
  /** update single row of the table: "cook" */
  update_cook_by_pk?: Maybe<Cook>
  /** update multiples rows of table: "cook" */
  update_cook_many?: Maybe<Array<Maybe<Cook_Mutation_Response>>>
  /** update data of the table: "cook_recipe_step" */
  update_cook_recipe_step?: Maybe<Cook_Recipe_Step_Mutation_Response>
  /** update single row of the table: "cook_recipe_step" */
  update_cook_recipe_step_by_pk?: Maybe<Cook_Recipe_Step>
  /** update multiples rows of table: "cook_recipe_step" */
  update_cook_recipe_step_many?: Maybe<
    Array<Maybe<Cook_Recipe_Step_Mutation_Response>>
  >
  /** update data of the table: "device" */
  update_device?: Maybe<Device_Mutation_Response>
  /** update single row of the table: "device" */
  update_device_by_pk?: Maybe<Device>
  /** update data of the table: "device_info" */
  update_device_info?: Maybe<Device_Info_Mutation_Response>
  /** update single row of the table: "device_info" */
  update_device_info_by_pk?: Maybe<Device_Info>
  /** update multiples rows of table: "device_info" */
  update_device_info_many?: Maybe<Array<Maybe<Device_Info_Mutation_Response>>>
  /** update multiples rows of table: "device" */
  update_device_many?: Maybe<Array<Maybe<Device_Mutation_Response>>>
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>
  /** update data of the table: "metric" */
  update_metric?: Maybe<Metric_Mutation_Response>
  /** update single row of the table: "metric" */
  update_metric_by_pk?: Maybe<Metric>
  /** update multiples rows of table: "metric" */
  update_metric_many?: Maybe<Array<Maybe<Metric_Mutation_Response>>>
  /** update data of the table: "metric_type" */
  update_metric_type?: Maybe<Metric_Type_Mutation_Response>
  /** update single row of the table: "metric_type" */
  update_metric_type_by_pk?: Maybe<Metric_Type>
  /** update multiples rows of table: "metric_type" */
  update_metric_type_many?: Maybe<Array<Maybe<Metric_Type_Mutation_Response>>>
  /** update data of the table: "recipe" */
  update_recipe?: Maybe<Recipe_Mutation_Response>
  /** update single row of the table: "recipe" */
  update_recipe_by_pk?: Maybe<Recipe>
  /** update multiples rows of table: "recipe" */
  update_recipe_many?: Maybe<Array<Maybe<Recipe_Mutation_Response>>>
  /** update data of the table: "recipe_step" */
  update_recipe_step?: Maybe<Recipe_Step_Mutation_Response>
  /** update single row of the table: "recipe_step" */
  update_recipe_step_by_pk?: Maybe<Recipe_Step>
  /** update multiples rows of table: "recipe_step" */
  update_recipe_step_many?: Maybe<Array<Maybe<Recipe_Step_Mutation_Response>>>
  /** update data of the table: "tenant" */
  update_tenant?: Maybe<Tenant_Mutation_Response>
  /** update single row of the table: "tenant" */
  update_tenant_by_pk?: Maybe<Tenant>
  /** update multiples rows of table: "tenant" */
  update_tenant_many?: Maybe<Array<Maybe<Tenant_Mutation_Response>>>
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_device_setting" */
  update_user_device_setting?: Maybe<User_Device_Setting_Mutation_Response>
  /** update single row of the table: "user_device_setting" */
  update_user_device_setting_by_pk?: Maybe<User_Device_Setting>
  /** update multiples rows of table: "user_device_setting" */
  update_user_device_setting_many?: Maybe<
    Array<Maybe<User_Device_Setting_Mutation_Response>>
  >
  /** update data of the table: "user_location" */
  update_user_location?: Maybe<User_Location_Mutation_Response>
  /** update single row of the table: "user_location" */
  update_user_location_by_pk?: Maybe<User_Location>
  /** update multiples rows of table: "user_location" */
  update_user_location_many?: Maybe<
    Array<Maybe<User_Location_Mutation_Response>>
  >
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_AgentArgs = {
  where: Agent_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Agent_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Agent_Metric_TypeArgs = {
  where: Agent_Metric_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Agent_Metric_Type_By_PkArgs = {
  agent_id: Scalars['uuid']
  metric_type: Metric_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Agent_TypeArgs = {
  where: Agent_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Agent_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_AlarmArgs = {
  where: Alarm_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Alarm_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CookArgs = {
  where: Cook_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cook_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Cook_Recipe_StepArgs = {
  where: Cook_Recipe_Step_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cook_Recipe_Step_By_PkArgs = {
  cook_id: Scalars['uuid']
  recipe_step_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_DeviceArgs = {
  where: Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Device_InfoArgs = {
  where: Device_Info_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Info_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_MetricArgs = {
  where: Metric_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Metric_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Metric_TypeArgs = {
  where: Metric_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Metric_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_RecipeArgs = {
  where: Recipe_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Recipe_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Recipe_StepArgs = {
  where: Recipe_Step_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Recipe_Step_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_TenantArgs = {
  where: Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Tenant_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Device_SettingArgs = {
  where: User_Device_Setting_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Device_Setting_By_PkArgs = {
  device_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_LocationArgs = {
  where: User_Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Location_By_PkArgs = {
  location_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_AgentArgs = {
  objects: Array<Agent_Insert_Input>
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_Metric_TypeArgs = {
  objects: Array<Agent_Metric_Type_Insert_Input>
  on_conflict?: InputMaybe<Agent_Metric_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_Metric_Type_OneArgs = {
  object: Agent_Metric_Type_Insert_Input
  on_conflict?: InputMaybe<Agent_Metric_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_OneArgs = {
  object: Agent_Insert_Input
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_TypeArgs = {
  objects: Array<Agent_Type_Insert_Input>
  on_conflict?: InputMaybe<Agent_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_Type_OneArgs = {
  object: Agent_Type_Insert_Input
  on_conflict?: InputMaybe<Agent_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AlarmArgs = {
  objects: Array<Alarm_Insert_Input>
  on_conflict?: InputMaybe<Alarm_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Alarm_OneArgs = {
  object: Alarm_Insert_Input
  on_conflict?: InputMaybe<Alarm_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CookArgs = {
  objects: Array<Cook_Insert_Input>
  on_conflict?: InputMaybe<Cook_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cook_OneArgs = {
  object: Cook_Insert_Input
  on_conflict?: InputMaybe<Cook_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cook_Recipe_StepArgs = {
  objects: Array<Cook_Recipe_Step_Insert_Input>
  on_conflict?: InputMaybe<Cook_Recipe_Step_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cook_Recipe_Step_OneArgs = {
  object: Cook_Recipe_Step_Insert_Input
  on_conflict?: InputMaybe<Cook_Recipe_Step_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DeviceArgs = {
  objects: Array<Device_Insert_Input>
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_InfoArgs = {
  objects: Array<Device_Info_Insert_Input>
  on_conflict?: InputMaybe<Device_Info_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Info_OneArgs = {
  object: Device_Info_Insert_Input
  on_conflict?: InputMaybe<Device_Info_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_OneArgs = {
  object: Device_Insert_Input
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_MetricArgs = {
  objects: Array<Metric_Insert_Input>
  on_conflict?: InputMaybe<Metric_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Metric_OneArgs = {
  object: Metric_Insert_Input
  on_conflict?: InputMaybe<Metric_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Metric_TypeArgs = {
  objects: Array<Metric_Type_Insert_Input>
  on_conflict?: InputMaybe<Metric_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Metric_Type_OneArgs = {
  object: Metric_Type_Insert_Input
  on_conflict?: InputMaybe<Metric_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_RecipeArgs = {
  objects: Array<Recipe_Insert_Input>
  on_conflict?: InputMaybe<Recipe_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Recipe_OneArgs = {
  object: Recipe_Insert_Input
  on_conflict?: InputMaybe<Recipe_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Recipe_StepArgs = {
  objects: Array<Recipe_Step_Insert_Input>
  on_conflict?: InputMaybe<Recipe_Step_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Recipe_Step_OneArgs = {
  object: Recipe_Step_Insert_Input
  on_conflict?: InputMaybe<Recipe_Step_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TenantArgs = {
  objects: Array<Tenant_Insert_Input>
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tenant_OneArgs = {
  object: Tenant_Insert_Input
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Device_SettingArgs = {
  objects: Array<User_Device_Setting_Insert_Input>
  on_conflict?: InputMaybe<User_Device_Setting_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Device_Setting_OneArgs = {
  object: User_Device_Setting_Insert_Input
  on_conflict?: InputMaybe<User_Device_Setting_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_LocationArgs = {
  objects: Array<User_Location_Insert_Input>
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Location_OneArgs = {
  object: User_Location_Insert_Input
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_AgentArgs = {
  _set?: InputMaybe<Agent_Set_Input>
  where: Agent_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Agent_By_PkArgs = {
  _set?: InputMaybe<Agent_Set_Input>
  pk_columns: Agent_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Agent_ManyArgs = {
  updates: Array<Agent_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Metric_TypeArgs = {
  _set?: InputMaybe<Agent_Metric_Type_Set_Input>
  where: Agent_Metric_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Metric_Type_By_PkArgs = {
  _set?: InputMaybe<Agent_Metric_Type_Set_Input>
  pk_columns: Agent_Metric_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Metric_Type_ManyArgs = {
  updates: Array<Agent_Metric_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Agent_TypeArgs = {
  _set?: InputMaybe<Agent_Type_Set_Input>
  where: Agent_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Type_By_PkArgs = {
  _set?: InputMaybe<Agent_Type_Set_Input>
  pk_columns: Agent_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Type_ManyArgs = {
  updates: Array<Agent_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_AlarmArgs = {
  _append?: InputMaybe<Alarm_Append_Input>
  _delete_at_path?: InputMaybe<Alarm_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Alarm_Delete_Elem_Input>
  _delete_key?: InputMaybe<Alarm_Delete_Key_Input>
  _inc?: InputMaybe<Alarm_Inc_Input>
  _prepend?: InputMaybe<Alarm_Prepend_Input>
  _set?: InputMaybe<Alarm_Set_Input>
  where: Alarm_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Alarm_By_PkArgs = {
  _append?: InputMaybe<Alarm_Append_Input>
  _delete_at_path?: InputMaybe<Alarm_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Alarm_Delete_Elem_Input>
  _delete_key?: InputMaybe<Alarm_Delete_Key_Input>
  _inc?: InputMaybe<Alarm_Inc_Input>
  _prepend?: InputMaybe<Alarm_Prepend_Input>
  _set?: InputMaybe<Alarm_Set_Input>
  pk_columns: Alarm_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Alarm_ManyArgs = {
  updates: Array<Alarm_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CookArgs = {
  _append?: InputMaybe<Cook_Append_Input>
  _delete_at_path?: InputMaybe<Cook_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cook_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cook_Delete_Key_Input>
  _prepend?: InputMaybe<Cook_Prepend_Input>
  _set?: InputMaybe<Cook_Set_Input>
  where: Cook_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cook_By_PkArgs = {
  _append?: InputMaybe<Cook_Append_Input>
  _delete_at_path?: InputMaybe<Cook_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cook_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cook_Delete_Key_Input>
  _prepend?: InputMaybe<Cook_Prepend_Input>
  _set?: InputMaybe<Cook_Set_Input>
  pk_columns: Cook_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cook_ManyArgs = {
  updates: Array<Cook_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cook_Recipe_StepArgs = {
  _set?: InputMaybe<Cook_Recipe_Step_Set_Input>
  where: Cook_Recipe_Step_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cook_Recipe_Step_By_PkArgs = {
  _set?: InputMaybe<Cook_Recipe_Step_Set_Input>
  pk_columns: Cook_Recipe_Step_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cook_Recipe_Step_ManyArgs = {
  updates: Array<Cook_Recipe_Step_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_DeviceArgs = {
  _set?: InputMaybe<Device_Set_Input>
  where: Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_By_PkArgs = {
  _set?: InputMaybe<Device_Set_Input>
  pk_columns: Device_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_InfoArgs = {
  _inc?: InputMaybe<Device_Info_Inc_Input>
  _set?: InputMaybe<Device_Info_Set_Input>
  where: Device_Info_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Info_By_PkArgs = {
  _inc?: InputMaybe<Device_Info_Inc_Input>
  _set?: InputMaybe<Device_Info_Set_Input>
  pk_columns: Device_Info_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Info_ManyArgs = {
  updates: Array<Device_Info_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_ManyArgs = {
  updates: Array<Device_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _set?: InputMaybe<Location_Set_Input>
  where: Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _set?: InputMaybe<Location_Set_Input>
  pk_columns: Location_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_MetricArgs = {
  _inc?: InputMaybe<Metric_Inc_Input>
  _set?: InputMaybe<Metric_Set_Input>
  where: Metric_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Metric_By_PkArgs = {
  _inc?: InputMaybe<Metric_Inc_Input>
  _set?: InputMaybe<Metric_Set_Input>
  pk_columns: Metric_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Metric_ManyArgs = {
  updates: Array<Metric_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Metric_TypeArgs = {
  _set?: InputMaybe<Metric_Type_Set_Input>
  where: Metric_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Metric_Type_By_PkArgs = {
  _set?: InputMaybe<Metric_Type_Set_Input>
  pk_columns: Metric_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Metric_Type_ManyArgs = {
  updates: Array<Metric_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_RecipeArgs = {
  _append?: InputMaybe<Recipe_Append_Input>
  _delete_at_path?: InputMaybe<Recipe_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Recipe_Delete_Elem_Input>
  _delete_key?: InputMaybe<Recipe_Delete_Key_Input>
  _prepend?: InputMaybe<Recipe_Prepend_Input>
  _set?: InputMaybe<Recipe_Set_Input>
  where: Recipe_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Recipe_By_PkArgs = {
  _append?: InputMaybe<Recipe_Append_Input>
  _delete_at_path?: InputMaybe<Recipe_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Recipe_Delete_Elem_Input>
  _delete_key?: InputMaybe<Recipe_Delete_Key_Input>
  _prepend?: InputMaybe<Recipe_Prepend_Input>
  _set?: InputMaybe<Recipe_Set_Input>
  pk_columns: Recipe_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Recipe_ManyArgs = {
  updates: Array<Recipe_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Recipe_StepArgs = {
  _append?: InputMaybe<Recipe_Step_Append_Input>
  _delete_at_path?: InputMaybe<Recipe_Step_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Recipe_Step_Delete_Elem_Input>
  _delete_key?: InputMaybe<Recipe_Step_Delete_Key_Input>
  _inc?: InputMaybe<Recipe_Step_Inc_Input>
  _prepend?: InputMaybe<Recipe_Step_Prepend_Input>
  _set?: InputMaybe<Recipe_Step_Set_Input>
  where: Recipe_Step_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Recipe_Step_By_PkArgs = {
  _append?: InputMaybe<Recipe_Step_Append_Input>
  _delete_at_path?: InputMaybe<Recipe_Step_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Recipe_Step_Delete_Elem_Input>
  _delete_key?: InputMaybe<Recipe_Step_Delete_Key_Input>
  _inc?: InputMaybe<Recipe_Step_Inc_Input>
  _prepend?: InputMaybe<Recipe_Step_Prepend_Input>
  _set?: InputMaybe<Recipe_Step_Set_Input>
  pk_columns: Recipe_Step_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Recipe_Step_ManyArgs = {
  updates: Array<Recipe_Step_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TenantArgs = {
  _set?: InputMaybe<Tenant_Set_Input>
  where: Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tenant_By_PkArgs = {
  _set?: InputMaybe<Tenant_Set_Input>
  pk_columns: Tenant_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Tenant_ManyArgs = {
  updates: Array<Tenant_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Device_SettingArgs = {
  _inc?: InputMaybe<User_Device_Setting_Inc_Input>
  _set?: InputMaybe<User_Device_Setting_Set_Input>
  where: User_Device_Setting_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Device_Setting_By_PkArgs = {
  _inc?: InputMaybe<User_Device_Setting_Inc_Input>
  _set?: InputMaybe<User_Device_Setting_Set_Input>
  pk_columns: User_Device_Setting_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Device_Setting_ManyArgs = {
  updates: Array<User_Device_Setting_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_LocationArgs = {
  _set?: InputMaybe<User_Location_Set_Input>
  where: User_Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Location_By_PkArgs = {
  _set?: InputMaybe<User_Location_Set_Input>
  pk_columns: User_Location_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Location_ManyArgs = {
  updates: Array<User_Location_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  pk_columns: User_Role_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "agent" */
  agent: Array<Agent>
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>
  /** fetch data from the table: "agent_metric_type" */
  agent_metric_type: Array<Agent_Metric_Type>
  /** fetch aggregated fields from the table: "agent_metric_type" */
  agent_metric_type_aggregate: Agent_Metric_Type_Aggregate
  /** fetch data from the table: "agent_metric_type" using primary key columns */
  agent_metric_type_by_pk?: Maybe<Agent_Metric_Type>
  /** fetch data from the table: "agent_type" */
  agent_type: Array<Agent_Type>
  /** fetch aggregated fields from the table: "agent_type" */
  agent_type_aggregate: Agent_Type_Aggregate
  /** fetch data from the table: "agent_type" using primary key columns */
  agent_type_by_pk?: Maybe<Agent_Type>
  /** fetch data from the table: "alarm" */
  alarm: Array<Alarm>
  /** fetch aggregated fields from the table: "alarm" */
  alarm_aggregate: Alarm_Aggregate
  /** fetch data from the table: "alarm" using primary key columns */
  alarm_by_pk?: Maybe<Alarm>
  /** fetch data from the table: "cook" */
  cook: Array<Cook>
  /** fetch aggregated fields from the table: "cook" */
  cook_aggregate: Cook_Aggregate
  /** fetch data from the table: "cook" using primary key columns */
  cook_by_pk?: Maybe<Cook>
  /** fetch data from the table: "cook_recipe_step" */
  cook_recipe_step: Array<Cook_Recipe_Step>
  /** fetch aggregated fields from the table: "cook_recipe_step" */
  cook_recipe_step_aggregate: Cook_Recipe_Step_Aggregate
  /** fetch data from the table: "cook_recipe_step" using primary key columns */
  cook_recipe_step_by_pk?: Maybe<Cook_Recipe_Step>
  /** fetch data from the table: "device" */
  device: Array<Device>
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>
  /** fetch data from the table: "device_info" */
  device_info: Array<Device_Info>
  /** fetch aggregated fields from the table: "device_info" */
  device_info_aggregate: Device_Info_Aggregate
  /** fetch data from the table: "device_info" using primary key columns */
  device_info_by_pk?: Maybe<Device_Info>
  /** fetch data from the table: "location" */
  location: Array<Location>
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>
  /** fetch data from the table: "metric" */
  metric: Array<Metric>
  /** fetch aggregated fields from the table: "metric" */
  metric_aggregate: Metric_Aggregate
  /** fetch data from the table: "metric" using primary key columns */
  metric_by_pk?: Maybe<Metric>
  /** fetch data from the table: "metric_type" */
  metric_type: Array<Metric_Type>
  /** fetch aggregated fields from the table: "metric_type" */
  metric_type_aggregate: Metric_Type_Aggregate
  /** fetch data from the table: "metric_type" using primary key columns */
  metric_type_by_pk?: Maybe<Metric_Type>
  /** fetch data from the table: "recipe" */
  recipe: Array<Recipe>
  /** fetch aggregated fields from the table: "recipe" */
  recipe_aggregate: Recipe_Aggregate
  /** fetch data from the table: "recipe" using primary key columns */
  recipe_by_pk?: Maybe<Recipe>
  /** fetch data from the table: "recipe_step" */
  recipe_step: Array<Recipe_Step>
  /** fetch aggregated fields from the table: "recipe_step" */
  recipe_step_aggregate: Recipe_Step_Aggregate
  /** fetch data from the table: "recipe_step" using primary key columns */
  recipe_step_by_pk?: Maybe<Recipe_Step>
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_device_setting" */
  user_device_setting: Array<User_Device_Setting>
  /** fetch aggregated fields from the table: "user_device_setting" */
  user_device_setting_aggregate: User_Device_Setting_Aggregate
  /** fetch data from the table: "user_device_setting" using primary key columns */
  user_device_setting_by_pk?: Maybe<User_Device_Setting>
  /** fetch data from the table: "user_location" */
  user_location: Array<User_Location>
  /** fetch aggregated fields from the table: "user_location" */
  user_location_aggregate: User_Location_Aggregate
  /** fetch data from the table: "user_location" using primary key columns */
  user_location_by_pk?: Maybe<User_Location>
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
}

export type Query_RootAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Query_RootAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Query_RootAgent_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAgent_Metric_TypeArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

export type Query_RootAgent_Metric_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

export type Query_RootAgent_Metric_Type_By_PkArgs = {
  agent_id: Scalars['uuid']
  metric_type: Metric_Type_Enum
}

export type Query_RootAgent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Agent_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Type_Order_By>>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

export type Query_RootAgent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Type_Order_By>>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

export type Query_RootAgent_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootAlarmArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

export type Query_RootAlarm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

export type Query_RootAlarm_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCookArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

export type Query_RootCook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

export type Query_RootCook_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCook_Recipe_StepArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

export type Query_RootCook_Recipe_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

export type Query_RootCook_Recipe_Step_By_PkArgs = {
  cook_id: Scalars['uuid']
  recipe_step_id: Scalars['uuid']
}

export type Query_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Query_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Query_RootDevice_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDevice_InfoArgs = {
  distinct_on?: InputMaybe<Array<Device_Info_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Info_Order_By>>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

export type Query_RootDevice_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Info_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Info_Order_By>>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

export type Query_RootDevice_Info_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Query_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Query_RootLocation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMetricArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

export type Query_RootMetric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

export type Query_RootMetric_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMetric_TypeArgs = {
  distinct_on?: InputMaybe<Array<Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Type_Order_By>>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

export type Query_RootMetric_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Type_Order_By>>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

export type Query_RootMetric_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootRecipeArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

export type Query_RootRecipe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

export type Query_RootRecipe_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRecipe_StepArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

export type Query_RootRecipe_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

export type Query_RootRecipe_Step_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Query_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Query_RootTenant_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Device_SettingArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

export type Query_RootUser_Device_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

export type Query_RootUser_Device_Setting_By_PkArgs = {
  device_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

export type Query_RootUser_LocationArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Query_RootUser_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Query_RootUser_Location_By_PkArgs = {
  location_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_By_PkArgs = {
  value: Scalars['String']
}

/** columns and relationships of "recipe" */
export type Recipe = {
  __typename?: 'recipe'
  /** An array relationship */
  cooks: Array<Cook>
  /** An aggregate relationship */
  cooks_aggregate: Cook_Aggregate
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  detail?: Maybe<Scalars['String']>
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']
  id: Scalars['uuid']
  name: Scalars['String']
  /** An array relationship */
  recipe_steps: Array<Recipe_Step>
  /** An aggregate relationship */
  recipe_steps_aggregate: Recipe_Step_Aggregate
  rules?: Maybe<Scalars['jsonb']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "recipe" */
export type RecipeCooksArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

/** columns and relationships of "recipe" */
export type RecipeCooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

/** columns and relationships of "recipe" */
export type RecipeRecipe_StepsArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

/** columns and relationships of "recipe" */
export type RecipeRecipe_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

/** columns and relationships of "recipe" */
export type RecipeRulesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "recipe" */
export type Recipe_Aggregate = {
  __typename?: 'recipe_aggregate'
  aggregate?: Maybe<Recipe_Aggregate_Fields>
  nodes: Array<Recipe>
}

export type Recipe_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recipe_Aggregate_Bool_Exp_Count>
}

export type Recipe_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recipe_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Recipe_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "recipe" */
export type Recipe_Aggregate_Fields = {
  __typename?: 'recipe_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Recipe_Max_Fields>
  min?: Maybe<Recipe_Min_Fields>
}

/** aggregate fields of "recipe" */
export type Recipe_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recipe_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "recipe" */
export type Recipe_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Recipe_Max_Order_By>
  min?: InputMaybe<Recipe_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Recipe_Append_Input = {
  rules?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "recipe" */
export type Recipe_Arr_Rel_Insert_Input = {
  data: Array<Recipe_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Recipe_On_Conflict>
}

/** Boolean expression to filter rows from the table "recipe". All fields are combined with a logical 'AND'. */
export type Recipe_Bool_Exp = {
  _and?: InputMaybe<Array<Recipe_Bool_Exp>>
  _not?: InputMaybe<Recipe_Bool_Exp>
  _or?: InputMaybe<Array<Recipe_Bool_Exp>>
  cooks?: InputMaybe<Cook_Bool_Exp>
  cooks_aggregate?: InputMaybe<Cook_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  detail?: InputMaybe<String_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  recipe_steps?: InputMaybe<Recipe_Step_Bool_Exp>
  recipe_steps_aggregate?: InputMaybe<Recipe_Step_Aggregate_Bool_Exp>
  rules?: InputMaybe<Jsonb_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "recipe" */
export enum Recipe_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipePkey = 'recipe_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Recipe_Delete_At_Path_Input = {
  rules?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Recipe_Delete_Elem_Input = {
  rules?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Recipe_Delete_Key_Input = {
  rules?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "recipe" */
export type Recipe_Insert_Input = {
  cooks?: InputMaybe<Cook_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  detail?: InputMaybe<Scalars['String']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  recipe_steps?: InputMaybe<Recipe_Step_Arr_Rel_Insert_Input>
  rules?: InputMaybe<Scalars['jsonb']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Recipe_Max_Fields = {
  __typename?: 'recipe_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  detail?: Maybe<Scalars['String']>
  device_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "recipe" */
export type Recipe_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  detail?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Recipe_Min_Fields = {
  __typename?: 'recipe_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  detail?: Maybe<Scalars['String']>
  device_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "recipe" */
export type Recipe_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  detail?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "recipe" */
export type Recipe_Mutation_Response = {
  __typename?: 'recipe_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Recipe>
}

/** input type for inserting object relation for remote table "recipe" */
export type Recipe_Obj_Rel_Insert_Input = {
  data: Recipe_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Recipe_On_Conflict>
}

/** on_conflict condition type for table "recipe" */
export type Recipe_On_Conflict = {
  constraint: Recipe_Constraint
  update_columns?: Array<Recipe_Update_Column>
  where?: InputMaybe<Recipe_Bool_Exp>
}

/** Ordering options when selecting data from "recipe". */
export type Recipe_Order_By = {
  cooks_aggregate?: InputMaybe<Cook_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  detail?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  recipe_steps_aggregate?: InputMaybe<Recipe_Step_Aggregate_Order_By>
  rules?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: recipe */
export type Recipe_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Recipe_Prepend_Input = {
  rules?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "recipe" */
export enum Recipe_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Detail = 'detail',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recipe" */
export type Recipe_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  detail?: InputMaybe<Scalars['String']>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  rules?: InputMaybe<Scalars['jsonb']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "recipe_step" */
export type Recipe_Step = {
  __typename?: 'recipe_step'
  /** An array relationship */
  cook_recipe_steps: Array<Cook_Recipe_Step>
  /** An aggregate relationship */
  cook_recipe_steps_aggregate: Cook_Recipe_Step_Aggregate
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  /** An object relationship */
  recipe: Recipe
  recipe_id: Scalars['uuid']
  rules?: Maybe<Scalars['jsonb']>
  step: Scalars['Int']
  time: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "recipe_step" */
export type Recipe_StepCook_Recipe_StepsArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

/** columns and relationships of "recipe_step" */
export type Recipe_StepCook_Recipe_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

/** columns and relationships of "recipe_step" */
export type Recipe_StepRulesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "recipe_step" */
export type Recipe_Step_Aggregate = {
  __typename?: 'recipe_step_aggregate'
  aggregate?: Maybe<Recipe_Step_Aggregate_Fields>
  nodes: Array<Recipe_Step>
}

export type Recipe_Step_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recipe_Step_Aggregate_Bool_Exp_Count>
}

export type Recipe_Step_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recipe_Step_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Recipe_Step_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "recipe_step" */
export type Recipe_Step_Aggregate_Fields = {
  __typename?: 'recipe_step_aggregate_fields'
  avg?: Maybe<Recipe_Step_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Recipe_Step_Max_Fields>
  min?: Maybe<Recipe_Step_Min_Fields>
  stddev?: Maybe<Recipe_Step_Stddev_Fields>
  stddev_pop?: Maybe<Recipe_Step_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Recipe_Step_Stddev_Samp_Fields>
  sum?: Maybe<Recipe_Step_Sum_Fields>
  var_pop?: Maybe<Recipe_Step_Var_Pop_Fields>
  var_samp?: Maybe<Recipe_Step_Var_Samp_Fields>
  variance?: Maybe<Recipe_Step_Variance_Fields>
}

/** aggregate fields of "recipe_step" */
export type Recipe_Step_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recipe_Step_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "recipe_step" */
export type Recipe_Step_Aggregate_Order_By = {
  avg?: InputMaybe<Recipe_Step_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Recipe_Step_Max_Order_By>
  min?: InputMaybe<Recipe_Step_Min_Order_By>
  stddev?: InputMaybe<Recipe_Step_Stddev_Order_By>
  stddev_pop?: InputMaybe<Recipe_Step_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Recipe_Step_Stddev_Samp_Order_By>
  sum?: InputMaybe<Recipe_Step_Sum_Order_By>
  var_pop?: InputMaybe<Recipe_Step_Var_Pop_Order_By>
  var_samp?: InputMaybe<Recipe_Step_Var_Samp_Order_By>
  variance?: InputMaybe<Recipe_Step_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Recipe_Step_Append_Input = {
  rules?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "recipe_step" */
export type Recipe_Step_Arr_Rel_Insert_Input = {
  data: Array<Recipe_Step_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Recipe_Step_On_Conflict>
}

/** aggregate avg on columns */
export type Recipe_Step_Avg_Fields = {
  __typename?: 'recipe_step_avg_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "recipe_step" */
export type Recipe_Step_Avg_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "recipe_step". All fields are combined with a logical 'AND'. */
export type Recipe_Step_Bool_Exp = {
  _and?: InputMaybe<Array<Recipe_Step_Bool_Exp>>
  _not?: InputMaybe<Recipe_Step_Bool_Exp>
  _or?: InputMaybe<Array<Recipe_Step_Bool_Exp>>
  cook_recipe_steps?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
  cook_recipe_steps_aggregate?: InputMaybe<Cook_Recipe_Step_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  max_temp?: InputMaybe<Float_Comparison_Exp>
  min_temp?: InputMaybe<Float_Comparison_Exp>
  recipe?: InputMaybe<Recipe_Bool_Exp>
  recipe_id?: InputMaybe<Uuid_Comparison_Exp>
  rules?: InputMaybe<Jsonb_Comparison_Exp>
  step?: InputMaybe<Int_Comparison_Exp>
  time?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "recipe_step" */
export enum Recipe_Step_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipeStepPkey = 'recipe_step_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Recipe_Step_Delete_At_Path_Input = {
  rules?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Recipe_Step_Delete_Elem_Input = {
  rules?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Recipe_Step_Delete_Key_Input = {
  rules?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "recipe_step" */
export type Recipe_Step_Inc_Input = {
  max_temp?: InputMaybe<Scalars['Float']>
  min_temp?: InputMaybe<Scalars['Float']>
  step?: InputMaybe<Scalars['Int']>
  time?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "recipe_step" */
export type Recipe_Step_Insert_Input = {
  cook_recipe_steps?: InputMaybe<Cook_Recipe_Step_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  max_temp?: InputMaybe<Scalars['Float']>
  min_temp?: InputMaybe<Scalars['Float']>
  recipe?: InputMaybe<Recipe_Obj_Rel_Insert_Input>
  recipe_id?: InputMaybe<Scalars['uuid']>
  rules?: InputMaybe<Scalars['jsonb']>
  step?: InputMaybe<Scalars['Int']>
  time?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Recipe_Step_Max_Fields = {
  __typename?: 'recipe_step_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  recipe_id?: Maybe<Scalars['uuid']>
  step?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "recipe_step" */
export type Recipe_Step_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  recipe_id?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Recipe_Step_Min_Fields = {
  __typename?: 'recipe_step_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  recipe_id?: Maybe<Scalars['uuid']>
  step?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "recipe_step" */
export type Recipe_Step_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  recipe_id?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "recipe_step" */
export type Recipe_Step_Mutation_Response = {
  __typename?: 'recipe_step_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Recipe_Step>
}

/** input type for inserting object relation for remote table "recipe_step" */
export type Recipe_Step_Obj_Rel_Insert_Input = {
  data: Recipe_Step_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Recipe_Step_On_Conflict>
}

/** on_conflict condition type for table "recipe_step" */
export type Recipe_Step_On_Conflict = {
  constraint: Recipe_Step_Constraint
  update_columns?: Array<Recipe_Step_Update_Column>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

/** Ordering options when selecting data from "recipe_step". */
export type Recipe_Step_Order_By = {
  cook_recipe_steps_aggregate?: InputMaybe<Cook_Recipe_Step_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  recipe?: InputMaybe<Recipe_Order_By>
  recipe_id?: InputMaybe<Order_By>
  rules?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: recipe_step */
export type Recipe_Step_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Recipe_Step_Prepend_Input = {
  rules?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "recipe_step" */
export enum Recipe_Step_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxTemp = 'max_temp',
  /** column name */
  MinTemp = 'min_temp',
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Step = 'step',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recipe_step" */
export type Recipe_Step_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  max_temp?: InputMaybe<Scalars['Float']>
  min_temp?: InputMaybe<Scalars['Float']>
  recipe_id?: InputMaybe<Scalars['uuid']>
  rules?: InputMaybe<Scalars['jsonb']>
  step?: InputMaybe<Scalars['Int']>
  time?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Recipe_Step_Stddev_Fields = {
  __typename?: 'recipe_step_stddev_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "recipe_step" */
export type Recipe_Step_Stddev_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Recipe_Step_Stddev_Pop_Fields = {
  __typename?: 'recipe_step_stddev_pop_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "recipe_step" */
export type Recipe_Step_Stddev_Pop_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Recipe_Step_Stddev_Samp_Fields = {
  __typename?: 'recipe_step_stddev_samp_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "recipe_step" */
export type Recipe_Step_Stddev_Samp_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "recipe_step" */
export type Recipe_Step_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Step_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Recipe_Step_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  max_temp?: InputMaybe<Scalars['Float']>
  min_temp?: InputMaybe<Scalars['Float']>
  recipe_id?: InputMaybe<Scalars['uuid']>
  rules?: InputMaybe<Scalars['jsonb']>
  step?: InputMaybe<Scalars['Int']>
  time?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Recipe_Step_Sum_Fields = {
  __typename?: 'recipe_step_sum_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "recipe_step" */
export type Recipe_Step_Sum_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** update columns of table "recipe_step" */
export enum Recipe_Step_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxTemp = 'max_temp',
  /** column name */
  MinTemp = 'min_temp',
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Step = 'step',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recipe_Step_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Recipe_Step_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Recipe_Step_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Recipe_Step_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Recipe_Step_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recipe_Step_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Recipe_Step_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recipe_Step_Set_Input>
  /** filter the rows which have to be updated */
  where: Recipe_Step_Bool_Exp
}

/** aggregate var_pop on columns */
export type Recipe_Step_Var_Pop_Fields = {
  __typename?: 'recipe_step_var_pop_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "recipe_step" */
export type Recipe_Step_Var_Pop_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Recipe_Step_Var_Samp_Fields = {
  __typename?: 'recipe_step_var_samp_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "recipe_step" */
export type Recipe_Step_Var_Samp_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Recipe_Step_Variance_Fields = {
  __typename?: 'recipe_step_variance_fields'
  max_temp?: Maybe<Scalars['Float']>
  min_temp?: Maybe<Scalars['Float']>
  step?: Maybe<Scalars['Float']>
  time?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "recipe_step" */
export type Recipe_Step_Variance_Order_By = {
  max_temp?: InputMaybe<Order_By>
  min_temp?: InputMaybe<Order_By>
  step?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "recipe" */
export type Recipe_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Recipe_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  detail?: InputMaybe<Scalars['String']>
  device_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  rules?: InputMaybe<Scalars['jsonb']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "recipe" */
export enum Recipe_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Detail = 'detail',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recipe_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Recipe_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Recipe_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Recipe_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Recipe_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Recipe_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recipe_Set_Input>
  /** filter the rows which have to be updated */
  where: Recipe_Bool_Exp
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "agent" */
  agent: Array<Agent>
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>
  /** fetch data from the table: "agent_metric_type" */
  agent_metric_type: Array<Agent_Metric_Type>
  /** fetch aggregated fields from the table: "agent_metric_type" */
  agent_metric_type_aggregate: Agent_Metric_Type_Aggregate
  /** fetch data from the table: "agent_metric_type" using primary key columns */
  agent_metric_type_by_pk?: Maybe<Agent_Metric_Type>
  /** fetch data from the table in a streaming manner: "agent_metric_type" */
  agent_metric_type_stream: Array<Agent_Metric_Type>
  /** fetch data from the table in a streaming manner: "agent" */
  agent_stream: Array<Agent>
  /** fetch data from the table: "agent_type" */
  agent_type: Array<Agent_Type>
  /** fetch aggregated fields from the table: "agent_type" */
  agent_type_aggregate: Agent_Type_Aggregate
  /** fetch data from the table: "agent_type" using primary key columns */
  agent_type_by_pk?: Maybe<Agent_Type>
  /** fetch data from the table in a streaming manner: "agent_type" */
  agent_type_stream: Array<Agent_Type>
  /** fetch data from the table: "alarm" */
  alarm: Array<Alarm>
  /** fetch aggregated fields from the table: "alarm" */
  alarm_aggregate: Alarm_Aggregate
  /** fetch data from the table: "alarm" using primary key columns */
  alarm_by_pk?: Maybe<Alarm>
  /** fetch data from the table in a streaming manner: "alarm" */
  alarm_stream: Array<Alarm>
  /** fetch data from the table: "cook" */
  cook: Array<Cook>
  /** fetch aggregated fields from the table: "cook" */
  cook_aggregate: Cook_Aggregate
  /** fetch data from the table: "cook" using primary key columns */
  cook_by_pk?: Maybe<Cook>
  /** fetch data from the table: "cook_recipe_step" */
  cook_recipe_step: Array<Cook_Recipe_Step>
  /** fetch aggregated fields from the table: "cook_recipe_step" */
  cook_recipe_step_aggregate: Cook_Recipe_Step_Aggregate
  /** fetch data from the table: "cook_recipe_step" using primary key columns */
  cook_recipe_step_by_pk?: Maybe<Cook_Recipe_Step>
  /** fetch data from the table in a streaming manner: "cook_recipe_step" */
  cook_recipe_step_stream: Array<Cook_Recipe_Step>
  /** fetch data from the table in a streaming manner: "cook" */
  cook_stream: Array<Cook>
  /** fetch data from the table: "device" */
  device: Array<Device>
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>
  /** fetch data from the table: "device_info" */
  device_info: Array<Device_Info>
  /** fetch aggregated fields from the table: "device_info" */
  device_info_aggregate: Device_Info_Aggregate
  /** fetch data from the table: "device_info" using primary key columns */
  device_info_by_pk?: Maybe<Device_Info>
  /** fetch data from the table in a streaming manner: "device_info" */
  device_info_stream: Array<Device_Info>
  /** fetch data from the table in a streaming manner: "device" */
  device_stream: Array<Device>
  /** fetch data from the table: "location" */
  location: Array<Location>
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>
  /** fetch data from the table: "metric" */
  metric: Array<Metric>
  /** fetch aggregated fields from the table: "metric" */
  metric_aggregate: Metric_Aggregate
  /** fetch data from the table: "metric" using primary key columns */
  metric_by_pk?: Maybe<Metric>
  /** fetch data from the table in a streaming manner: "metric" */
  metric_stream: Array<Metric>
  /** fetch data from the table: "metric_type" */
  metric_type: Array<Metric_Type>
  /** fetch aggregated fields from the table: "metric_type" */
  metric_type_aggregate: Metric_Type_Aggregate
  /** fetch data from the table: "metric_type" using primary key columns */
  metric_type_by_pk?: Maybe<Metric_Type>
  /** fetch data from the table in a streaming manner: "metric_type" */
  metric_type_stream: Array<Metric_Type>
  /** fetch data from the table: "recipe" */
  recipe: Array<Recipe>
  /** fetch aggregated fields from the table: "recipe" */
  recipe_aggregate: Recipe_Aggregate
  /** fetch data from the table: "recipe" using primary key columns */
  recipe_by_pk?: Maybe<Recipe>
  /** fetch data from the table: "recipe_step" */
  recipe_step: Array<Recipe_Step>
  /** fetch aggregated fields from the table: "recipe_step" */
  recipe_step_aggregate: Recipe_Step_Aggregate
  /** fetch data from the table: "recipe_step" using primary key columns */
  recipe_step_by_pk?: Maybe<Recipe_Step>
  /** fetch data from the table in a streaming manner: "recipe_step" */
  recipe_step_stream: Array<Recipe_Step>
  /** fetch data from the table in a streaming manner: "recipe" */
  recipe_stream: Array<Recipe>
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>
  /** fetch data from the table in a streaming manner: "tenant" */
  tenant_stream: Array<Tenant>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_device_setting" */
  user_device_setting: Array<User_Device_Setting>
  /** fetch aggregated fields from the table: "user_device_setting" */
  user_device_setting_aggregate: User_Device_Setting_Aggregate
  /** fetch data from the table: "user_device_setting" using primary key columns */
  user_device_setting_by_pk?: Maybe<User_Device_Setting>
  /** fetch data from the table in a streaming manner: "user_device_setting" */
  user_device_setting_stream: Array<User_Device_Setting>
  /** fetch data from the table: "user_location" */
  user_location: Array<User_Location>
  /** fetch aggregated fields from the table: "user_location" */
  user_location_aggregate: User_Location_Aggregate
  /** fetch data from the table: "user_location" using primary key columns */
  user_location_by_pk?: Maybe<User_Location>
  /** fetch data from the table in a streaming manner: "user_location" */
  user_location_stream: Array<User_Location>
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
}

export type Subscription_RootAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootAgent_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAgent_Metric_TypeArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

export type Subscription_RootAgent_Metric_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Metric_Type_Order_By>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

export type Subscription_RootAgent_Metric_Type_By_PkArgs = {
  agent_id: Scalars['uuid']
  metric_type: Metric_Type_Enum
}

export type Subscription_RootAgent_Metric_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Agent_Metric_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Agent_Metric_Type_Bool_Exp>
}

export type Subscription_RootAgent_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Agent_Stream_Cursor_Input>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootAgent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Agent_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Type_Order_By>>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

export type Subscription_RootAgent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Agent_Type_Order_By>>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

export type Subscription_RootAgent_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootAgent_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Agent_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Agent_Type_Bool_Exp>
}

export type Subscription_RootAlarmArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

export type Subscription_RootAlarm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alarm_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Alarm_Order_By>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

export type Subscription_RootAlarm_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAlarm_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Alarm_Stream_Cursor_Input>>
  where?: InputMaybe<Alarm_Bool_Exp>
}

export type Subscription_RootCookArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

export type Subscription_RootCook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Order_By>>
  where?: InputMaybe<Cook_Bool_Exp>
}

export type Subscription_RootCook_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCook_Recipe_StepArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

export type Subscription_RootCook_Recipe_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cook_Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Cook_Recipe_Step_Order_By>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

export type Subscription_RootCook_Recipe_Step_By_PkArgs = {
  cook_id: Scalars['uuid']
  recipe_step_id: Scalars['uuid']
}

export type Subscription_RootCook_Recipe_Step_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Cook_Recipe_Step_Stream_Cursor_Input>>
  where?: InputMaybe<Cook_Recipe_Step_Bool_Exp>
}

export type Subscription_RootCook_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Cook_Stream_Cursor_Input>>
  where?: InputMaybe<Cook_Bool_Exp>
}

export type Subscription_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootDevice_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDevice_InfoArgs = {
  distinct_on?: InputMaybe<Array<Device_Info_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Info_Order_By>>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

export type Subscription_RootDevice_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Info_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Device_Info_Order_By>>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

export type Subscription_RootDevice_Info_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDevice_Info_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Device_Info_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Info_Bool_Exp>
}

export type Subscription_RootDevice_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Device_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootMetricArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

export type Subscription_RootMetric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Order_By>>
  where?: InputMaybe<Metric_Bool_Exp>
}

export type Subscription_RootMetric_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMetric_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Metric_Stream_Cursor_Input>>
  where?: InputMaybe<Metric_Bool_Exp>
}

export type Subscription_RootMetric_TypeArgs = {
  distinct_on?: InputMaybe<Array<Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Type_Order_By>>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

export type Subscription_RootMetric_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Metric_Type_Order_By>>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

export type Subscription_RootMetric_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMetric_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Metric_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Metric_Type_Bool_Exp>
}

export type Subscription_RootRecipeArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

export type Subscription_RootRecipe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Order_By>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

export type Subscription_RootRecipe_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRecipe_StepArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

export type Subscription_RootRecipe_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Step_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Recipe_Step_Order_By>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

export type Subscription_RootRecipe_Step_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRecipe_Step_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Recipe_Step_Stream_Cursor_Input>>
  where?: InputMaybe<Recipe_Step_Bool_Exp>
}

export type Subscription_RootRecipe_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Recipe_Stream_Cursor_Input>>
  where?: InputMaybe<Recipe_Bool_Exp>
}

export type Subscription_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootTenant_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootTenant_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Tenant_Stream_Cursor_Input>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Device_SettingArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

export type Subscription_RootUser_Device_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

export type Subscription_RootUser_Device_Setting_By_PkArgs = {
  device_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

export type Subscription_RootUser_Device_Setting_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Device_Setting_Stream_Cursor_Input>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

export type Subscription_RootUser_LocationArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_Location_By_PkArgs = {
  location_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

export type Subscription_RootUser_Location_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Location_Stream_Cursor_Input>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type Tenant = {
  __typename?: 'tenant'
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An array relationship */
  locations: Array<Location>
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate
  name: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
}

/** columns and relationships of "tenant" */
export type TenantLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "tenant" */
export type Tenant_Aggregate = {
  __typename?: 'tenant_aggregate'
  aggregate?: Maybe<Tenant_Aggregate_Fields>
  nodes: Array<Tenant>
}

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_Fields = {
  __typename?: 'tenant_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Tenant_Max_Fields>
  min?: Maybe<Tenant_Min_Fields>
}

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "tenant". All fields are combined with a logical 'AND'. */
export type Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Bool_Exp>>
  _not?: InputMaybe<Tenant_Bool_Exp>
  _or?: InputMaybe<Array<Tenant_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  locations?: InputMaybe<Location_Bool_Exp>
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "tenant" */
export enum Tenant_Constraint {
  /** unique or primary key constraint on columns "name" */
  TenantNameKey = 'tenant_name_key',
  /** unique or primary key constraint on columns "id" */
  TenantPkey = 'tenant_pkey'
}

/** input type for inserting data into table "tenant" */
export type Tenant_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Tenant_Max_Fields = {
  __typename?: 'tenant_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Tenant_Min_Fields = {
  __typename?: 'tenant_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "tenant" */
export type Tenant_Mutation_Response = {
  __typename?: 'tenant_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Tenant>
}

/** input type for inserting object relation for remote table "tenant" */
export type Tenant_Obj_Rel_Insert_Input = {
  data: Tenant_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** on_conflict condition type for table "tenant" */
export type Tenant_On_Conflict = {
  constraint: Tenant_Constraint
  update_columns?: Array<Tenant_Update_Column>
  where?: InputMaybe<Tenant_Bool_Exp>
}

/** Ordering options when selecting data from "tenant". */
export type Tenant_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: tenant */
export type Tenant_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "tenant" */
export enum Tenant_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tenant" */
export type Tenant_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "tenant" */
export type Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Tenant_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "tenant" */
export enum Tenant_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tenant_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Set_Input>
  /** filter the rows which have to be updated */
  where: Tenant_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  email: Scalars['String']
  firebase_id: Scalars['String']
  first_name?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  id_number?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  /** An array relationship */
  locations: Array<User_Location>
  /** An aggregate relationship */
  locations_aggregate: User_Location_Aggregate
  role: User_Role_Enum
  /** An object relationship */
  tenant?: Maybe<Tenant>
  tenant_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
  /** An array relationship */
  user_device_settings: Array<User_Device_Setting>
  /** An aggregate relationship */
  user_device_settings_aggregate: User_Device_Setting_Aggregate
  /** An object relationship */
  user_role: User_Role
}

/** columns and relationships of "user" */
export type UserLocationsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Device_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Device_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Device_Setting_Order_By>>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Max_Order_By>
  min?: InputMaybe<User_Min_Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>
  _not?: InputMaybe<User_Bool_Exp>
  _or?: InputMaybe<Array<User_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  firebase_id?: InputMaybe<String_Comparison_Exp>
  first_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  last_name?: InputMaybe<String_Comparison_Exp>
  locations?: InputMaybe<User_Location_Bool_Exp>
  locations_aggregate?: InputMaybe<User_Location_Aggregate_Bool_Exp>
  role?: InputMaybe<User_Role_Enum_Comparison_Exp>
  tenant?: InputMaybe<Tenant_Bool_Exp>
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_device_settings?: InputMaybe<User_Device_Setting_Bool_Exp>
  user_device_settings_aggregate?: InputMaybe<User_Device_Setting_Aggregate_Bool_Exp>
  user_role?: InputMaybe<User_Role_Bool_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "firebase_id" */
  UserFirebaseIdKey = 'user_firebase_id_key',
  /** unique or primary key constraint on columns "id_number" */
  UserIdNumberKey = 'user_id_number_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** columns and relationships of "user_device_setting" */
export type User_Device_Setting = {
  __typename?: 'user_device_setting'
  created_at: Scalars['timestamptz']
  dashboard_order?: Maybe<Scalars['Int']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']
  show_in_dashboard: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']
}

/** aggregated selection of "user_device_setting" */
export type User_Device_Setting_Aggregate = {
  __typename?: 'user_device_setting_aggregate'
  aggregate?: Maybe<User_Device_Setting_Aggregate_Fields>
  nodes: Array<User_Device_Setting>
}

export type User_Device_Setting_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Device_Setting_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Device_Setting_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Device_Setting_Aggregate_Bool_Exp_Count>
}

export type User_Device_Setting_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Device_Setting_Select_Column_User_Device_Setting_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Device_Setting_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Device_Setting_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Device_Setting_Select_Column_User_Device_Setting_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Device_Setting_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Device_Setting_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Device_Setting_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_device_setting" */
export type User_Device_Setting_Aggregate_Fields = {
  __typename?: 'user_device_setting_aggregate_fields'
  avg?: Maybe<User_Device_Setting_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Device_Setting_Max_Fields>
  min?: Maybe<User_Device_Setting_Min_Fields>
  stddev?: Maybe<User_Device_Setting_Stddev_Fields>
  stddev_pop?: Maybe<User_Device_Setting_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Device_Setting_Stddev_Samp_Fields>
  sum?: Maybe<User_Device_Setting_Sum_Fields>
  var_pop?: Maybe<User_Device_Setting_Var_Pop_Fields>
  var_samp?: Maybe<User_Device_Setting_Var_Samp_Fields>
  variance?: Maybe<User_Device_Setting_Variance_Fields>
}

/** aggregate fields of "user_device_setting" */
export type User_Device_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Device_Setting_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_device_setting" */
export type User_Device_Setting_Aggregate_Order_By = {
  avg?: InputMaybe<User_Device_Setting_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Device_Setting_Max_Order_By>
  min?: InputMaybe<User_Device_Setting_Min_Order_By>
  stddev?: InputMaybe<User_Device_Setting_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Device_Setting_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Device_Setting_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Device_Setting_Sum_Order_By>
  var_pop?: InputMaybe<User_Device_Setting_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Device_Setting_Var_Samp_Order_By>
  variance?: InputMaybe<User_Device_Setting_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_device_setting" */
export type User_Device_Setting_Arr_Rel_Insert_Input = {
  data: Array<User_Device_Setting_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Device_Setting_On_Conflict>
}

/** aggregate avg on columns */
export type User_Device_Setting_Avg_Fields = {
  __typename?: 'user_device_setting_avg_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_device_setting" */
export type User_Device_Setting_Avg_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_device_setting". All fields are combined with a logical 'AND'. */
export type User_Device_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<User_Device_Setting_Bool_Exp>>
  _not?: InputMaybe<User_Device_Setting_Bool_Exp>
  _or?: InputMaybe<Array<User_Device_Setting_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  dashboard_order?: InputMaybe<Int_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  show_in_dashboard?: InputMaybe<Boolean_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_device_setting" */
export enum User_Device_Setting_Constraint {
  /** unique or primary key constraint on columns "device_id", "user_id" */
  UserDeviceSettingsPkey = 'user_device_settings_pkey'
}

/** input type for incrementing numeric columns in table "user_device_setting" */
export type User_Device_Setting_Inc_Input = {
  dashboard_order?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "user_device_setting" */
export type User_Device_Setting_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  dashboard_order?: InputMaybe<Scalars['Int']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']>
  show_in_dashboard?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Device_Setting_Max_Fields = {
  __typename?: 'user_device_setting_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  dashboard_order?: Maybe<Scalars['Int']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "user_device_setting" */
export type User_Device_Setting_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  dashboard_order?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Device_Setting_Min_Fields = {
  __typename?: 'user_device_setting_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  dashboard_order?: Maybe<Scalars['Int']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  device_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "user_device_setting" */
export type User_Device_Setting_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  dashboard_order?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_device_setting" */
export type User_Device_Setting_Mutation_Response = {
  __typename?: 'user_device_setting_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Device_Setting>
}

/** on_conflict condition type for table "user_device_setting" */
export type User_Device_Setting_On_Conflict = {
  constraint: User_Device_Setting_Constraint
  update_columns?: Array<User_Device_Setting_Update_Column>
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}

/** Ordering options when selecting data from "user_device_setting". */
export type User_Device_Setting_Order_By = {
  created_at?: InputMaybe<Order_By>
  dashboard_order?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  show_in_dashboard?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_device_setting */
export type User_Device_Setting_Pk_Columns_Input = {
  device_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

/** select columns of table "user_device_setting" */
export enum User_Device_Setting_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DashboardOrder = 'dashboard_order',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ShowInDashboard = 'show_in_dashboard',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_device_setting_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_device_setting" */
export enum User_Device_Setting_Select_Column_User_Device_Setting_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInDashboard = 'show_in_dashboard'
}

/** select "user_device_setting_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_device_setting" */
export enum User_Device_Setting_Select_Column_User_Device_Setting_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInDashboard = 'show_in_dashboard'
}

/** input type for updating data in table "user_device_setting" */
export type User_Device_Setting_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  dashboard_order?: InputMaybe<Scalars['Int']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  show_in_dashboard?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate stddev on columns */
export type User_Device_Setting_Stddev_Fields = {
  __typename?: 'user_device_setting_stddev_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_device_setting" */
export type User_Device_Setting_Stddev_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Device_Setting_Stddev_Pop_Fields = {
  __typename?: 'user_device_setting_stddev_pop_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_device_setting" */
export type User_Device_Setting_Stddev_Pop_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Device_Setting_Stddev_Samp_Fields = {
  __typename?: 'user_device_setting_stddev_samp_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_device_setting" */
export type User_Device_Setting_Stddev_Samp_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_device_setting" */
export type User_Device_Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Device_Setting_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Device_Setting_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  dashboard_order?: InputMaybe<Scalars['Int']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  device_id?: InputMaybe<Scalars['uuid']>
  show_in_dashboard?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate sum on columns */
export type User_Device_Setting_Sum_Fields = {
  __typename?: 'user_device_setting_sum_fields'
  dashboard_order?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "user_device_setting" */
export type User_Device_Setting_Sum_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** update columns of table "user_device_setting" */
export enum User_Device_Setting_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DashboardOrder = 'dashboard_order',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  ShowInDashboard = 'show_in_dashboard',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Device_Setting_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Device_Setting_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Device_Setting_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Device_Setting_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Device_Setting_Var_Pop_Fields = {
  __typename?: 'user_device_setting_var_pop_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_device_setting" */
export type User_Device_Setting_Var_Pop_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Device_Setting_Var_Samp_Fields = {
  __typename?: 'user_device_setting_var_samp_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_device_setting" */
export type User_Device_Setting_Var_Samp_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Device_Setting_Variance_Fields = {
  __typename?: 'user_device_setting_variance_fields'
  dashboard_order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_device_setting" */
export type User_Device_Setting_Variance_Order_By = {
  dashboard_order?: InputMaybe<Order_By>
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firebase_id?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  id_number?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  locations?: InputMaybe<User_Location_Arr_Rel_Insert_Input>
  role?: InputMaybe<User_Role_Enum>
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_device_settings?: InputMaybe<User_Device_Setting_Arr_Rel_Insert_Input>
  user_role?: InputMaybe<User_Role_Obj_Rel_Insert_Input>
}

/** columns and relationships of "user_location" */
export type User_Location = {
  __typename?: 'user_location'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']
}

/** aggregated selection of "user_location" */
export type User_Location_Aggregate = {
  __typename?: 'user_location_aggregate'
  aggregate?: Maybe<User_Location_Aggregate_Fields>
  nodes: Array<User_Location>
}

export type User_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Location_Aggregate_Bool_Exp_Count>
}

export type User_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Location_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_location" */
export type User_Location_Aggregate_Fields = {
  __typename?: 'user_location_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Location_Max_Fields>
  min?: Maybe<User_Location_Min_Fields>
}

/** aggregate fields of "user_location" */
export type User_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_location" */
export type User_Location_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Location_Max_Order_By>
  min?: InputMaybe<User_Location_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_location" */
export type User_Location_Arr_Rel_Insert_Input = {
  data: Array<User_Location_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_location". All fields are combined with a logical 'AND'. */
export type User_Location_Bool_Exp = {
  _and?: InputMaybe<Array<User_Location_Bool_Exp>>
  _not?: InputMaybe<User_Location_Bool_Exp>
  _or?: InputMaybe<Array<User_Location_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_location" */
export enum User_Location_Constraint {
  /** unique or primary key constraint on columns "user_id", "location_id" */
  UserLocationPkey = 'user_location_pkey'
}

/** input type for inserting data into table "user_location" */
export type User_Location_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Location_Max_Fields = {
  __typename?: 'user_location_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  location_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "user_location" */
export type User_Location_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Location_Min_Fields = {
  __typename?: 'user_location_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  location_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "user_location" */
export type User_Location_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_location" */
export type User_Location_Mutation_Response = {
  __typename?: 'user_location_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Location>
}

/** on_conflict condition type for table "user_location" */
export type User_Location_On_Conflict = {
  constraint: User_Location_Constraint
  update_columns?: Array<User_Location_Update_Column>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** Ordering options when selecting data from "user_location". */
export type User_Location_Order_By = {
  created_at?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_location */
export type User_Location_Pk_Columns_Input = {
  location_id: Scalars['uuid']
  user_id: Scalars['uuid']
}

/** select columns of table "user_location" */
export enum User_Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_location" */
export type User_Location_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  location_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "user_location" */
export type User_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Location_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Location_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  location_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "user_location" */
export enum User_Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Location_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Location_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Location_Bool_Exp
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  firebase_id?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  id_number?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  firebase_id?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  id_number?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: InputMaybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  locations_aggregate?: InputMaybe<User_Location_Aggregate_Order_By>
  role?: InputMaybe<Order_By>
  tenant?: InputMaybe<Tenant_Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_device_settings_aggregate?: InputMaybe<User_Device_Setting_Aggregate_Order_By>
  user_role?: InputMaybe<User_Role_Order_By>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role'
  comment: Scalars['String']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
  value: Scalars['String']
}

/** columns and relationships of "user_role" */
export type User_RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "user_role" */
export type User_RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate'
  aggregate?: Maybe<User_Role_Aggregate_Fields>
  nodes: Array<User_Role>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Role_Max_Fields>
  min?: Maybe<User_Role_Min_Fields>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>
  _not?: InputMaybe<User_Role_Bool_Exp>
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserRolePkey = 'user_role_pkey'
}

export enum User_Role_Enum {
  /** ANONYMOUS */
  Anonymous = 'ANONYMOUS',
  /** LOCATION_ADMIN */
  LocationAdmin = 'LOCATION_ADMIN',
  /** TENANT_ADMIN */
  TenantAdmin = 'TENANT_ADMIN',
  /** VIEWER */
  Viewer = 'VIEWER',
  /** admin */
  Admin = 'admin'
}

/** Boolean expression to compare columns of type "user_role_enum". All fields are combined with logical 'AND'. */
export type User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Role_Enum>
  _in?: InputMaybe<Array<User_Role_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<User_Role_Enum>
  _nin?: InputMaybe<Array<User_Role_Enum>>
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>
}

/** input type for inserting object relation for remote table "user_role" */
export type User_Role_Obj_Rel_Insert_Input = {
  data: User_Role_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint
  update_columns?: Array<User_Role_Update_Column>
  where?: InputMaybe<User_Role_Bool_Exp>
}

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  comment?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firebase_id?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  id_number?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  role?: InputMaybe<User_Role_Enum>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  deleted_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firebase_id?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  id_number?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  role?: InputMaybe<User_Role_Enum>
  tenant_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type AgentFragmentFragment = {
  __typename?: 'agent'
  id: any
  name: string
  type: Agent_Type_Enum
  device_id: any
  pin_id: string
  created_at: any
  updated_at: any
  deleted_at?: any | null
  device: { __typename?: 'device'; id: any; name: string }
  agent_metric_types: Array<{
    __typename?: 'agent_metric_type'
    agent_id: any
    metric_type: Metric_Type_Enum
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }>
}

export type AgentStatusFragmentFragment = {
  __typename?: 'agent'
  id: any
  name: string
  type: Agent_Type_Enum
  created_at: any
  metrics: Array<{
    __typename?: 'metric'
    id: any
    type: Metric_Type_Enum
    value: number
    created_at: any
  }>
}

export type GetAgentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Agent_Select_Column> | Agent_Select_Column>
  order_by?: InputMaybe<Array<Agent_Order_By> | Agent_Order_By>
  where?: InputMaybe<Agent_Bool_Exp>
}>

export type GetAgentsQuery = {
  __typename?: 'query_root'
  agentsAggregate: {
    __typename?: 'agent_aggregate'
    aggregate?: {
      __typename?: 'agent_aggregate_fields'
      totalCount: number
    } | null
  }
  agents: Array<{
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    device_id: any
    pin_id: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    agent_metric_types: Array<{
      __typename?: 'agent_metric_type'
      agent_id: any
      metric_type: Metric_Type_Enum
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  }>
}

export type GetAgentQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetAgentQuery = {
  __typename?: 'query_root'
  agent?: {
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    device_id: any
    pin_id: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    agent_metric_types: Array<{
      __typename?: 'agent_metric_type'
      agent_id: any
      metric_type: Metric_Type_Enum
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  } | null
}

export type GetAgentStatusSubscriptionVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetAgentStatusSubscription = {
  __typename?: 'subscription_root'
  agent?: {
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    created_at: any
    metrics: Array<{
      __typename?: 'metric'
      id: any
      type: Metric_Type_Enum
      value: number
      created_at: any
    }>
  } | null
}

export type UpdateAgentMutationVariables = Exact<{
  id: Scalars['uuid']
  set: Agent_Set_Input
}>

export type UpdateAgentMutation = {
  __typename?: 'mutation_root'
  agent?: {
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    created_at: any
    metrics: Array<{
      __typename?: 'metric'
      id: any
      type: Metric_Type_Enum
      value: number
      created_at: any
    }>
  } | null
}

export type AlarmFragmentRemoteFragment = {
  __typename?: 'alarm'
  id: any
  cook_id: any
  message: string
  alarm: string
  progress_time: number
  sender_type: string
  sender_value: string
  sender_response?: any | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
  cook: {
    __typename?: 'cook'
    id: any
    batch_code?: string | null
    device: {
      __typename?: 'device'
      id: any
      alarm_email?: string | null
      alarm_phone_number?: string | null
    }
  }
}

export type GetAlarmsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Alarm_Select_Column> | Alarm_Select_Column>
  order_by?: InputMaybe<Array<Alarm_Order_By> | Alarm_Order_By>
  where?: InputMaybe<Alarm_Bool_Exp>
}>

export type GetAlarmsQuery = {
  __typename?: 'query_root'
  alarmsAggregate: {
    __typename?: 'alarm_aggregate'
    aggregate?: {
      __typename?: 'alarm_aggregate_fields'
      totalCount: number
    } | null
  }
  alarms: Array<{
    __typename?: 'alarm'
    id: any
    cook_id: any
    message: string
    alarm: string
    progress_time: number
    sender_type: string
    sender_value: string
    sender_response?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    cook: {
      __typename?: 'cook'
      id: any
      batch_code?: string | null
      device: {
        __typename?: 'device'
        id: any
        alarm_email?: string | null
        alarm_phone_number?: string | null
      }
    }
  }>
}

export type GetAlarmQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetAlarmQuery = {
  __typename?: 'query_root'
  alarm?: {
    __typename?: 'alarm'
    id: any
    cook_id: any
    message: string
    alarm: string
    progress_time: number
    sender_type: string
    sender_value: string
    sender_response?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    cook: {
      __typename?: 'cook'
      id: any
      batch_code?: string | null
      device: {
        __typename?: 'device'
        id: any
        alarm_email?: string | null
        alarm_phone_number?: string | null
      }
    }
  } | null
}

export type CookFragmentFragment = {
  __typename?: 'cook'
  id: any
  recipe_id: any
  started_at?: any | null
  ended_at?: any | null
  created_at: any
  device_id: any
  batch_code?: string | null
  metadata?: any | null
  device: {
    __typename?: 'device'
    id: any
    name: string
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
    }>
  }
  recipe: {
    __typename?: 'recipe'
    id: any
    name: string
    detail?: string | null
  }
  cook_recipe_steps: Array<{
    __typename?: 'cook_recipe_step'
    cook_id: any
    recipe_step_id: any
    started_at?: any | null
    ended_at?: any | null
    created_at: any
    recipe_step: {
      __typename?: 'recipe_step'
      id: any
      step: number
      min_temp?: number | null
      max_temp?: number | null
      time: number
    }
  }>
}

export type GetCooksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Cook_Select_Column> | Cook_Select_Column>
  order_by?: InputMaybe<Array<Cook_Order_By> | Cook_Order_By>
  where?: InputMaybe<Cook_Bool_Exp>
}>

export type GetCooksQuery = {
  __typename?: 'query_root'
  cooksAggregate: {
    __typename?: 'cook_aggregate'
    aggregate?: {
      __typename?: 'cook_aggregate_fields'
      totalCount: number
    } | null
  }
  cooks: Array<{
    __typename?: 'cook'
    id: any
    recipe_id: any
    started_at?: any | null
    ended_at?: any | null
    created_at: any
    device_id: any
    batch_code?: string | null
    metadata?: any | null
    device: {
      __typename?: 'device'
      id: any
      name: string
      agents: Array<{
        __typename?: 'agent'
        id: any
        name: string
        type: Agent_Type_Enum
      }>
    }
    recipe: {
      __typename?: 'recipe'
      id: any
      name: string
      detail?: string | null
    }
    cook_recipe_steps: Array<{
      __typename?: 'cook_recipe_step'
      cook_id: any
      recipe_step_id: any
      started_at?: any | null
      ended_at?: any | null
      created_at: any
      recipe_step: {
        __typename?: 'recipe_step'
        id: any
        step: number
        min_temp?: number | null
        max_temp?: number | null
        time: number
      }
    }>
  }>
}

export type GetCookQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCookQuery = {
  __typename?: 'query_root'
  cook?: {
    __typename?: 'cook'
    id: any
    recipe_id: any
    started_at?: any | null
    ended_at?: any | null
    created_at: any
    device_id: any
    batch_code?: string | null
    metadata?: any | null
    device: {
      __typename?: 'device'
      id: any
      name: string
      agents: Array<{
        __typename?: 'agent'
        id: any
        name: string
        type: Agent_Type_Enum
      }>
    }
    recipe: {
      __typename?: 'recipe'
      id: any
      name: string
      detail?: string | null
    }
    cook_recipe_steps: Array<{
      __typename?: 'cook_recipe_step'
      cook_id: any
      recipe_step_id: any
      started_at?: any | null
      ended_at?: any | null
      created_at: any
      recipe_step: {
        __typename?: 'recipe_step'
        id: any
        step: number
        min_temp?: number | null
        max_temp?: number | null
        time: number
      }
    }>
  } | null
}

export type AllDevicesFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
}

export type AllDevicesWithSettingsFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  user_device_settings: Array<{
    __typename?: 'user_device_setting'
    device_id: any
    user_id: any
    dashboard_order?: number | null
    show_in_dashboard: boolean
  }>
}

export type DeviceFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  serial_number?: string | null
  alarm_email?: string | null
  alarm_phone_number?: string | null
  test_connection_at?: any | null
  location_id?: any | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
  emit_metrics_at?: any | null
  internal_ip_address?: string | null
  info?: {
    __typename?: 'device_info'
    id: any
    internal_ip_address?: string | null
    external_ip_address?: string | null
    total_ram?: number | null
    total_ram_used?: number | null
    total_ram_available?: number | null
    total_disk?: number | null
    total_disk_used?: number | null
    total_disk_available?: number | null
    cpu_usage_percentage?: number | null
    updated_at: any
  } | null
  agents: Array<{
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    device_id: any
    pin_id: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    agent_metric_types: Array<{
      __typename?: 'agent_metric_type'
      agent_id: any
      metric_type: Metric_Type_Enum
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  }>
  location?: { __typename?: 'location'; id: any; name: string } | null
  cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
}

export type DeviceStatusFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  serial_number?: string | null
  alarm_email?: string | null
  alarm_phone_number?: string | null
  created_at: any
  test_connection_at?: any | null
  emit_metrics_at?: any | null
  info?: {
    __typename?: 'device_info'
    id: any
    internal_ip_address?: string | null
    external_ip_address?: string | null
    total_ram?: number | null
    total_ram_used?: number | null
    total_ram_available?: number | null
    total_disk?: number | null
    total_disk_used?: number | null
    total_disk_available?: number | null
    cpu_usage_percentage?: number | null
    updated_at: any
  } | null
  cooks: Array<{
    __typename?: 'cook'
    id: any
    started_at?: any | null
    ended_at?: any | null
    recipe_id: any
    created_at: any
    recipe: {
      __typename?: 'recipe'
      id: any
      name: string
      detail?: string | null
      recipe_steps_aggregate: {
        __typename?: 'recipe_step_aggregate'
        aggregate?: {
          __typename?: 'recipe_step_aggregate_fields'
          count: number
        } | null
      }
    }
    currentStep: Array<{
      __typename?: 'cook_recipe_step'
      started_at?: any | null
      ended_at?: any | null
      recipe_step: {
        __typename?: 'recipe_step'
        id: any
        min_temp?: number | null
        max_temp?: number | null
        step: number
        time: number
      }
    }>
  }>
  agents: Array<{
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    created_at: any
    metrics: Array<{
      __typename?: 'metric'
      id: any
      type: Metric_Type_Enum
      value: number
      created_at: any
    }>
  }>
}

export type DevicesStatusFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  created_at: any
  test_connection_at?: any | null
  emit_metrics_at?: any | null
  info?: {
    __typename?: 'device_info'
    id: any
    internal_ip_address?: string | null
    external_ip_address?: string | null
    total_ram?: number | null
    total_ram_used?: number | null
    total_ram_available?: number | null
    total_disk?: number | null
    total_disk_used?: number | null
    total_disk_available?: number | null
    cpu_usage_percentage?: number | null
    updated_at: any
  } | null
  cooks: Array<{
    __typename?: 'cook'
    id: any
    started_at?: any | null
    ended_at?: any | null
    recipe_id: any
    created_at: any
    recipe: {
      __typename?: 'recipe'
      id: any
      name: string
      detail?: string | null
      recipe_steps_aggregate: {
        __typename?: 'recipe_step_aggregate'
        aggregate?: {
          __typename?: 'recipe_step_aggregate_fields'
          count: number
        } | null
      }
    }
    currentStep: Array<{
      __typename?: 'cook_recipe_step'
      started_at?: any | null
      ended_at?: any | null
      recipe_step: {
        __typename?: 'recipe_step'
        id: any
        min_temp?: number | null
        max_temp?: number | null
        step: number
        time: number
      }
    }>
  }>
  agents: Array<{
    __typename?: 'agent'
    id: any
    name: string
    type: Agent_Type_Enum
    created_at: any
    metrics: Array<{
      __typename?: 'metric'
      id: any
      type: Metric_Type_Enum
      value: number
      created_at: any
    }>
  }>
}

export type GetDevicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Device_Select_Column> | Device_Select_Column>
  order_by?: InputMaybe<Array<Device_Order_By> | Device_Order_By>
  where?: InputMaybe<Device_Bool_Exp>
}>

export type GetDevicesQuery = {
  __typename?: 'query_root'
  devicesAggregate: {
    __typename?: 'device_aggregate'
    aggregate?: {
      __typename?: 'device_aggregate_fields'
      totalCount: number
    } | null
  }
  devices: Array<{
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    alarm_email?: string | null
    alarm_phone_number?: string | null
    test_connection_at?: any | null
    location_id?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    emit_metrics_at?: any | null
    internal_ip_address?: string | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      device_id: any
      pin_id: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      device: { __typename?: 'device'; id: any; name: string }
      agent_metric_types: Array<{
        __typename?: 'agent_metric_type'
        agent_id: any
        metric_type: Metric_Type_Enum
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }>
    }>
    location?: { __typename?: 'location'; id: any; name: string } | null
    cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
  }>
}

export type GetAllDevicesQueryVariables = Exact<{
  where?: InputMaybe<Device_Bool_Exp>
}>

export type GetAllDevicesQuery = {
  __typename?: 'query_root'
  devices: Array<{
    __typename?: 'device'
    id: any
    name: string
    cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
  }>
}

export type GetAllDevicesWithSettingsQueryVariables = Exact<{
  where?: InputMaybe<Device_Bool_Exp>
  user_id: Scalars['uuid']
}>

export type GetAllDevicesWithSettingsQuery = {
  __typename?: 'query_root'
  devices: Array<{
    __typename?: 'device'
    id: any
    name: string
    user_device_settings: Array<{
      __typename?: 'user_device_setting'
      device_id: any
      user_id: any
      dashboard_order?: number | null
      show_in_dashboard: boolean
    }>
  }>
}

export type GetDeviceQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetDeviceQuery = {
  __typename?: 'query_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    alarm_email?: string | null
    alarm_phone_number?: string | null
    test_connection_at?: any | null
    location_id?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    emit_metrics_at?: any | null
    internal_ip_address?: string | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      device_id: any
      pin_id: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      device: { __typename?: 'device'; id: any; name: string }
      agent_metric_types: Array<{
        __typename?: 'agent_metric_type'
        agent_id: any
        metric_type: Metric_Type_Enum
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }>
    }>
    location?: { __typename?: 'location'; id: any; name: string } | null
    cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
  } | null
}

export type InsertDeviceMutationVariables = Exact<{
  device: Device_Insert_Input
}>

export type InsertDeviceMutation = {
  __typename?: 'mutation_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    alarm_email?: string | null
    alarm_phone_number?: string | null
    test_connection_at?: any | null
    location_id?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    emit_metrics_at?: any | null
    internal_ip_address?: string | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      device_id: any
      pin_id: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      device: { __typename?: 'device'; id: any; name: string }
      agent_metric_types: Array<{
        __typename?: 'agent_metric_type'
        agent_id: any
        metric_type: Metric_Type_Enum
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }>
    }>
    location?: { __typename?: 'location'; id: any; name: string } | null
    cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
  } | null
}

export type GetDevicesStatusSubscriptionVariables = Exact<{
  userId: Scalars['uuid']
}>

export type GetDevicesStatusSubscription = {
  __typename?: 'subscription_root'
  devices: Array<{
    __typename?: 'device'
    id: any
    name: string
    created_at: any
    test_connection_at?: any | null
    emit_metrics_at?: any | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    cooks: Array<{
      __typename?: 'cook'
      id: any
      started_at?: any | null
      ended_at?: any | null
      recipe_id: any
      created_at: any
      recipe: {
        __typename?: 'recipe'
        id: any
        name: string
        detail?: string | null
        recipe_steps_aggregate: {
          __typename?: 'recipe_step_aggregate'
          aggregate?: {
            __typename?: 'recipe_step_aggregate_fields'
            count: number
          } | null
        }
      }
      currentStep: Array<{
        __typename?: 'cook_recipe_step'
        started_at?: any | null
        ended_at?: any | null
        recipe_step: {
          __typename?: 'recipe_step'
          id: any
          min_temp?: number | null
          max_temp?: number | null
          step: number
          time: number
        }
      }>
    }>
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      created_at: any
      metrics: Array<{
        __typename?: 'metric'
        id: any
        type: Metric_Type_Enum
        value: number
        created_at: any
      }>
    }>
  }>
}

export type GetDeviceStatusSubscriptionVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetDeviceStatusSubscription = {
  __typename?: 'subscription_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    alarm_email?: string | null
    alarm_phone_number?: string | null
    created_at: any
    test_connection_at?: any | null
    emit_metrics_at?: any | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    cooks: Array<{
      __typename?: 'cook'
      id: any
      started_at?: any | null
      ended_at?: any | null
      recipe_id: any
      created_at: any
      recipe: {
        __typename?: 'recipe'
        id: any
        name: string
        detail?: string | null
        recipe_steps_aggregate: {
          __typename?: 'recipe_step_aggregate'
          aggregate?: {
            __typename?: 'recipe_step_aggregate_fields'
            count: number
          } | null
        }
      }
      currentStep: Array<{
        __typename?: 'cook_recipe_step'
        started_at?: any | null
        ended_at?: any | null
        recipe_step: {
          __typename?: 'recipe_step'
          id: any
          min_temp?: number | null
          max_temp?: number | null
          step: number
          time: number
        }
      }>
    }>
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      created_at: any
      metrics: Array<{
        __typename?: 'metric'
        id: any
        type: Metric_Type_Enum
        value: number
        created_at: any
      }>
    }>
  } | null
}

export type UpsertDeviceMutationVariables = Exact<{
  device: Device_Insert_Input
  on_conflict: Device_On_Conflict
}>

export type UpsertDeviceMutation = {
  __typename?: 'mutation_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    alarm_email?: string | null
    alarm_phone_number?: string | null
    test_connection_at?: any | null
    location_id?: any | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    emit_metrics_at?: any | null
    internal_ip_address?: string | null
    info?: {
      __typename?: 'device_info'
      id: any
      internal_ip_address?: string | null
      external_ip_address?: string | null
      total_ram?: number | null
      total_ram_used?: number | null
      total_ram_available?: number | null
      total_disk?: number | null
      total_disk_used?: number | null
      total_disk_available?: number | null
      cpu_usage_percentage?: number | null
      updated_at: any
    } | null
    agents: Array<{
      __typename?: 'agent'
      id: any
      name: string
      type: Agent_Type_Enum
      device_id: any
      pin_id: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      device: { __typename?: 'device'; id: any; name: string }
      agent_metric_types: Array<{
        __typename?: 'agent_metric_type'
        agent_id: any
        metric_type: Metric_Type_Enum
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }>
    }>
    location?: { __typename?: 'location'; id: any; name: string } | null
    cooks: Array<{ __typename?: 'cook'; id: any; ended_at?: any | null }>
  } | null
}

export type LocationFragmentFragment = {
  __typename?: 'location'
  id: any
  name: string
  tenant_id: any
  created_at: any
  tenant: { __typename?: 'tenant'; id: any; name: string }
}

export type GetAllLocationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllLocationsQuery = {
  __typename?: 'query_root'
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  }>
}

export type GetLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<
    Array<Location_Select_Column> | Location_Select_Column
  >
  order_by?: InputMaybe<Array<Location_Order_By> | Location_Order_By>
  where?: InputMaybe<Location_Bool_Exp>
}>

export type GetLocationsQuery = {
  __typename?: 'query_root'
  locationsAggregate: {
    __typename?: 'location_aggregate'
    aggregate?: {
      __typename?: 'location_aggregate_fields'
      totalCount: number
    } | null
  }
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  }>
}

export type GetLocationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetLocationQuery = {
  __typename?: 'query_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  } | null
}

export type InsertLocationMutationVariables = Exact<{
  location: Location_Insert_Input
}>

export type InsertLocationMutation = {
  __typename?: 'mutation_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  } | null
}

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['uuid']
  set: Location_Set_Input
}>

export type UpdateLocationMutation = {
  __typename?: 'mutation_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  } | null
}

export type RecipeFragmentFragment = {
  __typename?: 'recipe'
  id: any
  name: string
  detail?: string | null
  device_id: any
  rules?: any | null
  created_at: any
  deleted_at?: any | null
  device: { __typename?: 'device'; id: any; name: string }
  recipe_steps: Array<{
    __typename?: 'recipe_step'
    id: any
    step: number
    rules?: any | null
    min_temp?: number | null
    max_temp?: number | null
    time: number
  }>
}

export type AllRecipesFragmentFragment = {
  __typename?: 'recipe'
  id: any
  name: string
}

export type GetRecipesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Recipe_Select_Column> | Recipe_Select_Column>
  order_by?: InputMaybe<Array<Recipe_Order_By> | Recipe_Order_By>
  where?: InputMaybe<Recipe_Bool_Exp>
}>

export type GetRecipesQuery = {
  __typename?: 'query_root'
  recipesAggregate: {
    __typename?: 'recipe_aggregate'
    aggregate?: {
      __typename?: 'recipe_aggregate_fields'
      totalCount: number
    } | null
  }
  recipes: Array<{
    __typename?: 'recipe'
    id: any
    name: string
    detail?: string | null
    device_id: any
    rules?: any | null
    created_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    recipe_steps: Array<{
      __typename?: 'recipe_step'
      id: any
      step: number
      rules?: any | null
      min_temp?: number | null
      max_temp?: number | null
      time: number
    }>
  }>
}

export type GetRecipeQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetRecipeQuery = {
  __typename?: 'query_root'
  recipe?: {
    __typename?: 'recipe'
    id: any
    name: string
    detail?: string | null
    device_id: any
    rules?: any | null
    created_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    recipe_steps: Array<{
      __typename?: 'recipe_step'
      id: any
      step: number
      rules?: any | null
      min_temp?: number | null
      max_temp?: number | null
      time: number
    }>
  } | null
}

export type GetAllRecipesQueryVariables = Exact<{
  where?: InputMaybe<Recipe_Bool_Exp>
}>

export type GetAllRecipesQuery = {
  __typename?: 'query_root'
  recipes: Array<{ __typename?: 'recipe'; id: any; name: string }>
}

export type InsertRecipeMutationVariables = Exact<{
  recipe: Recipe_Insert_Input
}>

export type InsertRecipeMutation = {
  __typename?: 'mutation_root'
  recipe?: {
    __typename?: 'recipe'
    id: any
    name: string
    detail?: string | null
    device_id: any
    rules?: any | null
    created_at: any
    deleted_at?: any | null
    device: { __typename?: 'device'; id: any; name: string }
    recipe_steps: Array<{
      __typename?: 'recipe_step'
      id: any
      step: number
      rules?: any | null
      min_temp?: number | null
      max_temp?: number | null
      time: number
    }>
  } | null
}

export type TenantFragmentFragment = {
  __typename?: 'tenant'
  id: any
  name: string
  created_at: any
}

export type GetTenantsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<Tenant_Select_Column> | Tenant_Select_Column>
  order_by?: InputMaybe<Array<Tenant_Order_By> | Tenant_Order_By>
  where?: InputMaybe<Tenant_Bool_Exp>
}>

export type GetTenantsQuery = {
  __typename?: 'query_root'
  tenantsAggregate: {
    __typename?: 'tenant_aggregate'
    aggregate?: {
      __typename?: 'tenant_aggregate_fields'
      totalCount: number
    } | null
  }
  tenants: Array<{
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  }>
}

export type GetTenantQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetTenantQuery = {
  __typename?: 'query_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  } | null
}

export type InsertTenantMutationVariables = Exact<{
  tenant: Tenant_Insert_Input
}>

export type InsertTenantMutation = {
  __typename?: 'mutation_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  } | null
}

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars['uuid']
  set: Tenant_Set_Input
}>

export type UpdateTenantMutation = {
  __typename?: 'mutation_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  } | null
}

export type GetAllTenantsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllTenantsQuery = {
  __typename?: 'query_root'
  tenants: Array<{
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  }>
}

export type UserFragmentFragment = {
  __typename?: 'user'
  id: any
  email: string
  role: User_Role_Enum
  firebase_id: string
  tenant_id?: any | null
  first_name?: string | null
  last_name?: string | null
  id_number?: string | null
  created_at: any
  locations: Array<{
    __typename?: 'user_location'
    user_id: any
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
  }>
}

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<Array<User_Select_Column> | User_Select_Column>
  order_by?: InputMaybe<Array<User_Order_By> | User_Order_By>
  where?: InputMaybe<User_Bool_Exp>
}>

export type GetUsersQuery = {
  __typename?: 'query_root'
  usersAggregate: {
    __typename?: 'user_aggregate'
    aggregate?: {
      __typename?: 'user_aggregate_fields'
      totalCount: number
    } | null
  }
  users: Array<{
    __typename?: 'user'
    id: any
    email: string
    role: User_Role_Enum
    firebase_id: string
    tenant_id?: any | null
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    created_at: any
    locations: Array<{
      __typename?: 'user_location'
      user_id: any
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
    }>
  }>
}

export type GetUserByFirebaseIdQueryVariables = Exact<{
  firebaseId?: InputMaybe<Scalars['String']>
}>

export type GetUserByFirebaseIdQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: any
    email: string
    role: User_Role_Enum
    firebase_id: string
    tenant_id?: any | null
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    created_at: any
    locations: Array<{
      __typename?: 'user_location'
      user_id: any
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
    }>
  }>
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetUserQuery = {
  __typename?: 'query_root'
  user?: {
    __typename?: 'user'
    id: any
    email: string
    role: User_Role_Enum
    firebase_id: string
    tenant_id?: any | null
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    created_at: any
    locations: Array<{
      __typename?: 'user_location'
      user_id: any
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
    }>
  } | null
}

export type GetUserAndLocationsQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetUserAndLocationsQuery = {
  __typename?: 'query_root'
  user?: {
    __typename?: 'user'
    id: any
    email: string
    role: User_Role_Enum
    firebase_id: string
    tenant_id?: any | null
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    created_at: any
    locations: Array<{
      __typename?: 'user_location'
      user_id: any
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
    }>
  } | null
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    tenant_id: any
    created_at: any
    tenant: { __typename?: 'tenant'; id: any; name: string }
  }>
  tenants: Array<{
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
  }>
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid']
  set: User_Set_Input
}>

export type UpdateUserMutation = {
  __typename?: 'mutation_root'
  user?: {
    __typename?: 'user'
    id: any
    email: string
    role: User_Role_Enum
    firebase_id: string
    tenant_id?: any | null
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    created_at: any
    locations: Array<{
      __typename?: 'user_location'
      user_id: any
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
    }>
  } | null
}

export type UserDeviceSettingFragmentFragment = {
  __typename?: 'user_device_setting'
  user_id: any
  device_id: any
  show_in_dashboard: boolean
  dashboard_order?: number | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
  user: { __typename?: 'user'; id: any; email: string }
  device: {
    __typename?: 'device'
    id: any
    name: string
    serial_number?: string | null
    location?: { __typename?: 'location'; id: any; name: string } | null
  }
}

export type GetUserDevicesSettingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  distinct_on?: InputMaybe<
    Array<User_Device_Setting_Select_Column> | User_Device_Setting_Select_Column
  >
  order_by?: InputMaybe<
    Array<User_Device_Setting_Order_By> | User_Device_Setting_Order_By
  >
  where?: InputMaybe<User_Device_Setting_Bool_Exp>
}>

export type GetUserDevicesSettingsQuery = {
  __typename?: 'query_root'
  userDevicesSettingsAggregate: {
    __typename?: 'user_device_setting_aggregate'
    aggregate?: {
      __typename?: 'user_device_setting_aggregate_fields'
      totalCount: number
    } | null
  }
  userDevicesSettings: Array<{
    __typename?: 'user_device_setting'
    user_id: any
    device_id: any
    show_in_dashboard: boolean
    dashboard_order?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: { __typename?: 'user'; id: any; email: string }
    device: {
      __typename?: 'device'
      id: any
      name: string
      serial_number?: string | null
      location?: { __typename?: 'location'; id: any; name: string } | null
    }
  }>
}

export type GetUserDeviceSettingsQueryVariables = Exact<{
  user_id: Scalars['uuid']
  device_id: Scalars['uuid']
}>

export type GetUserDeviceSettingsQuery = {
  __typename?: 'query_root'
  userDeviceSettings?: {
    __typename?: 'user_device_setting'
    user_id: any
    device_id: any
    show_in_dashboard: boolean
    dashboard_order?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: { __typename?: 'user'; id: any; email: string }
    device: {
      __typename?: 'device'
      id: any
      name: string
      serial_number?: string | null
      location?: { __typename?: 'location'; id: any; name: string } | null
    }
  } | null
}

export type UpsertUserDeviceSettingMutationVariables = Exact<{
  user_device_setting: User_Device_Setting_Insert_Input
  on_conflict: User_Device_Setting_On_Conflict
}>

export type UpsertUserDeviceSettingMutation = {
  __typename?: 'mutation_root'
  userDeviceSetting?: {
    __typename?: 'user_device_setting'
    user_id: any
    device_id: any
    show_in_dashboard: boolean
    dashboard_order?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: { __typename?: 'user'; id: any; email: string }
    device: {
      __typename?: 'device'
      id: any
      name: string
      serial_number?: string | null
      location?: { __typename?: 'location'; id: any; name: string } | null
    }
  } | null
}

export type UpsertUserDevicesSettingMutationVariables = Exact<{
  user_devices_setting:
    | Array<User_Device_Setting_Insert_Input>
    | User_Device_Setting_Insert_Input
  on_conflict: User_Device_Setting_On_Conflict
}>

export type UpsertUserDevicesSettingMutation = {
  __typename?: 'mutation_root'
  userDevicesSetting?: {
    __typename?: 'user_device_setting_mutation_response'
    returning: Array<{
      __typename?: 'user_device_setting'
      user_id: any
      device_id: any
      show_in_dashboard: boolean
      dashboard_order?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      user: { __typename?: 'user'; id: any; email: string }
      device: {
        __typename?: 'device'
        id: any
        name: string
        serial_number?: string | null
        location?: { __typename?: 'location'; id: any; name: string } | null
      }
    }>
  } | null
}

export const AlarmFragmentRemoteFragmentDoc = gql`
  fragment AlarmFragmentRemote on alarm {
    id
    cook_id
    message
    alarm
    progress_time
    sender_type
    sender_value
    sender_response
    created_at
    updated_at
    deleted_at
    cook {
      id
      batch_code
      device {
        id
        alarm_email
        alarm_phone_number
      }
    }
  }
`
export const CookFragmentFragmentDoc = gql`
  fragment CookFragment on cook {
    id
    recipe_id
    started_at
    ended_at
    created_at
    device_id
    batch_code
    metadata
    device {
      id
      name
      agents {
        id
        name
        type
      }
    }
    recipe {
      id
      name
      detail
    }
    cook_recipe_steps {
      cook_id
      recipe_step_id
      started_at
      ended_at
      created_at
      recipe_step {
        id
        step
        min_temp
        max_temp
        time
      }
    }
  }
`
export const AllDevicesFragmentFragmentDoc = gql`
  fragment AllDevicesFragment on device {
    id
    name
    cooks(where: { ended_at: { _is_null: true } }) {
      id
      ended_at
    }
  }
`
export const AllDevicesWithSettingsFragmentFragmentDoc = gql`
  fragment AllDevicesWithSettingsFragment on device {
    id
    name
    user_device_settings {
      device_id
      user_id
      dashboard_order
      show_in_dashboard
    }
  }
`
export const AgentFragmentFragmentDoc = gql`
  fragment AgentFragment on agent {
    id
    name
    type
    device_id
    pin_id
    created_at
    updated_at
    deleted_at
    device {
      id
      name
    }
    agent_metric_types(where: { deleted_at: { _is_null: true } }) {
      agent_id
      metric_type
      created_at
      updated_at
      deleted_at
    }
  }
`
export const DeviceFragmentFragmentDoc = gql`
  fragment DeviceFragment on device {
    id
    name
    serial_number
    alarm_email
    alarm_phone_number
    test_connection_at
    location_id
    created_at
    updated_at
    deleted_at
    emit_metrics_at
    internal_ip_address
    info {
      id
      internal_ip_address
      external_ip_address
      total_ram
      total_ram_used
      total_ram_available
      total_disk
      total_disk_used
      total_disk_available
      cpu_usage_percentage
      updated_at
    }
    agents(where: { deleted_at: { _is_null: true } }) {
      ...AgentFragment
    }
    location {
      id
      name
    }
    cooks(where: { ended_at: { _is_null: true } }) {
      id
      ended_at
    }
  }
  ${AgentFragmentFragmentDoc}
`
export const AgentStatusFragmentFragmentDoc = gql`
  fragment AgentStatusFragment on agent {
    id
    name
    type
    created_at
    metrics(limit: 50, order_by: { created_at: desc }) {
      id
      type
      value
      created_at
    }
  }
`
export const DeviceStatusFragmentFragmentDoc = gql`
  fragment DeviceStatusFragment on device {
    id
    name
    serial_number
    alarm_email
    alarm_phone_number
    created_at
    test_connection_at
    emit_metrics_at
    info {
      id
      internal_ip_address
      external_ip_address
      total_ram
      total_ram_used
      total_ram_available
      total_disk
      total_disk_used
      total_disk_available
      cpu_usage_percentage
      updated_at
    }
    cooks(
      limit: 1
      where: { ended_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      id
      started_at
      ended_at
      recipe_id
      created_at
      recipe {
        id
        name
        detail
        recipe_steps_aggregate {
          aggregate {
            count
          }
        }
      }
      currentStep: cook_recipe_steps(where: { ended_at: { _is_null: true } }) {
        started_at
        ended_at
        recipe_step {
          id
          min_temp
          max_temp
          step
          time
        }
      }
    }
    agents(where: { deleted_at: { _is_null: true } }) {
      ...AgentStatusFragment
    }
  }
  ${AgentStatusFragmentFragmentDoc}
`
export const DevicesStatusFragmentFragmentDoc = gql`
  fragment DevicesStatusFragment on device {
    id
    name
    created_at
    test_connection_at
    created_at
    emit_metrics_at
    info {
      id
      internal_ip_address
      external_ip_address
      total_ram
      total_ram_used
      total_ram_available
      total_disk
      total_disk_used
      total_disk_available
      cpu_usage_percentage
      updated_at
    }
    cooks(
      limit: 1
      where: { ended_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      id
      started_at
      ended_at
      recipe_id
      created_at
      recipe {
        id
        name
        detail
        recipe_steps_aggregate {
          aggregate {
            count
          }
        }
      }
      currentStep: cook_recipe_steps(where: { ended_at: { _is_null: true } }) {
        started_at
        ended_at
        recipe_step {
          id
          min_temp
          max_temp
          step
          time
        }
      }
    }
    agents(
      where: { deleted_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      id
      name
      type
      created_at
      metrics(
        order_by: [{ type: desc }, { created_at: desc }]
        distinct_on: type
      ) {
        id
        type
        value
        created_at
      }
    }
  }
`
export const LocationFragmentFragmentDoc = gql`
  fragment LocationFragment on location {
    id
    name
    tenant_id
    tenant {
      id
      name
    }
    created_at
  }
`
export const RecipeFragmentFragmentDoc = gql`
  fragment RecipeFragment on recipe {
    id
    name
    detail
    device_id
    rules
    created_at
    deleted_at
    device {
      id
      name
    }
    recipe_steps(order_by: { step: asc }) {
      id
      step
      rules
      min_temp
      max_temp
      time
    }
  }
`
export const AllRecipesFragmentFragmentDoc = gql`
  fragment AllRecipesFragment on recipe {
    id
    name
  }
`
export const TenantFragmentFragmentDoc = gql`
  fragment TenantFragment on tenant {
    id
    name
    created_at
  }
`
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on user {
    id
    email
    role
    firebase_id
    tenant_id
    first_name
    last_name
    id_number
    created_at
    locations {
      user_id
      location_id
      location {
        id
        name
      }
    }
  }
`
export const UserDeviceSettingFragmentFragmentDoc = gql`
  fragment UserDeviceSettingFragment on user_device_setting {
    user_id
    device_id
    show_in_dashboard
    dashboard_order
    created_at
    updated_at
    deleted_at
    user {
      id
      email
    }
    device {
      id
      name
      serial_number
      location {
        id
        name
      }
    }
  }
`
export const GetAgentsDocument = gql`
  query getAgents(
    $limit: Int
    $offset: Int
    $distinct_on: [agent_select_column!]
    $order_by: [agent_order_by!]
    $where: agent_bool_exp
  ) {
    agentsAggregate: agent_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    agents: agent(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...AgentFragment
    }
  }
  ${AgentFragmentFragmentDoc}
`

/**
 * __useGetAgentsQuery__
 *
 * To run a query within a React component, call `useGetAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAgentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAgentsQuery, GetAgentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAgentsQuery, GetAgentsQueryVariables>(
    GetAgentsDocument,
    options
  )
}
export function useGetAgentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentsQuery,
    GetAgentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAgentsQuery, GetAgentsQueryVariables>(
    GetAgentsDocument,
    options
  )
}
export type GetAgentsQueryHookResult = ReturnType<typeof useGetAgentsQuery>
export type GetAgentsLazyQueryHookResult = ReturnType<
  typeof useGetAgentsLazyQuery
>
export type GetAgentsQueryResult = Apollo.QueryResult<
  GetAgentsQuery,
  GetAgentsQueryVariables
>
export const GetAgentDocument = gql`
  query getAgent($id: uuid!) {
    agent: agent_by_pk(id: $id) {
      ...AgentFragment
    }
  }
  ${AgentFragmentFragmentDoc}
`

/**
 * __useGetAgentQuery__
 *
 * To run a query within a React component, call `useGetAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAgentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAgentQuery, GetAgentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAgentQuery, GetAgentQueryVariables>(
    GetAgentDocument,
    options
  )
}
export function useGetAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentQuery,
    GetAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAgentQuery, GetAgentQueryVariables>(
    GetAgentDocument,
    options
  )
}
export type GetAgentQueryHookResult = ReturnType<typeof useGetAgentQuery>
export type GetAgentLazyQueryHookResult = ReturnType<
  typeof useGetAgentLazyQuery
>
export type GetAgentQueryResult = Apollo.QueryResult<
  GetAgentQuery,
  GetAgentQueryVariables
>
export const GetAgentStatusDocument = gql`
  subscription getAgentStatus($id: uuid!) {
    agent: agent_by_pk(id: $id) {
      ...AgentStatusFragment
    }
  }
  ${AgentStatusFragmentFragmentDoc}
`

/**
 * __useGetAgentStatusSubscription__
 *
 * To run a query within a React component, call `useGetAgentStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentStatusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAgentStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetAgentStatusSubscription,
    GetAgentStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetAgentStatusSubscription,
    GetAgentStatusSubscriptionVariables
  >(GetAgentStatusDocument, options)
}
export type GetAgentStatusSubscriptionHookResult = ReturnType<
  typeof useGetAgentStatusSubscription
>
export type GetAgentStatusSubscriptionResult =
  Apollo.SubscriptionResult<GetAgentStatusSubscription>
export const UpdateAgentDocument = gql`
  mutation updateAgent($id: uuid!, $set: agent_set_input!) {
    agent: update_agent_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...AgentStatusFragment
    }
  }
  ${AgentStatusFragmentFragmentDoc}
`
export type UpdateAgentMutationFn = Apollo.MutationFunction<
  UpdateAgentMutation,
  UpdateAgentMutationVariables
>

/**
 * __useUpdateAgentMutation__
 *
 * To run a mutation, you first call `useUpdateAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentMutation, { data, loading, error }] = useUpdateAgentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgentMutation,
    UpdateAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(
    UpdateAgentDocument,
    options
  )
}
export type UpdateAgentMutationHookResult = ReturnType<
  typeof useUpdateAgentMutation
>
export type UpdateAgentMutationResult =
  Apollo.MutationResult<UpdateAgentMutation>
export type UpdateAgentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgentMutation,
  UpdateAgentMutationVariables
>
export const GetAlarmsDocument = gql`
  query getAlarms(
    $limit: Int
    $offset: Int
    $distinct_on: [alarm_select_column!]
    $order_by: [alarm_order_by!]
    $where: alarm_bool_exp
  ) {
    alarmsAggregate: alarm_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    alarms: alarm(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...AlarmFragmentRemote
    }
  }
  ${AlarmFragmentRemoteFragmentDoc}
`

/**
 * __useGetAlarmsQuery__
 *
 * To run a query within a React component, call `useGetAlarmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAlarmsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAlarmsQuery, GetAlarmsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAlarmsQuery, GetAlarmsQueryVariables>(
    GetAlarmsDocument,
    options
  )
}
export function useGetAlarmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlarmsQuery,
    GetAlarmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAlarmsQuery, GetAlarmsQueryVariables>(
    GetAlarmsDocument,
    options
  )
}
export type GetAlarmsQueryHookResult = ReturnType<typeof useGetAlarmsQuery>
export type GetAlarmsLazyQueryHookResult = ReturnType<
  typeof useGetAlarmsLazyQuery
>
export type GetAlarmsQueryResult = Apollo.QueryResult<
  GetAlarmsQuery,
  GetAlarmsQueryVariables
>
export const GetAlarmDocument = gql`
  query getAlarm($id: uuid!) {
    alarm: alarm_by_pk(id: $id) {
      ...AlarmFragmentRemote
    }
  }
  ${AlarmFragmentRemoteFragmentDoc}
`

/**
 * __useGetAlarmQuery__
 *
 * To run a query within a React component, call `useGetAlarmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAlarmQuery(
  baseOptions: Apollo.QueryHookOptions<GetAlarmQuery, GetAlarmQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAlarmQuery, GetAlarmQueryVariables>(
    GetAlarmDocument,
    options
  )
}
export function useGetAlarmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlarmQuery,
    GetAlarmQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAlarmQuery, GetAlarmQueryVariables>(
    GetAlarmDocument,
    options
  )
}
export type GetAlarmQueryHookResult = ReturnType<typeof useGetAlarmQuery>
export type GetAlarmLazyQueryHookResult = ReturnType<
  typeof useGetAlarmLazyQuery
>
export type GetAlarmQueryResult = Apollo.QueryResult<
  GetAlarmQuery,
  GetAlarmQueryVariables
>
export const GetCooksDocument = gql`
  query getCooks(
    $limit: Int
    $offset: Int
    $distinct_on: [cook_select_column!]
    $order_by: [cook_order_by!]
    $where: cook_bool_exp
  ) {
    cooksAggregate: cook_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    cooks: cook(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...CookFragment
    }
  }
  ${CookFragmentFragmentDoc}
`

/**
 * __useGetCooksQuery__
 *
 * To run a query within a React component, call `useGetCooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCooksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCooksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCooksQuery, GetCooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCooksQuery, GetCooksQueryVariables>(
    GetCooksDocument,
    options
  )
}
export function useGetCooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCooksQuery,
    GetCooksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCooksQuery, GetCooksQueryVariables>(
    GetCooksDocument,
    options
  )
}
export type GetCooksQueryHookResult = ReturnType<typeof useGetCooksQuery>
export type GetCooksLazyQueryHookResult = ReturnType<
  typeof useGetCooksLazyQuery
>
export type GetCooksQueryResult = Apollo.QueryResult<
  GetCooksQuery,
  GetCooksQueryVariables
>
export const GetCookDocument = gql`
  query getCook($id: uuid!) {
    cook: cook_by_pk(id: $id) {
      ...CookFragment
    }
  }
  ${CookFragmentFragmentDoc}
`

/**
 * __useGetCookQuery__
 *
 * To run a query within a React component, call `useGetCookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCookQuery(
  baseOptions: Apollo.QueryHookOptions<GetCookQuery, GetCookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCookQuery, GetCookQueryVariables>(
    GetCookDocument,
    options
  )
}
export function useGetCookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCookQuery, GetCookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCookQuery, GetCookQueryVariables>(
    GetCookDocument,
    options
  )
}
export type GetCookQueryHookResult = ReturnType<typeof useGetCookQuery>
export type GetCookLazyQueryHookResult = ReturnType<typeof useGetCookLazyQuery>
export type GetCookQueryResult = Apollo.QueryResult<
  GetCookQuery,
  GetCookQueryVariables
>
export const GetDevicesDocument = gql`
  query getDevices(
    $limit: Int
    $offset: Int
    $distinct_on: [device_select_column!]
    $order_by: [device_order_by!]
    $where: device_bool_exp
  ) {
    devicesAggregate: device_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    devices: device(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(
    GetDevicesDocument,
    options
  )
}
export function useGetDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(
    GetDevicesDocument,
    options
  )
}
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>
export type GetDevicesLazyQueryHookResult = ReturnType<
  typeof useGetDevicesLazyQuery
>
export type GetDevicesQueryResult = Apollo.QueryResult<
  GetDevicesQuery,
  GetDevicesQueryVariables
>
export const GetAllDevicesDocument = gql`
  query getAllDevices($where: device_bool_exp) {
    devices: device(where: $where) {
      ...AllDevicesFragment
    }
  }
  ${AllDevicesFragmentFragmentDoc}
`

/**
 * __useGetAllDevicesQuery__
 *
 * To run a query within a React component, call `useGetAllDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDevicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllDevicesQuery,
    GetAllDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllDevicesQuery, GetAllDevicesQueryVariables>(
    GetAllDevicesDocument,
    options
  )
}
export function useGetAllDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDevicesQuery,
    GetAllDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllDevicesQuery, GetAllDevicesQueryVariables>(
    GetAllDevicesDocument,
    options
  )
}
export type GetAllDevicesQueryHookResult = ReturnType<
  typeof useGetAllDevicesQuery
>
export type GetAllDevicesLazyQueryHookResult = ReturnType<
  typeof useGetAllDevicesLazyQuery
>
export type GetAllDevicesQueryResult = Apollo.QueryResult<
  GetAllDevicesQuery,
  GetAllDevicesQueryVariables
>
export const GetAllDevicesWithSettingsDocument = gql`
  query getAllDevicesWithSettings($where: device_bool_exp, $user_id: uuid!) {
    devices: device(
      where: $where
      order_by: {
        user_device_settings_aggregate: { avg: { dashboard_order: asc } }
      }
    ) {
      id
      name
      user_device_settings(where: { user_id: { _eq: $user_id } }) {
        device_id
        user_id
        dashboard_order
        show_in_dashboard
      }
    }
  }
`

/**
 * __useGetAllDevicesWithSettingsQuery__
 *
 * To run a query within a React component, call `useGetAllDevicesWithSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDevicesWithSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDevicesWithSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetAllDevicesWithSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllDevicesWithSettingsQuery,
    GetAllDevicesWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllDevicesWithSettingsQuery,
    GetAllDevicesWithSettingsQueryVariables
  >(GetAllDevicesWithSettingsDocument, options)
}
export function useGetAllDevicesWithSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDevicesWithSettingsQuery,
    GetAllDevicesWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllDevicesWithSettingsQuery,
    GetAllDevicesWithSettingsQueryVariables
  >(GetAllDevicesWithSettingsDocument, options)
}
export type GetAllDevicesWithSettingsQueryHookResult = ReturnType<
  typeof useGetAllDevicesWithSettingsQuery
>
export type GetAllDevicesWithSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAllDevicesWithSettingsLazyQuery
>
export type GetAllDevicesWithSettingsQueryResult = Apollo.QueryResult<
  GetAllDevicesWithSettingsQuery,
  GetAllDevicesWithSettingsQueryVariables
>
export const GetDeviceDocument = gql`
  query getDevice($id: uuid!) {
    device: device_by_pk(id: $id) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`

/**
 * __useGetDeviceQuery__
 *
 * To run a query within a React component, call `useGetDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<GetDeviceQuery, GetDeviceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeviceQuery, GetDeviceQueryVariables>(
    GetDeviceDocument,
    options
  )
}
export function useGetDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeviceQuery,
    GetDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeviceQuery, GetDeviceQueryVariables>(
    GetDeviceDocument,
    options
  )
}
export type GetDeviceQueryHookResult = ReturnType<typeof useGetDeviceQuery>
export type GetDeviceLazyQueryHookResult = ReturnType<
  typeof useGetDeviceLazyQuery
>
export type GetDeviceQueryResult = Apollo.QueryResult<
  GetDeviceQuery,
  GetDeviceQueryVariables
>
export const InsertDeviceDocument = gql`
  mutation insertDevice($device: device_insert_input!) {
    device: insert_device_one(object: $device) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`
export type InsertDeviceMutationFn = Apollo.MutationFunction<
  InsertDeviceMutation,
  InsertDeviceMutationVariables
>

/**
 * __useInsertDeviceMutation__
 *
 * To run a mutation, you first call `useInsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDeviceMutation, { data, loading, error }] = useInsertDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useInsertDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDeviceMutation,
    InsertDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertDeviceMutation,
    InsertDeviceMutationVariables
  >(InsertDeviceDocument, options)
}
export type InsertDeviceMutationHookResult = ReturnType<
  typeof useInsertDeviceMutation
>
export type InsertDeviceMutationResult =
  Apollo.MutationResult<InsertDeviceMutation>
export type InsertDeviceMutationOptions = Apollo.BaseMutationOptions<
  InsertDeviceMutation,
  InsertDeviceMutationVariables
>
export const GetDevicesStatusDocument = gql`
  subscription getDevicesStatus($userId: uuid!) {
    devices: device(
      where: {
        user_device_settings: {
          show_in_dashboard: { _eq: true }
          user_id: { _eq: $userId }
        }
      }
      order_by: {
        user_device_settings_aggregate: { avg: { dashboard_order: asc } }
      }
    ) {
      ...DevicesStatusFragment
    }
  }
  ${DevicesStatusFragmentFragmentDoc}
`

/**
 * __useGetDevicesStatusSubscription__
 *
 * To run a query within a React component, call `useGetDevicesStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesStatusSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDevicesStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetDevicesStatusSubscription,
    GetDevicesStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetDevicesStatusSubscription,
    GetDevicesStatusSubscriptionVariables
  >(GetDevicesStatusDocument, options)
}
export type GetDevicesStatusSubscriptionHookResult = ReturnType<
  typeof useGetDevicesStatusSubscription
>
export type GetDevicesStatusSubscriptionResult =
  Apollo.SubscriptionResult<GetDevicesStatusSubscription>
export const GetDeviceStatusDocument = gql`
  subscription getDeviceStatus($id: uuid!) {
    device: device_by_pk(id: $id) {
      ...DeviceStatusFragment
    }
  }
  ${DeviceStatusFragmentFragmentDoc}
`

/**
 * __useGetDeviceStatusSubscription__
 *
 * To run a query within a React component, call `useGetDeviceStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceStatusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetDeviceStatusSubscription,
    GetDeviceStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetDeviceStatusSubscription,
    GetDeviceStatusSubscriptionVariables
  >(GetDeviceStatusDocument, options)
}
export type GetDeviceStatusSubscriptionHookResult = ReturnType<
  typeof useGetDeviceStatusSubscription
>
export type GetDeviceStatusSubscriptionResult =
  Apollo.SubscriptionResult<GetDeviceStatusSubscription>
export const UpsertDeviceDocument = gql`
  mutation upsertDevice(
    $device: device_insert_input!
    $on_conflict: device_on_conflict!
  ) {
    device: insert_device_one(object: $device, on_conflict: $on_conflict) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`
export type UpsertDeviceMutationFn = Apollo.MutationFunction<
  UpsertDeviceMutation,
  UpsertDeviceMutationVariables
>

/**
 * __useUpsertDeviceMutation__
 *
 * To run a mutation, you first call `useUpsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDeviceMutation, { data, loading, error }] = useUpsertDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDeviceMutation,
    UpsertDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDeviceMutation,
    UpsertDeviceMutationVariables
  >(UpsertDeviceDocument, options)
}
export type UpsertDeviceMutationHookResult = ReturnType<
  typeof useUpsertDeviceMutation
>
export type UpsertDeviceMutationResult =
  Apollo.MutationResult<UpsertDeviceMutation>
export type UpsertDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpsertDeviceMutation,
  UpsertDeviceMutationVariables
>
export const GetAllLocationsDocument = gql`
  query getAllLocations {
    locations: location {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(
    GetAllLocationsDocument,
    options
  )
}
export function useGetAllLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllLocationsQuery,
    GetAllLocationsQueryVariables
  >(GetAllLocationsDocument, options)
}
export type GetAllLocationsQueryHookResult = ReturnType<
  typeof useGetAllLocationsQuery
>
export type GetAllLocationsLazyQueryHookResult = ReturnType<
  typeof useGetAllLocationsLazyQuery
>
export type GetAllLocationsQueryResult = Apollo.QueryResult<
  GetAllLocationsQuery,
  GetAllLocationsQueryVariables
>
export const GetLocationsDocument = gql`
  query getLocations(
    $limit: Int
    $offset: Int
    $distinct_on: [location_select_column!]
    $order_by: [location_order_by!]
    $where: location_bool_exp
  ) {
    locationsAggregate: location_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    locations: location(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  )
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  )
}
export type GetLocationsQueryHookResult = ReturnType<
  typeof useGetLocationsQuery
>
export type GetLocationsLazyQueryHookResult = ReturnType<
  typeof useGetLocationsLazyQuery
>
export type GetLocationsQueryResult = Apollo.QueryResult<
  GetLocationsQuery,
  GetLocationsQueryVariables
>
export const GetLocationDocument = gql`
  query getLocation($id: uuid!) {
    location: location_by_pk(id: $id) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  )
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  )
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>
export type GetLocationLazyQueryHookResult = ReturnType<
  typeof useGetLocationLazyQuery
>
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>
export const InsertLocationDocument = gql`
  mutation insertLocation($location: location_insert_input!) {
    location: insert_location_one(object: $location) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`
export type InsertLocationMutationFn = Apollo.MutationFunction<
  InsertLocationMutation,
  InsertLocationMutationVariables
>

/**
 * __useInsertLocationMutation__
 *
 * To run a mutation, you first call `useInsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationMutation, { data, loading, error }] = useInsertLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useInsertLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertLocationMutation,
    InsertLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertLocationMutation,
    InsertLocationMutationVariables
  >(InsertLocationDocument, options)
}
export type InsertLocationMutationHookResult = ReturnType<
  typeof useInsertLocationMutation
>
export type InsertLocationMutationResult =
  Apollo.MutationResult<InsertLocationMutation>
export type InsertLocationMutationOptions = Apollo.BaseMutationOptions<
  InsertLocationMutation,
  InsertLocationMutationVariables
>
export const UpdateLocationDocument = gql`
  mutation updateLocation($id: uuid!, $set: location_set_input!) {
    location: update_location_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options)
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>
export const GetRecipesDocument = gql`
  query getRecipes(
    $limit: Int
    $offset: Int
    $distinct_on: [recipe_select_column!]
    $order_by: [recipe_order_by!]
    $where: recipe_bool_exp
  ) {
    recipesAggregate: recipe_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    recipes: recipe(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...RecipeFragment
    }
  }
  ${RecipeFragmentFragmentDoc}
`

/**
 * __useGetRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecipesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecipesQuery,
    GetRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRecipesQuery, GetRecipesQueryVariables>(
    GetRecipesDocument,
    options
  )
}
export function useGetRecipesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipesQuery,
    GetRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRecipesQuery, GetRecipesQueryVariables>(
    GetRecipesDocument,
    options
  )
}
export type GetRecipesQueryHookResult = ReturnType<typeof useGetRecipesQuery>
export type GetRecipesLazyQueryHookResult = ReturnType<
  typeof useGetRecipesLazyQuery
>
export type GetRecipesQueryResult = Apollo.QueryResult<
  GetRecipesQuery,
  GetRecipesQueryVariables
>
export const GetRecipeDocument = gql`
  query getRecipe($id: uuid!) {
    recipe: recipe_by_pk(id: $id) {
      ...RecipeFragment
    }
  }
  ${RecipeFragmentFragmentDoc}
`

/**
 * __useGetRecipeQuery__
 *
 * To run a query within a React component, call `useGetRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRecipeQuery, GetRecipeQueryVariables>(
    GetRecipeDocument,
    options
  )
}
export function useGetRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipeQuery,
    GetRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRecipeQuery, GetRecipeQueryVariables>(
    GetRecipeDocument,
    options
  )
}
export type GetRecipeQueryHookResult = ReturnType<typeof useGetRecipeQuery>
export type GetRecipeLazyQueryHookResult = ReturnType<
  typeof useGetRecipeLazyQuery
>
export type GetRecipeQueryResult = Apollo.QueryResult<
  GetRecipeQuery,
  GetRecipeQueryVariables
>
export const GetAllRecipesDocument = gql`
  query getAllRecipes($where: recipe_bool_exp) {
    recipes: recipe(where: $where) {
      ...AllRecipesFragment
    }
  }
  ${AllRecipesFragmentFragmentDoc}
`

/**
 * __useGetAllRecipesQuery__
 *
 * To run a query within a React component, call `useGetAllRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecipesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllRecipesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRecipesQuery,
    GetAllRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllRecipesQuery, GetAllRecipesQueryVariables>(
    GetAllRecipesDocument,
    options
  )
}
export function useGetAllRecipesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRecipesQuery,
    GetAllRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllRecipesQuery, GetAllRecipesQueryVariables>(
    GetAllRecipesDocument,
    options
  )
}
export type GetAllRecipesQueryHookResult = ReturnType<
  typeof useGetAllRecipesQuery
>
export type GetAllRecipesLazyQueryHookResult = ReturnType<
  typeof useGetAllRecipesLazyQuery
>
export type GetAllRecipesQueryResult = Apollo.QueryResult<
  GetAllRecipesQuery,
  GetAllRecipesQueryVariables
>
export const InsertRecipeDocument = gql`
  mutation insertRecipe($recipe: recipe_insert_input!) {
    recipe: insert_recipe_one(object: $recipe) {
      ...RecipeFragment
    }
  }
  ${RecipeFragmentFragmentDoc}
`
export type InsertRecipeMutationFn = Apollo.MutationFunction<
  InsertRecipeMutation,
  InsertRecipeMutationVariables
>

/**
 * __useInsertRecipeMutation__
 *
 * To run a mutation, you first call `useInsertRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRecipeMutation, { data, loading, error }] = useInsertRecipeMutation({
 *   variables: {
 *      recipe: // value for 'recipe'
 *   },
 * });
 */
export function useInsertRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertRecipeMutation,
    InsertRecipeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertRecipeMutation,
    InsertRecipeMutationVariables
  >(InsertRecipeDocument, options)
}
export type InsertRecipeMutationHookResult = ReturnType<
  typeof useInsertRecipeMutation
>
export type InsertRecipeMutationResult =
  Apollo.MutationResult<InsertRecipeMutation>
export type InsertRecipeMutationOptions = Apollo.BaseMutationOptions<
  InsertRecipeMutation,
  InsertRecipeMutationVariables
>
export const GetTenantsDocument = gql`
  query getTenants(
    $limit: Int
    $offset: Int
    $distinct_on: [tenant_select_column!]
    $order_by: [tenant_order_by!]
    $where: tenant_bool_exp
  ) {
    tenantsAggregate: tenant_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    tenants: tenant(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options
  )
}
export function useGetTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options
  )
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>
export type GetTenantsLazyQueryHookResult = ReturnType<
  typeof useGetTenantsLazyQuery
>
export type GetTenantsQueryResult = Apollo.QueryResult<
  GetTenantsQuery,
  GetTenantsQueryVariables
>
export const GetTenantDocument = gql`
  query getTenant($id: uuid!) {
    tenant: tenant_by_pk(id: $id) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    options
  )
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantQuery,
    GetTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    options
  )
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>
export type GetTenantLazyQueryHookResult = ReturnType<
  typeof useGetTenantLazyQuery
>
export type GetTenantQueryResult = Apollo.QueryResult<
  GetTenantQuery,
  GetTenantQueryVariables
>
export const InsertTenantDocument = gql`
  mutation insertTenant($tenant: tenant_insert_input!) {
    tenant: insert_tenant_one(object: $tenant) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`
export type InsertTenantMutationFn = Apollo.MutationFunction<
  InsertTenantMutation,
  InsertTenantMutationVariables
>

/**
 * __useInsertTenantMutation__
 *
 * To run a mutation, you first call `useInsertTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTenantMutation, { data, loading, error }] = useInsertTenantMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useInsertTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTenantMutation,
    InsertTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertTenantMutation,
    InsertTenantMutationVariables
  >(InsertTenantDocument, options)
}
export type InsertTenantMutationHookResult = ReturnType<
  typeof useInsertTenantMutation
>
export type InsertTenantMutationResult =
  Apollo.MutationResult<InsertTenantMutation>
export type InsertTenantMutationOptions = Apollo.BaseMutationOptions<
  InsertTenantMutation,
  InsertTenantMutationVariables
>
export const UpdateTenantDocument = gql`
  mutation updateTenant($id: uuid!, $set: tenant_set_input!) {
    tenant: update_tenant_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`
export type UpdateTenantMutationFn = Apollo.MutationFunction<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >(UpdateTenantDocument, options)
}
export type UpdateTenantMutationHookResult = ReturnType<
  typeof useUpdateTenantMutation
>
export type UpdateTenantMutationResult =
  Apollo.MutationResult<UpdateTenantMutation>
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>
export const GetAllTenantsDocument = gql`
  query getAllTenants {
    tenants: tenant {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetAllTenantsQuery__
 *
 * To run a query within a React component, call `useGetAllTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTenantsQuery,
    GetAllTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllTenantsQuery, GetAllTenantsQueryVariables>(
    GetAllTenantsDocument,
    options
  )
}
export function useGetAllTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTenantsQuery,
    GetAllTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllTenantsQuery, GetAllTenantsQueryVariables>(
    GetAllTenantsDocument,
    options
  )
}
export type GetAllTenantsQueryHookResult = ReturnType<
  typeof useGetAllTenantsQuery
>
export type GetAllTenantsLazyQueryHookResult = ReturnType<
  typeof useGetAllTenantsLazyQuery
>
export type GetAllTenantsQueryResult = Apollo.QueryResult<
  GetAllTenantsQuery,
  GetAllTenantsQueryVariables
>
export const GetUsersDocument = gql`
  query getUsers(
    $limit: Int
    $offset: Int
    $distinct_on: [user_select_column!]
    $order_by: [user_order_by!]
    $where: user_bool_exp
  ) {
    usersAggregate: user_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    users: user(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>
export const GetUserByFirebaseIdDocument = gql`
  query getUserByFirebaseId($firebaseId: String) {
    user(where: { firebase_id: { _eq: $firebaseId } }) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserByFirebaseIdQuery__
 *
 * To run a query within a React component, call `useGetUserByFirebaseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByFirebaseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByFirebaseIdQuery({
 *   variables: {
 *      firebaseId: // value for 'firebaseId'
 *   },
 * });
 */
export function useGetUserByFirebaseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export function useGetUserByFirebaseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export type GetUserByFirebaseIdQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdQuery
>
export type GetUserByFirebaseIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdLazyQuery
>
export type GetUserByFirebaseIdQueryResult = Apollo.QueryResult<
  GetUserByFirebaseIdQuery,
  GetUserByFirebaseIdQueryVariables
>
export const GetUserDocument = gql`
  query getUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const GetUserAndLocationsDocument = gql`
  query getUserAndLocations($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...UserFragment
    }
    locations: location {
      ...LocationFragment
    }
    tenants: tenant {
      ...TenantFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetUserAndLocationsQuery__
 *
 * To run a query within a React component, call `useGetUserAndLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAndLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAndLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAndLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAndLocationsQuery,
    GetUserAndLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserAndLocationsQuery,
    GetUserAndLocationsQueryVariables
  >(GetUserAndLocationsDocument, options)
}
export function useGetUserAndLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAndLocationsQuery,
    GetUserAndLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserAndLocationsQuery,
    GetUserAndLocationsQueryVariables
  >(GetUserAndLocationsDocument, options)
}
export type GetUserAndLocationsQueryHookResult = ReturnType<
  typeof useGetUserAndLocationsQuery
>
export type GetUserAndLocationsLazyQueryHookResult = ReturnType<
  typeof useGetUserAndLocationsLazyQuery
>
export type GetUserAndLocationsQueryResult = Apollo.QueryResult<
  GetUserAndLocationsQuery,
  GetUserAndLocationsQueryVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: uuid!, $set: user_set_input!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const GetUserDevicesSettingsDocument = gql`
  query getUserDevicesSettings(
    $limit: Int
    $offset: Int
    $distinct_on: [user_device_setting_select_column!]
    $order_by: [user_device_setting_order_by!]
    $where: user_device_setting_bool_exp
  ) {
    userDevicesSettingsAggregate: user_device_setting_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    userDevicesSettings: user_device_setting(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...UserDeviceSettingFragment
    }
  }
  ${UserDeviceSettingFragmentFragmentDoc}
`

/**
 * __useGetUserDevicesSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserDevicesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDevicesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDevicesSettingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserDevicesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDevicesSettingsQuery,
    GetUserDevicesSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDevicesSettingsQuery,
    GetUserDevicesSettingsQueryVariables
  >(GetUserDevicesSettingsDocument, options)
}
export function useGetUserDevicesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDevicesSettingsQuery,
    GetUserDevicesSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDevicesSettingsQuery,
    GetUserDevicesSettingsQueryVariables
  >(GetUserDevicesSettingsDocument, options)
}
export type GetUserDevicesSettingsQueryHookResult = ReturnType<
  typeof useGetUserDevicesSettingsQuery
>
export type GetUserDevicesSettingsLazyQueryHookResult = ReturnType<
  typeof useGetUserDevicesSettingsLazyQuery
>
export type GetUserDevicesSettingsQueryResult = Apollo.QueryResult<
  GetUserDevicesSettingsQuery,
  GetUserDevicesSettingsQueryVariables
>
export const GetUserDeviceSettingsDocument = gql`
  query getUserDeviceSettings($user_id: uuid!, $device_id: uuid!) {
    userDeviceSettings: user_device_setting_by_pk(
      user_id: $user_id
      device_id: $device_id
    ) {
      ...UserDeviceSettingFragment
    }
  }
  ${UserDeviceSettingFragmentFragmentDoc}
`

/**
 * __useGetUserDeviceSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserDeviceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDeviceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDeviceSettingsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      device_id: // value for 'device_id'
 *   },
 * });
 */
export function useGetUserDeviceSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDeviceSettingsQuery,
    GetUserDeviceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDeviceSettingsQuery,
    GetUserDeviceSettingsQueryVariables
  >(GetUserDeviceSettingsDocument, options)
}
export function useGetUserDeviceSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDeviceSettingsQuery,
    GetUserDeviceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDeviceSettingsQuery,
    GetUserDeviceSettingsQueryVariables
  >(GetUserDeviceSettingsDocument, options)
}
export type GetUserDeviceSettingsQueryHookResult = ReturnType<
  typeof useGetUserDeviceSettingsQuery
>
export type GetUserDeviceSettingsLazyQueryHookResult = ReturnType<
  typeof useGetUserDeviceSettingsLazyQuery
>
export type GetUserDeviceSettingsQueryResult = Apollo.QueryResult<
  GetUserDeviceSettingsQuery,
  GetUserDeviceSettingsQueryVariables
>
export const UpsertUserDeviceSettingDocument = gql`
  mutation upsertUserDeviceSetting(
    $user_device_setting: user_device_setting_insert_input!
    $on_conflict: user_device_setting_on_conflict!
  ) {
    userDeviceSetting: insert_user_device_setting_one(
      object: $user_device_setting
      on_conflict: $on_conflict
    ) {
      ...UserDeviceSettingFragment
    }
  }
  ${UserDeviceSettingFragmentFragmentDoc}
`
export type UpsertUserDeviceSettingMutationFn = Apollo.MutationFunction<
  UpsertUserDeviceSettingMutation,
  UpsertUserDeviceSettingMutationVariables
>

/**
 * __useUpsertUserDeviceSettingMutation__
 *
 * To run a mutation, you first call `useUpsertUserDeviceSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserDeviceSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserDeviceSettingMutation, { data, loading, error }] = useUpsertUserDeviceSettingMutation({
 *   variables: {
 *      user_device_setting: // value for 'user_device_setting'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertUserDeviceSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserDeviceSettingMutation,
    UpsertUserDeviceSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserDeviceSettingMutation,
    UpsertUserDeviceSettingMutationVariables
  >(UpsertUserDeviceSettingDocument, options)
}
export type UpsertUserDeviceSettingMutationHookResult = ReturnType<
  typeof useUpsertUserDeviceSettingMutation
>
export type UpsertUserDeviceSettingMutationResult =
  Apollo.MutationResult<UpsertUserDeviceSettingMutation>
export type UpsertUserDeviceSettingMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserDeviceSettingMutation,
  UpsertUserDeviceSettingMutationVariables
>
export const UpsertUserDevicesSettingDocument = gql`
  mutation upsertUserDevicesSetting(
    $user_devices_setting: [user_device_setting_insert_input!]!
    $on_conflict: user_device_setting_on_conflict!
  ) {
    userDevicesSetting: insert_user_device_setting(
      objects: $user_devices_setting
      on_conflict: $on_conflict
    ) {
      returning {
        ...UserDeviceSettingFragment
      }
    }
  }
  ${UserDeviceSettingFragmentFragmentDoc}
`
export type UpsertUserDevicesSettingMutationFn = Apollo.MutationFunction<
  UpsertUserDevicesSettingMutation,
  UpsertUserDevicesSettingMutationVariables
>

/**
 * __useUpsertUserDevicesSettingMutation__
 *
 * To run a mutation, you first call `useUpsertUserDevicesSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserDevicesSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserDevicesSettingMutation, { data, loading, error }] = useUpsertUserDevicesSettingMutation({
 *   variables: {
 *      user_devices_setting: // value for 'user_devices_setting'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertUserDevicesSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserDevicesSettingMutation,
    UpsertUserDevicesSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserDevicesSettingMutation,
    UpsertUserDevicesSettingMutationVariables
  >(UpsertUserDevicesSettingDocument, options)
}
export type UpsertUserDevicesSettingMutationHookResult = ReturnType<
  typeof useUpsertUserDevicesSettingMutation
>
export type UpsertUserDevicesSettingMutationResult =
  Apollo.MutationResult<UpsertUserDevicesSettingMutation>
export type UpsertUserDevicesSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserDevicesSettingMutation,
    UpsertUserDevicesSettingMutationVariables
  >
