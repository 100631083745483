import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

const MuiTextField: React.FC<TextFieldProps> = ({
  variant = 'outlined',
  ...props
}: TextFieldProps) => {
  return (
    <TextField
      margin='normal'
      fullWidth
      autoComplete='off'
      variant={variant}
      {...props}
    />
  )
}

export default MuiTextField
