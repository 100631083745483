import React, { lazy, Suspense } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { FullPageLoading } from 'components'
import { useThemeType } from 'context/theme'
import { themeConfig } from 'config'
import { useUser } from 'context/auth/user'
import { useAuth } from 'context/auth/auth'

const Authenticated = lazy(() => import('./screens/Authenticated'))
const Unauthenticated = lazy(() => import('./screens/Unauthenticated'))

const App = () => {
  const { firstLoading } = useAuth()
  const { firebaseUser } = useUser()
  const { themeType } = useThemeType()
  const theme = themeConfig(themeType)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<FullPageLoading />}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {firstLoading ? (
            <FullPageLoading />
          ) : firebaseUser && firebaseUser?.emailVerified ? (
            <Authenticated />
          ) : (
            <Unauthenticated />
          )}
        </LocalizationProvider>
      </Suspense>
    </ThemeProvider>
  )
}

export default App
