import { intervalToDuration, Duration } from 'date-fns'
import { TFunction } from 'react-i18next'

const formatDurationTime = (seconds: number, t: TFunction): string => {
  if (seconds === 0) {
    return `0 ${t('seg')}`
  }

  const durations = intervalToDuration({ start: 0, end: seconds * 1000 })
  let response = ''

  for (const duration in durations) {
    const time = durations[duration as keyof Duration]
    if (time) {
      response += `${time} ${t(duration)} `
    }
  }

  return response
}

export default {
  formatDurationTime
}
