import React from 'react'
import { Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'

import MuiButton from '../MuiButton'

type ConfirmDialogProps = {
  dialogContent: string
  openDialog: boolean
  onAccept?(event: React.SyntheticEvent): void
  onCancel?(event: React.SyntheticEvent): void
  disabled?: boolean
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  dialogContent,
  openDialog,
  onAccept,
  onCancel,
  disabled = false
}: ConfirmDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={openDialog}>
      <DialogTitle>{dialogContent}</DialogTitle>

      <DialogActions>
        <MuiButton
          onClick={onCancel}
          color='secondary'
          disabled={disabled}
          startIcon={<CloseIcon />}
        >
          {t('cancel')}
        </MuiButton>
        <MuiButton
          onClick={onAccept}
          color='primary'
          disabled={disabled}
          startIcon={<CheckIcon />}
        >
          {t('confirm')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
