import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tooltip, IconButton, MenuItem } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Controller, UseFormReturn, UseFieldArrayReturn } from 'react-hook-form'

import { MuiTextField } from 'components'
import { InsertDeviceSchemaType } from 'schemas/device'
import { Agent_Type_Enum } from 'api/generated'
import { metricUtils } from 'utils'

export type AgentFormTypes = {
  // eslint-disable-next-line
  form: UseFormReturn<InsertDeviceSchemaType, any>
  fieldArray: UseFieldArrayReturn<InsertDeviceSchemaType, 'agents', 'id'>
  agentIndex: number
}

const AgentForm: React.FC<AgentFormTypes> = ({
  form: { control, watch, setValue },
  fieldArray: { remove },
  agentIndex
}: AgentFormTypes) => {
  const { t } = useTranslation()
  const agents = watch('agents')
  const agent = agents?.[agentIndex]
  const agentType = agent?.type
  const metricTypes = metricUtils.metricByAgentType?.[agentType]?.metrics || []

  return (
    <Box
      key={agentIndex}
      display='flex'
      alignItems='baseline'
      flexDirection='row'
      mb={2}
    >
      <Tooltip title={t('delete') as string}>
        <span>
          <IconButton
            disabled={agents.length === 1}
            onClick={() => {
              remove(agentIndex)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Controller
        name={`agents.${agentIndex}.name` as const}
        control={control}
        defaultValue={agent.name}
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
          return (
            <MuiTextField
              label={t('name')}
              error={!!error?.message}
              helperText={error?.message && t(error?.message)}
              inputRef={ref}
              {...fieldProps}
            />
          )
        }}
      />

      <Controller
        name={`agents.${agentIndex}.type` as const}
        control={control}
        defaultValue={agent.type}
        render={({
          field: { ref, onChange, ...fieldProps },
          fieldState: { error }
        }) => {
          return (
            <MuiTextField
              label={t('type')}
              error={!!error?.message}
              helperText={error?.message && t(error?.message)}
              inputRef={ref}
              select
              onChange={event => {
                setValue(`agents.${agentIndex}.metric_types`, [])
                onChange(event)
              }}
              {...fieldProps}
            >
              {Object.values(Agent_Type_Enum).map(agentType => {
                return (
                  <MenuItem key={agentType} value={agentType}>
                    {t(agentType)}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          )
        }}
      />

      <Controller
        name={`agents.${agentIndex}.pin_id` as const}
        control={control}
        defaultValue={agent.pin_id}
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
          return (
            <MuiTextField
              label={t('pin_id')}
              error={!!error?.message}
              helperText={error?.message && t(error?.message)}
              inputRef={ref}
              {...fieldProps}
            />
          )
        }}
      />

      <Controller
        name={`agents.${agentIndex}.metric_types` as const}
        control={control}
        defaultValue={agent.metric_types}
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
          return (
            <MuiTextField
              label={t('metricTypes')}
              error={!!error?.message}
              helperText={error?.message && t(error?.message)}
              inputRef={ref}
              select
              SelectProps={{
                multiple: true
              }}
              {...fieldProps}
            >
              {metricTypes?.map(metricType => {
                return (
                  <MenuItem key={metricType} value={metricType}>
                    {t(metricType)}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          )
        }}
      />
    </Box>
  )
}

export default AgentForm
