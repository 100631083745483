import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

import { Metric_Type_Enum } from 'api/generated'

import { ReactComponent as HumidityIcon } from './icons/humidity.svg'
import { ReactComponent as OrpIcon } from './icons/orp.svg'
import { ReactComponent as PhIcon } from './icons/ph.svg'
import { ReactComponent as TemperatureIcon } from './icons/temperature.svg'
import { ReactComponent as RelayIcon } from './icons/relay.svg'

const MetricIcons = {
  [Metric_Type_Enum.Humidity]: HumidityIcon,
  [Metric_Type_Enum.Orp]: OrpIcon,
  [Metric_Type_Enum.Ph]: PhIcon,
  [Metric_Type_Enum.Temp]: TemperatureIcon,
  [Metric_Type_Enum.Relay]: RelayIcon
}

type MetricIconProps = SvgIconProps & {
  type: Metric_Type_Enum
}

const MetricIcon: React.FC<MetricIconProps> = ({
  type,
  ...props
}: MetricIconProps) => {
  return <SvgIcon component={MetricIcons?.[type]} {...props} />
}

export default MetricIcon
