import React, { Fragment } from 'react'

import { User_Role_Enum } from 'api/generated'
import { useUser } from 'context/auth/user'

type ProtectedComponentProps = {
  allowedRoles: User_Role_Enum[]
  children: JSX.Element | JSX.Element[]
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  allowedRoles,
  children
}: ProtectedComponentProps) => {
  const { dbUser } = useUser()

  return dbUser && allowedRoles?.includes(dbUser?.role) ? (
    <>{children}</>
  ) : (
    <Fragment />
  )
}

export default ProtectedComponent
