import * as yup from 'yup'

import { recipeOperators, RECIPE_CONDITION_RULE_TYPE } from 'config/constants'

export type RecipeStartupRuleV2 = {
  agentId?: string
  value?: number
}

export type RecipeTimeRuleV2 = {
  agentId?: string
  time?: number
  value?: number
}

export type RecipeIntervalRuleV2 = {
  agentId?: string
  time?: number
  delay?: number
  value?: number
}

export type RecipeAlarmV2 = {
  type?: string
  agentId?: string
  metricType?: string
  operator?: string
  minConditionValue?: number
  maxConditionValue?: number
  senderType?: string
  sendingInterval?: number
}

export type RecipeConditionRuleV2 = {
  type?: string
  conditionAgentId?: string
  metricType?: string
  operator?: string
  minConditionValue?: number
  maxConditionValue?: number
  agentId?: string
  value?: string
}

export type RecipeStepV2 = {
  id?: string
  time?: number
  alarms: RecipeAlarmV2[]
  timeRules: RecipeTimeRuleV2[]
  intervalRules: RecipeIntervalRuleV2[]
  conditionRules: RecipeConditionRuleV2[]
}

export type UpsertRecipeV2SchemaType = {
  id?: string
  deviceId: string
  name: string
  detail: string
  alarms: RecipeAlarmV2[]
  startupRules: RecipeStartupRuleV2[]
  timeRules: RecipeTimeRuleV2[]
  intervalRules: RecipeIntervalRuleV2[]
  conditionRules: RecipeConditionRuleV2[]
  steps: RecipeStepV2[]
}

export const alarmSchema = yup.object().shape({
  type: yup.string().required('requiredField'),
  agentId: yup
    .string()
    .uuid()
    .when('type', {
      is: (val: string) => val === RECIPE_CONDITION_RULE_TYPE.AGENT,
      then: schema => schema.required('requiredField'),
      otherwise: schema => schema.nullable()
    }),
  metricType: yup.string().required('requiredField'),
  operator: yup.string().required('requiredField'),
  minConditionValue: yup
    .number()
    .typeError('requiredField')
    .required('requiredField'),
  maxConditionValue: yup
    .number()
    .typeError('requiredField')
    .when('operator', {
      is: (val: string) => val === recipeOperators.BETWEEN.key,
      then: schema => schema.required('requiredField'),
      otherwise: schema => schema.nullable()
    }),
  senderType: yup.string().required('requiredField'),
  sendingInterval: yup.number().required('requiredField')
})

export const startupRuleSchema = yup.object().shape({
  agentId: yup.string().uuid().required('requiredField'),
  value: yup.number().typeError('requiredField').required('requiredField')
})

export const timeRuleSchema = yup.object().shape({
  agentId: yup.string().uuid().required('requiredField'),
  time: yup.date().required('requiredField'),
  value: yup.number().typeError('requiredField').required('requiredField')
})

export const intervalRuleSchema = yup.object().shape({
  agentId: yup.string().uuid().required('requiredField'),
  time: yup.number().typeError('requiredField').required('requiredField'),
  delay: yup.number().typeError('requiredField').required('requiredField'),
  value: yup.number().typeError('requiredField').required('requiredField')
})

export const conditionRuleSchema = yup.object().shape({
  type: yup.string().required('requiredField'),
  metricType: yup.string().required('requiredField'),
  conditionAgentId: yup
    .string()
    .uuid()
    .when('type', {
      is: (val: string) => val === RECIPE_CONDITION_RULE_TYPE.AGENT,
      then: schema => schema.required('requiredField'),
      otherwise: schema => schema.nullable()
    }),
  operator: yup.string().required('requiredField'),
  minConditionValue: yup
    .number()
    .typeError('requiredField')
    .required('requiredField'),
  maxConditionValue: yup
    .number()
    .typeError('requiredField')
    .when('operator', {
      is: (val: string) => val === recipeOperators.BETWEEN.key,
      then: schema => schema.required('requiredField'),
      otherwise: schema => schema.nullable()
    }),
  agentId: yup.string().uuid().required('requiredField'),
  value: yup.number().typeError('requiredField').required('requiredField')
})

export const createRecipeV2Schema = yup.object().shape({
  deviceId: yup.string().uuid().required('requiredField'),
  name: yup.string().required('requiredField'),
  detail: yup.string(),
  alarms: yup.array().of(alarmSchema),
  startupRules: yup.array().of(startupRuleSchema),
  timeRules: yup.array().of(timeRuleSchema),
  intervalRules: yup.array().of(intervalRuleSchema),
  conditionRules: yup.array().of(conditionRuleSchema),
  steps: yup
    .array()
    .of(
      yup.object().shape({
        time: yup.number().typeError('requiredField').required('requiredField'),
        alarms: yup.array().of(alarmSchema),
        timeRules: yup.array().of(timeRuleSchema),
        intervalRules: yup.array().of(intervalRuleSchema),
        conditionRules: yup.array().of(conditionRuleSchema)
      })
    )
    .required('requiredField')
})

export const updateRecipeV2Schema = yup.object().shape({
  id: yup.string().uuid().required('requiredField'),
  deviceId: yup.string().uuid().required('requiredField'),
  name: yup.string().required('requiredField'),
  detail: yup.string(),
  alarms: yup.array().of(alarmSchema),
  startupRules: yup.array().of(startupRuleSchema),
  timeRules: yup.array().of(timeRuleSchema),
  intervalRules: yup.array().of(intervalRuleSchema),
  conditionRules: yup.array().of(conditionRuleSchema),
  steps: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string().uuid(),
          time: yup
            .number()
            .typeError('requiredField')
            .required('requiredField'),
          alarms: yup.array().of(alarmSchema),
          timeRules: yup.array().of(timeRuleSchema),
          intervalRules: yup.array().of(intervalRuleSchema),
          conditionRules: yup.array().of(conditionRuleSchema)
        })
        .required('requiredField')
    )
    .required('requiredField')
})

export const defaultAlarmValues = {
  agentId: undefined,
  operator: undefined,
  minConditionValue: undefined,
  maxConditionValue: undefined,
  type: undefined,
  senderType: undefined
}

export const defaultIntervalRuleValues = {
  agentId: undefined,
  time: undefined,
  delay: undefined,
  value: undefined
}

export const defaultStartupRuleValues = {
  agentId: undefined,
  value: undefined
}

export const defaultTimeRuleValues = {
  agentId: undefined,
  time: undefined,
  value: undefined
}

export const defaultConditionRuleValues = {
  type: undefined,
  conditionAgentId: undefined,
  operator: undefined,
  minConditionValue: undefined,
  maxConditionValue: undefined,
  agentId: undefined,
  value: undefined
}

export const defaultStepValues = {
  time: undefined,
  alarms: [],
  timeRules: [],
  intervalRules: [],
  conditionRules: []
}

export const defaultValues = {
  name: undefined,
  detail: undefined,
  alarms: [],
  startupRules: [],
  timeRules: [],
  intervalRules: [],
  conditionRules: [],
  steps: []
}
