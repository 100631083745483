import React from 'react'
import { Grid, Box, Skeleton } from '@mui/material'

import DeviceListItem from '../DeviceListItem'
import { GetDevicesStatusSubscription } from 'api/generated'

type DeviceListProps = {
  data: GetDevicesStatusSubscription | undefined
  loading: boolean
  start(id: string): void
  stop(id: string): void
}

const DeviceList: React.FC<DeviceListProps> = ({
  data,
  loading,
  start,
  stop
}: DeviceListProps) => {
  return (
    <Grid container spacing={2}>
      {loading
        ? [...Array(9)].map((_time, key) => {
            return (
              <Grid key={key} item xs={12} sm={6} lg={4}>
                <Box maxWidth={500} minWidth={300}>
                  <Skeleton variant='rectangular' width='100%' height={250} />
                </Box>
              </Grid>
            )
          })
        : data?.devices?.map(device => {
            return (
              <Grid key={device.id} item xs={12} sm={6} lg={4}>
                <DeviceListItem data={device} start={start} stop={stop} />
              </Grid>
            )
          })}
    </Grid>
  )
}

export default DeviceList
