import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box, Tooltip, IconButton, MenuItem } from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useFieldArray, Controller, UseFormReturn } from 'react-hook-form'

import { MuiTextField, MuiButton } from 'components'
import {
  UpsertRecipeV2SchemaType,
  defaultTimeRuleValues,
  defaultIntervalRuleValues,
  defaultConditionRuleValues,
  defaultAlarmValues
} from 'schemas/recipeV2'
import {
  AgentFragmentFragment,
  Agent_Type_Enum,
  Metric_Type_Enum
} from 'api/generated'
import {
  recipeOperators,
  RECIPE_OPERATORS,
  RECIPE_CONDITION_RULE_TYPE,
  ALARM_SENDER_TYPE
} from 'config/constants'

export type StepRulesFormTypes = {
  // eslint-disable-next-line
  form: UseFormReturn<UpsertRecipeV2SchemaType, any>
  stepIndex: number
  deviceId: string
  agents: AgentFragmentFragment[] | undefined
}

const StepRulesForm: React.FC<StepRulesFormTypes> = ({
  form: { control, watch },
  stepIndex,
  deviceId,
  agents
}: StepRulesFormTypes) => {
  const { t } = useTranslation()
  const {
    fields: alarmFields,
    append: appendAlarm,
    remove: removeAlarm
  } = useFieldArray({
    control,
    name: `steps.${stepIndex}.alarms`
  })
  const {
    fields: timeRuleFields,
    append: appendTimeRule,
    remove: removeTimeRule
  } = useFieldArray({
    control,
    name: `steps.${stepIndex}.timeRules`
  })
  const {
    fields: intervalRuleFields,
    append: appendIntervalRule,
    remove: removeIntervalRule
  } = useFieldArray({
    control,
    name: `steps.${stepIndex}.intervalRules`
  })
  const {
    fields: conditionRuleFields,
    append: appendConditionRule,
    remove: removeConditionRule
  } = useFieldArray({
    control,
    name: `steps.${stepIndex}.conditionRules`
  })
  const alarms = watch(`steps.${stepIndex}.alarms`)
  const conditionRules = watch(`steps.${stepIndex}.conditionRules`)

  return (
    <>
      <Typography variant='h6' component='div'>
        <Box mt={2}>{t('alarms')}</Box>
      </Typography>

      {alarmFields.map((field, index) => {
        const alarm = alarms?.[index]
        const agentId = alarm?.agentId
        const operator = alarm?.operator
        const type = alarm?.type
        const metricTypes = agents?.find(
          ({ id }) => id === agentId
        )?.agent_metric_types
        const isBetween = operator === RECIPE_OPERATORS.BETWEEN

        return (
          <Box key={field.id} display='flex' alignItems='baseline' mb={2}>
            <Tooltip title={t('delete') as string}>
              <span>
                <IconButton
                  onClick={() => {
                    removeAlarm(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Controller
              name={`steps.${stepIndex}.alarms.${index}.type` as const}
              control={control}
              defaultValue={field.type}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('type')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {Object.keys(RECIPE_CONDITION_RULE_TYPE)?.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {t(type)}
                        </MenuItem>
                      )
                    })}
                  </MuiTextField>
                )
              }}
            />

            {type === RECIPE_CONDITION_RULE_TYPE.AGENT ? (
              <Controller
                name={`steps.${stepIndex}.alarms.${index}.agentId` as const}
                control={control}
                defaultValue={field.agentId}
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error }
                }) => {
                  return (
                    <MuiTextField
                      label={t('agent')}
                      error={!!error?.message}
                      helperText={error?.message && t(error?.message)}
                      inputRef={ref}
                      select
                      {...fieldProps}
                    >
                      {agents?.map(agent => {
                        return (
                          <MenuItem key={agent?.id} value={agent?.id}>
                            {`${agent?.name} - ${t(agent?.type)}`}
                          </MenuItem>
                        )
                      })}
                    </MuiTextField>
                  )
                }}
              />
            ) : null}

            <Controller
              name={`steps.${stepIndex}.alarms.${index}.metricType` as const}
              control={control}
              defaultValue={field.metricType}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('metricType')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {type === RECIPE_CONDITION_RULE_TYPE.AGENT
                      ? metricTypes?.map(metricType => {
                          return (
                            <MenuItem
                              key={metricType?.metric_type}
                              value={metricType?.metric_type}
                            >
                              {t(metricType?.metric_type)}
                            </MenuItem>
                          )
                        })
                      : Object.values(Metric_Type_Enum)
                          .filter(key => key !== Metric_Type_Enum.Relay)
                          ?.map(key => {
                            return (
                              <MenuItem key={key} value={key}>
                                {t(key)}
                              </MenuItem>
                            )
                          })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.alarms.${index}.operator` as const}
              control={control}
              defaultValue={field.operator}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('operator')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {Object.keys(recipeOperators)?.map(key => {
                      const recipeOperator = recipeOperators?.[key]

                      return (
                        <MenuItem key={key} value={key}>
                          {`${t(key)} ${recipeOperator?.operator}`}
                        </MenuItem>
                      )
                    })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={
                `steps.${stepIndex}.alarms.${index}.minConditionValue` as const
              }
              control={control}
              defaultValue={field.minConditionValue}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={isBetween ? t('minConditionValue') : t('value')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    type='number'
                    {...fieldProps}
                  />
                )
              }}
            />

            {isBetween ? (
              <Controller
                name={
                  `steps.${stepIndex}.alarms.${index}.maxConditionValue` as const
                }
                control={control}
                defaultValue={field.maxConditionValue}
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error }
                }) => {
                  return (
                    <MuiTextField
                      label={t('maxConditionValue')}
                      error={!!error?.message}
                      helperText={error?.message && t(error?.message)}
                      inputRef={ref}
                      type='number'
                      {...fieldProps}
                    />
                  )
                }}
              />
            ) : null}

            <Controller
              name={`steps.${stepIndex}.alarms.${index}.senderType` as const}
              control={control}
              defaultValue={field.senderType}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('sender')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {Object.keys(ALARM_SENDER_TYPE)?.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {t(type)}
                        </MenuItem>
                      )
                    })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={
                `steps.${stepIndex}.alarms.${index}.sendingInterval` as const
              }
              control={control}
              defaultValue={field.sendingInterval}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('sendingIntervalInSeconds')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    type='number'
                    inputProps={{
                      step: 5
                    }}
                    {...fieldProps}
                  />
                )
              }}
            />
          </Box>
        )
      })}

      <MuiButton
        variant='outlined'
        disabled={!deviceId}
        onClick={() => {
          appendAlarm(defaultAlarmValues)
        }}
        startIcon={<AddIcon />}
      >
        {t('addAlarm')}
      </MuiButton>

      <Typography variant='h6' component='div'>
        <Box mt={2}>{t('timeRules')}</Box>
      </Typography>

      {timeRuleFields.map((field, index) => {
        return (
          <Box key={field.id} display='flex' alignItems='baseline' mb={2}>
            <Tooltip title={t('delete') as string}>
              <span>
                <IconButton
                  onClick={() => {
                    removeTimeRule(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Controller
              name={`steps.${stepIndex}.timeRules.${index}.agentId` as const}
              control={control}
              defaultValue={field.agentId}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('agent')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {agents
                      ?.filter(agent => agent?.type === Agent_Type_Enum.Relay)
                      .map(agent => {
                        return (
                          <MenuItem key={agent?.id} value={agent?.id}>
                            {`${agent?.name} - ${t(agent?.type)}`}
                          </MenuItem>
                        )
                      })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.timeRules.${index}.time` as const}
              control={control}
              defaultValue={field.time}
              render={({
                field: { ref, value, onChange, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <TimePicker
                    label={t('hour')}
                    value={value}
                    onChange={newValue => {
                      onChange(newValue)
                    }}
                    renderInput={params => {
                      return (
                        <MuiTextField
                          error={!!error?.message}
                          helperText={error?.message && t(error?.message)}
                          inputRef={ref}
                          {...fieldProps}
                          {...params}
                        />
                      )
                    }}
                  />
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.timeRules.${index}.value` as const}
              control={control}
              defaultValue={field.value}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('status')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    <MenuItem value={0}>{t('turnOn')}</MenuItem>
                    <MenuItem value={1}>{t('turnOff')}</MenuItem>
                  </MuiTextField>
                )
              }}
            />
          </Box>
        )
      })}

      <MuiButton
        variant='outlined'
        disabled={!deviceId}
        onClick={() => {
          appendTimeRule(defaultTimeRuleValues)
        }}
        startIcon={<AddIcon />}
      >
        {t('addTimeRule')}
      </MuiButton>

      <Typography variant='h6' component='div'>
        <Box mt={2}>{t('intervalRules')}</Box>
      </Typography>

      {intervalRuleFields.map((field, index) => {
        return (
          <Box key={field.id} display='flex' alignItems='baseline' mb={2}>
            <Tooltip title={t('delete') as string}>
              <span>
                <IconButton
                  onClick={() => {
                    removeIntervalRule(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Controller
              name={
                `steps.${stepIndex}.intervalRules.${index}.agentId` as const
              }
              control={control}
              defaultValue={field.agentId}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('agent')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {agents
                      ?.filter(agent => agent?.type === Agent_Type_Enum.Relay)
                      .map(agent => {
                        return (
                          <MenuItem key={agent?.id} value={agent?.id}>
                            {`${agent?.name} - ${t(agent?.type)}`}
                          </MenuItem>
                        )
                      })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.intervalRules.${index}.time` as const}
              control={control}
              defaultValue={field.time}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('timeInSeconds')}
                    type='number'
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    inputProps={{
                      step: 5
                    }}
                    {...fieldProps}
                  />
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.intervalRules.${index}.delay` as const}
              control={control}
              defaultValue={field.delay}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('delayInSeconds')}
                    type='number'
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    inputProps={{
                      step: 5
                    }}
                    {...fieldProps}
                  />
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.intervalRules.${index}.value` as const}
              control={control}
              defaultValue={field.value}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('status')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    <MenuItem value={0}>{t('onOff')}</MenuItem>
                    <MenuItem value={1}>{t('offOn')}</MenuItem>
                  </MuiTextField>
                )
              }}
            />
          </Box>
        )
      })}

      <MuiButton
        variant='outlined'
        disabled={!deviceId}
        onClick={() => {
          appendIntervalRule(defaultIntervalRuleValues)
        }}
        startIcon={<AddIcon />}
      >
        {t('addIntervalRule')}
      </MuiButton>

      <Typography variant='h6' component='div'>
        <Box mt={2}>{t('conditionRules')}</Box>
      </Typography>

      {conditionRuleFields.map((field, index) => {
        const conditionRule = conditionRules?.[index]
        const conditionAgentId = conditionRule?.conditionAgentId
        const operator = conditionRule?.operator
        const type = conditionRule?.type
        const metricTypes = agents?.find(
          ({ id }) => id === conditionAgentId
        )?.agent_metric_types
        const isBetween = operator === RECIPE_OPERATORS.BETWEEN

        return (
          <Box key={field.id} display='flex' alignItems='baseline' mb={2}>
            <Tooltip title={t('delete') as string}>
              <span>
                <IconButton
                  onClick={() => {
                    removeConditionRule(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Controller
              name={`steps.${stepIndex}.conditionRules.${index}.type` as const}
              control={control}
              defaultValue={field.type}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('type')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {Object.keys(RECIPE_CONDITION_RULE_TYPE)?.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {t(type)}
                        </MenuItem>
                      )
                    })}
                  </MuiTextField>
                )
              }}
            />

            {type === RECIPE_CONDITION_RULE_TYPE.AGENT ? (
              <Controller
                name={
                  `steps.${stepIndex}.conditionRules.${index}.conditionAgentId` as const
                }
                control={control}
                defaultValue={field.conditionAgentId}
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error }
                }) => {
                  return (
                    <MuiTextField
                      label={t('conditionAgent')}
                      error={!!error?.message}
                      helperText={error?.message && t(error?.message)}
                      inputRef={ref}
                      select
                      {...fieldProps}
                    >
                      {agents?.map(agent => {
                        return (
                          <MenuItem key={agent?.id} value={agent?.id}>
                            {`${agent?.name} - ${t(agent?.type)}`}
                          </MenuItem>
                        )
                      })}
                    </MuiTextField>
                  )
                }}
              />
            ) : null}

            <Controller
              name={
                `steps.${stepIndex}.conditionRules.${index}.metricType` as const
              }
              control={control}
              defaultValue={field.metricType}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('metricType')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {type === RECIPE_CONDITION_RULE_TYPE.AGENT
                      ? metricTypes?.map(metricType => {
                          return (
                            <MenuItem
                              key={metricType?.metric_type}
                              value={metricType?.metric_type}
                            >
                              {t(metricType?.metric_type)}
                            </MenuItem>
                          )
                        })
                      : Object.values(Metric_Type_Enum)
                          .filter(key => key !== Metric_Type_Enum.Relay)
                          ?.map(key => {
                            return (
                              <MenuItem key={key} value={key}>
                                {t(key)}
                              </MenuItem>
                            )
                          })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={
                `steps.${stepIndex}.conditionRules.${index}.operator` as const
              }
              control={control}
              defaultValue={field.operator}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('operator')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {Object.keys(recipeOperators)?.map(key => {
                      const recipeOperator = recipeOperators?.[key]

                      return (
                        <MenuItem key={key} value={key}>
                          {`${t(key)} ${recipeOperator?.operator}`}
                        </MenuItem>
                      )
                    })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={
                `steps.${stepIndex}.conditionRules.${index}.minConditionValue` as const
              }
              control={control}
              defaultValue={field.minConditionValue}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={isBetween ? t('minConditionValue') : t('value')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    type='number'
                    {...fieldProps}
                  />
                )
              }}
            />

            {isBetween ? (
              <Controller
                name={
                  `steps.${stepIndex}.conditionRules.${index}.maxConditionValue` as const
                }
                control={control}
                defaultValue={field.maxConditionValue}
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error }
                }) => {
                  return (
                    <MuiTextField
                      label={t('maxConditionValue')}
                      error={!!error?.message}
                      helperText={error?.message && t(error?.message)}
                      inputRef={ref}
                      type='number'
                      {...fieldProps}
                    />
                  )
                }}
              />
            ) : null}

            <Controller
              name={
                `steps.${stepIndex}.conditionRules.${index}.agentId` as const
              }
              control={control}
              defaultValue={field.agentId}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('agent')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    {agents
                      ?.filter(agent => agent?.type === Agent_Type_Enum.Relay)
                      .map(agent => {
                        return (
                          <MenuItem key={agent?.id} value={agent?.id}>
                            {`${agent?.name} - ${t(agent?.type)}`}
                          </MenuItem>
                        )
                      })}
                  </MuiTextField>
                )
              }}
            />

            <Controller
              name={`steps.${stepIndex}.conditionRules.${index}.value` as const}
              control={control}
              defaultValue={field.value}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('status')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    select
                    {...fieldProps}
                  >
                    <MenuItem value={0}>{t('turnOn')}</MenuItem>
                    <MenuItem value={1}>{t('turnOff')}</MenuItem>
                  </MuiTextField>
                )
              }}
            />
          </Box>
        )
      })}

      <MuiButton
        variant='outlined'
        disabled={!deviceId}
        onClick={() => {
          appendConditionRule(defaultConditionRuleValues)
        }}
        startIcon={<AddIcon />}
      >
        {t('addConditionRule')}
      </MuiButton>
    </>
  )
}

export default StepRulesForm
