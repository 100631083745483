import React, {
  useReducer,
  createContext,
  useContext,
  useCallback,
  useEffect
} from 'react'
import {
  onAuthStateChanged,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
  ActionCodeSettings,
  sendEmailVerification,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth'
import { useApolloClient } from '@apollo/client'

import {
  ProviderProps,
  State,
  NewState,
  SignUpData,
  LogInData,
  AuthContextProps,
  OnCompleted
} from './types'
import firebaseLib from 'lib/firebase'
import { EmailVerifiedDialog } from 'components'
import { useGetUserByFirebaseIdQuery } from 'api/generated'

const { auth } = firebaseLib
const AuthContext = createContext<AuthContextProps | undefined>(undefined)

const AuthProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const client = useApolloClient()
  const [state, setState] = useReducer(
    (state: State, newState: NewState) => ({ ...state, ...newState }),
    {
      firstLoading: true,
      loading: false,
      error: null,
      firebaseUser: null,
      emailVerifiedModalOpen: false
    }
  )
  const {
    data: users,
    loading: loadingDbUser,
    refetch: refetchDbUser
  } = useGetUserByFirebaseIdQuery({
    variables: {
      firebaseId: state?.firebaseUser?.uid
    },
    skip: !state?.firebaseUser?.uid
  })
  const dbUser = users?.user?.[0]

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      try {
        if (user) {
          const { claims } = await user.getIdTokenResult()
          const hasuraClaims = claims?.['https://hasura.io/jwt/claims']

          if (!hasuraClaims) {
            setState({
              firebaseUser: null,
              firstLoading: false
            })

            return
          }

          setState({
            firebaseUser: user,
            firstLoading: false
          })
        } else {
          setState({
            firebaseUser: null,
            firstLoading: false
          })
        }
      } catch (error) {
        setState({
          firebaseUser: null,
          firstLoading: false
        })
      }
    })

    return () => unsubscribe()
  }, [])

  const signUp = useCallback(
    async (
      { email, password }: SignUpData,
      onCompleted?: OnCompleted
    ): Promise<UserCredential | undefined> => {
      try {
        setState({
          loading: true,
          error: null
        })

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )

        await sendEmailVerification(userCredential?.user)

        setState({
          loading: false
        })

        if (onCompleted) {
          onCompleted(userCredential)
        }

        return userCredential
      } catch (e) {
        const error = e as Error
        setState({ loading: false, error })
      }
    },
    []
  )

  const logIn = useCallback(
    async (
      { email, password }: LogInData,
      onCompleted?: OnCompleted
    ): Promise<UserCredential | undefined> => {
      try {
        setState({
          loading: true,
          error: null
        })

        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        )

        const user = userCredential?.user

        setState({
          loading: false,
          firebaseUser: user,
          emailVerifiedModalOpen: !user?.emailVerified
        })

        if (onCompleted) {
          onCompleted(userCredential)
        }

        return userCredential
      } catch (e) {
        const error = e as Error
        setState({ loading: false, error })
      }
    },
    []
  )

  const logout = useCallback(async (): Promise<void> => {
    try {
      setState({
        loading: true,
        error: null
      })

      await firebaseSignOut(auth)
      await client.clearStore()

      setState({
        firebaseUser: null,
        loading: false
      })
    } catch (e) {
      const error = e as Error
      setState({ loading: false, error })
    }
  }, [client])

  const handleSendPasswordResetEmail = useCallback(
    async (
      email: string,
      actionCodeSettings?: ActionCodeSettings | undefined,
      callback?: () => void
    ) => {
      try {
        setState({
          loading: true,
          error: null
        })

        await sendPasswordResetEmail(auth, email, actionCodeSettings)

        setState({
          loading: false,
          error: null
        })

        if (callback) {
          callback()
        }
      } catch (e) {
        const error = e as Error
        setState({
          loading: false,
          error
        })
      }
    },
    []
  )

  const handleSendEmailVerification = useCallback(async () => {
    try {
      setState({
        loading: true,
        error: null
      })

      if (state?.firebaseUser) {
        await sendEmailVerification(state?.firebaseUser)
      }

      setState({
        loading: false,
        emailVerifiedModalOpen: false,
        error: null
      })
    } catch (e) {
      const error = e as Error
      setState({
        loading: false,
        error,
        emailVerifiedModalOpen: false
      })
    }
  }, [state?.firebaseUser])

  const handleUpdatePassword = useCallback(
    async (
      currentPassword: string,
      newPassword: string,
      callback?: () => void
    ) => {
      try {
        const firebaseUser = auth.currentUser

        if (!firebaseUser?.email) {
          return
        }

        setState({
          loading: true,
          error: null
        })

        const credential = EmailAuthProvider.credential(
          firebaseUser.email,
          currentPassword
        )

        await reauthenticateWithCredential(firebaseUser, credential)
        await updatePassword(firebaseUser, newPassword)

        setState({
          loading: false,
          error: null
        })

        if (callback) {
          callback()
        }
      } catch (e) {
        const error = e as Error
        setState({
          loading: false,
          error
        })
      }
    },
    []
  )

  const handleSetEmailVerifiedModalInvisible = useCallback(() => {
    setState({
      emailVerifiedModalOpen: false
    })
  }, [])

  const handleForceRefreshToken = useCallback(
    async (callback?: () => void) => {
      try {
        setState({
          loading: true,
          error: null
        })

        await auth.currentUser?.getIdTokenResult(true)
        const firebaseUser = auth.currentUser
        if (!firebaseUser) {
          return
        }

        await client.clearStore()
        await refetchDbUser({
          firebaseId: firebaseUser?.uid
        })

        setState({
          loading: false,
          error: null,
          firebaseUser
        })

        if (callback) {
          callback()
        }
      } catch (e) {
        const error = e as Error
        setState({
          loading: false,
          error
        })
      }
    },
    [client, refetchDbUser]
  )

  return (
    <AuthContext.Provider
      value={{
        signUp,
        logIn,
        logout,
        sendPasswordResetEmail: handleSendPasswordResetEmail,
        forceRefreshToken: handleForceRefreshToken,
        updatePassword: handleUpdatePassword,
        dbUser,
        loadingDbUser,
        ...state
      }}
    >
      <EmailVerifiedDialog
        open={state.emailVerifiedModalOpen}
        onClose={handleSetEmailVerifiedModalInvisible}
        loading={state?.loading}
        onSendEmail={handleSendEmailVerification}
        user={state?.firebaseUser}
      />
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
