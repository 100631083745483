import React from 'react'
import { useTheme } from '@mui/material'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  PieProps,
  Legend,
  Tooltip,
  Cell
} from 'recharts'

export type PieChartComponentProps = Omit<PieProps, 'dataKey'> & {
  data: {
    name: string
    value: number
  }[]
  unit?: string
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({
  data,
  label,
  unit
}: PieChartComponentProps) => {
  const {
    palette: {
      primary: { main: primaryColor },
      text: { primary: primaryText },
      background: { paper },
      grey
    }
  } = useTheme()

  return (
    <ResponsiveContainer width='100%' height={250}>
      <PieChart width={730} height={250}>
        <Pie dataKey='value' data={data} label={label} innerRadius={60}>
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index === 0 ? primaryColor : grey['500']}
            />
          ))}
        </Pie>
        <Legend />
        <Tooltip
          contentStyle={{ backgroundColor: paper }}
          itemStyle={{ color: primaryText }}
          formatter={value => (unit ? `${value}${unit}` : value)}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartComponent
