import { User_Role_Enum } from 'api/generated'

export type RoleMetadata = {
  name: User_Role_Enum
  isBasedOnLocation: boolean
  level: number
}

export type RolesMetadata = {
  [role in User_Role_Enum]: RoleMetadata
}

export const rolesMetadata: RolesMetadata = {
  [User_Role_Enum.Admin]: {
    name: User_Role_Enum.Admin,
    isBasedOnLocation: false,
    level: 0
  },
  [User_Role_Enum.TenantAdmin]: {
    name: User_Role_Enum.TenantAdmin,
    isBasedOnLocation: false,
    level: 1
  },
  [User_Role_Enum.LocationAdmin]: {
    name: User_Role_Enum.LocationAdmin,
    isBasedOnLocation: true,
    level: 2
  },
  [User_Role_Enum.Viewer]: {
    name: User_Role_Enum.Viewer,
    isBasedOnLocation: true,
    level: 3
  },
  [User_Role_Enum.Anonymous]: {
    name: User_Role_Enum.Anonymous,
    isBasedOnLocation: false,
    level: 4
  }
}

const rolesMetadataValues = Object.values(rolesMetadata)
const rolesBasedOnLocationsMetadata = rolesMetadataValues?.filter(
  roleMetadata => !!roleMetadata?.isBasedOnLocation
)
const roleNamesBasedOnLocationsArray = rolesBasedOnLocationsMetadata?.map(
  role => role?.name
)

const findRoleMetadata = (
  userRole?: User_Role_Enum
): RoleMetadata | undefined => {
  const userRoleMetadata = rolesMetadataValues?.find(
    role => role?.name === userRole
  )

  return userRoleMetadata
}

const getAllowedRolesByUserRole = (
  userRole?: User_Role_Enum
): User_Role_Enum[] => {
  const userRoleMetadata = findRoleMetadata(userRole)

  if (!userRoleMetadata) {
    return []
  }

  const roles = rolesMetadataValues
    ?.filter(role => role?.level >= userRoleMetadata?.level)
    ?.map(role => role?.name)

  return roles
}

const getIsRoleBasedOnLocations = (userRole: User_Role_Enum): boolean => {
  return roleNamesBasedOnLocationsArray?.includes(userRole)
}

export default {
  getAllowedRolesByUserRole,
  getIsRoleBasedOnLocations
}
