import React, { useState, useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Paper,
  Typography,
  ButtonBase
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material'
import { httpsCallable } from 'firebase/functions'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import MuiSnackbar from '../MuiSnackbar'
import MuiButton from '../MuiButton'
import MuiTextField from '../MuiTextField'
import { AgentFragmentFragment } from 'api/generated'
import firebaseLib from 'lib/firebase'
import {
  SendAgentCommandSchemaType,
  sendAgentCommandSchema
} from 'schemas/agent'
import { commonCommands } from 'config/constants'

type SendAgentCommandDialogProps = {
  agent?: AgentFragmentFragment | null
  openDialog: boolean
  onClose(event: React.SyntheticEvent): void
}

const SendAgentCommandDialog: React.FC<SendAgentCommandDialogProps> = ({
  agent,
  openDialog,
  onClose
}: SendAgentCommandDialogProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const { control, handleSubmit, reset } = useForm<SendAgentCommandSchemaType>({
    resolver: yupResolver(sendAgentCommandSchema)
  })

  const handleSendAgentCommand = useCallback(
    async (data: SendAgentCommandSchemaType): Promise<void> => {
      try {
        setError(undefined)
        setLoading(true)
        const sendAgentCommandFunction = httpsCallable(
          firebaseLib.functions,
          'sendAgentCommandCallable'
        )
        await sendAgentCommandFunction({
          deviceId: agent?.device_id,
          agentId: agent?.id,
          command: data?.command,
          waitTime: data?.waitTime
        })

        reset()
      } catch (e) {
        const error = e as Error
        setError(error?.message)
      } finally {
        setLoading(false)
      }
    },
    [agent, reset]
  )

  const handleOnClose = useCallback(
    (event: React.SyntheticEvent) => {
      if (loading) {
        return
      }

      onClose(event)
      reset()
    },
    [loading, onClose, reset]
  )

  return (
    <>
      <MuiSnackbar
        snackbarProps={{
          open: !!error
        }}
        alertProps={{
          severity: 'error'
        }}
        message={JSON.stringify(error)}
      />

      <Dialog open={openDialog} onClose={handleOnClose}>
        <form
          onSubmit={handleSubmit(data => {
            handleSendAgentCommand(data)
          })}
        >
          <DialogTitle>{t('sendCommand')}</DialogTitle>

          <DialogContent>
            <Typography variant='body2'>{t('commonCommands')}:</Typography>

            <Stack direction='row' spacing={1} mt={1}>
              {Object.keys(commonCommands)?.map(key => {
                const commonCommand = commonCommands?.[key]
                const label = commonCommand?.label
                const value = commonCommand?.getValue(t)
                const waitTime = commonCommand?.waitTime

                return (
                  <ButtonBase
                    key={key}
                    onClick={() => {
                      reset({
                        command: value,
                        waitTime
                      })
                    }}
                  >
                    <Box
                      component={Paper}
                      display='flex'
                      flexDirection='column'
                      py={1}
                      px={2}
                      elevation={4}
                    >
                      <Typography variant='caption' align='left'>
                        {t(label)}
                      </Typography>
                      <Typography variant='caption'>{value}</Typography>
                    </Box>
                  </ButtonBase>
                )
              })}
            </Stack>
            <Controller
              name='command'
              control={control}
              defaultValue=''
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={t('command')}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    {...fieldProps}
                  />
                )
              }}
            />

            <Controller
              name='waitTime'
              control={control}
              defaultValue={300}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error }
              }) => {
                return (
                  <MuiTextField
                    label={`${t('waitTime')} (ms)`}
                    error={!!error?.message}
                    helperText={error?.message && t(error?.message)}
                    inputRef={ref}
                    type='number'
                    {...fieldProps}
                  />
                )
              }}
            />
          </DialogContent>

          <DialogActions>
            <MuiButton
              onClick={handleOnClose}
              color='secondary'
              startIcon={<CloseIcon />}
              disabled={loading}
            >
              {t('cancel')}
            </MuiButton>

            <MuiButton
              type='submit'
              color='primary'
              startIcon={<SendIcon />}
              disabled={loading}
            >
              {t('send')}
            </MuiButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default SendAgentCommandDialog
