import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Box,
  Skeleton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material'

import { MuiButton, MuiTextField, QueryError } from 'components'
import { GetRecipesQuery } from 'api/generated'
import { ApolloError } from '@apollo/client'

const schema = yup.object().shape({
  batchCode: yup.string().required('requiredField'),
  recipeId: yup.string().required('requiredField')
})

export type CookDialogFormData = {
  batchCode: string
  recipeId: string
}

type CookDialogProps = {
  data: GetRecipesQuery | undefined
  loading: boolean
  onSubmit(data: CookDialogFormData): void
  open: boolean
  onClose(): void
  mutationLoading: boolean
  error: ApolloError | undefined
  refetch(): void
}

const CookDialog: React.FC<CookDialogProps> = ({
  data,
  loading,
  onSubmit,
  open,
  onClose,
  mutationLoading,
  error,
  refetch
}: CookDialogProps) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<CookDialogFormData>({
    resolver: yupResolver(schema)
  })

  return (
    <Dialog
      onClose={() => {
        onClose()
        reset()
      }}
      open={open}
    >
      <form
        onSubmit={handleSubmit(data => {
          onSubmit(data)
          reset()
        })}
      >
        <DialogTitle>{t('cook')}</DialogTitle>
        <DialogContent>
          {error ? (
            <QueryError error={error} loading={loading} refetch={refetch} />
          ) : loading ? (
            [...Array(5)].map((_time, key) => {
              return (
                <Box key={key} mb={1} minWidth={250}>
                  <Skeleton variant='rectangular' width='100%' height={40} />
                </Box>
              )
            })
          ) : (
            <>
              <Controller
                name='batchCode'
                control={control}
                defaultValue=''
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error }
                }) => {
                  return (
                    <MuiTextField
                      label={t('batchCode')}
                      error={!!error?.message}
                      helperText={error?.message && t(error?.message)}
                      inputRef={ref}
                      {...fieldProps}
                    />
                  )
                }}
              />

              <Controller
                name='recipeId'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl component='fieldset' error={!!error?.message}>
                      <FormLabel component='legend'>{t('recipe')}</FormLabel>
                      <RadioGroup {...field}>
                        {data?.recipes?.map(recipe => {
                          return (
                            <FormControlLabel
                              key={recipe.id}
                              value={recipe.id}
                              control={<Radio />}
                              label={recipe.name}
                            />
                          )
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {error?.message && t(error?.message)}
                      </FormHelperText>
                    </FormControl>
                  )
                }}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <MuiButton
            type='submit'
            disabled={mutationLoading}
            startIcon={<PlayArrowIcon />}
          >
            {t('start')}
          </MuiButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CookDialog
