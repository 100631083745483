import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import set from 'lodash.set'
import { startOfDay, endOfDay } from 'date-fns'
import { TFunction } from 'i18next'

const initialWidth = 180
const initialPage = 0
const initialPageSize = 10
const initialSortModel: GridSortModel = [{ field: 'id', sort: 'asc' }]

const getOrderBy = (
  sortModel?: GridSortModel,
  defaultSortModel: GridSortModel = initialSortModel
): Record<string, unknown> => {
  const field = sortModel?.[0]?.field
  const sort = sortModel?.[0]?.sort

  if (field && sort) {
    const orderBy = {}
    set(orderBy, field, sort)
    return orderBy
  } else {
    const orderBy = {}
    const initialField = defaultSortModel?.[0].field
    const initialSort = defaultSortModel?.[0].sort
    set(orderBy, initialField, initialSort)
    return orderBy
  }
}

const getHasuraValue = (
  value: string,
  muiOperator?: string
): string | number | Date => {
  switch (muiOperator) {
    case 'contains':
      return `%${value}%`

    case 'startsWith':
      return `${value}%`

    case 'endsWith':
      return `%${value}`

    case 'before':
    case 'onOrAfter':
      return startOfDay(value as unknown as Date)

    case 'after':
    case 'onOrBefore':
      return endOfDay(value as unknown as Date)

    case '=':
    case '!=':
    case '>':
    case '>=':
    case '<':
    case '<=':
      return parseInt(value)

    default:
      return value || ''
  }
}

const getHasuraOperator = (muiOperator?: string): string => {
  switch (muiOperator) {
    case 'and':
      return '_and'

    case 'or':
      return '_or'

    case 'equals':
    case 'is':
    case '=':
      return '_eq'

    case '!=':
      return '_neq'

    case '>':
    case 'after':
      return '_gt'

    case '<':
    case 'before':
      return '_lt'

    case '>=':
    case 'onOrAfter':
      return '_gte'

    case '<=':
    case 'onOrBefore':
      return '_lte'

    case 'contains':
    case 'startsWith':
    case 'endsWith':
      return '_ilike'

    default:
      throw new Error(`operator ${muiOperator} not available`)
  }
}

const getWhere = (
  filterModel?: GridFilterModel
): Record<string, unknown> | undefined => {
  if (!filterModel) {
    return {}
  }

  const linkOperator = getHasuraOperator(filterModel.linkOperator)
  const where = { [linkOperator]: [] }
  for (const item of filterModel.items) {
    const { columnField, operatorValue, value } = item
    if (value) {
      const hasuraOperator = getHasuraOperator(operatorValue)
      const hasuraValue = getHasuraValue(value, operatorValue)
      const whereItem = {}
      set(whereItem, `${columnField}.${hasuraOperator}`, hasuraValue)
      where?.[linkOperator]?.push(whereItem as never)
    }
  }

  return where
}

const getLocales = (t: TFunction): Record<string, unknown> => {
  return {
    noRowsLabel: t('noRowsLabel'),
    errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
    toolbarDensity: t('toolbarDensity'),
    toolbarDensityLabel: t('toolbarDensityLabel'),
    toolbarDensityCompact: t('toolbarDensityCompact'),
    toolbarDensityStandard: t('toolbarDensityStandard'),
    toolbarDensityComfortable: t('toolbarDensityComfortable'),
    toolbarColumns: t('toolbarColumns'),
    toolbarColumnsLabel: t('toolbarColumnsLabel'),
    toolbarFilters: t('toolbarFilters'),
    toolbarFiltersLabel: t('toolbarFiltersLabel'),
    toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
    toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
    toolbarExport: t('toolbarExport'),
    toolbarExportLabel: t('toolbarExportLabel'),
    toolbarExportCSV: t('toolbarExportCSV'),
    columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
    columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
    columnsPanelDragIconLabel: t('columnsPanelDragIconLabel'),
    columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
    columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
    filterPanelAddFilter: t('filterPanelAddFilter'),
    filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
    filterPanelOperators: t('filterPanelOperators'),
    filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
    filterPanelOperatorOr: t('filterPanelOperatorOr'),
    filterPanelColumns: t('filterPanelColumns'),
    filterPanelInputLabel: t('filterPanelInputLabel'),
    filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
    filterOperatorContains: t('filterOperatorContains'),
    filterOperatorEquals: t('filterOperatorEquals'),
    filterOperatorStartsWith: t('filterOperatorStartsWith'),
    filterOperatorEndsWith: t('filterOperatorEndsWith'),
    filterOperatorIs: t('filterOperatorIs'),
    filterOperatorNot: t('filterOperatorNot'),
    filterOperatorAfter: t('filterOperatorAfter'),
    filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
    filterOperatorBefore: t('filterOperatorBefore'),
    filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
    filterOperatorIsEmpty: t('filterOperatorIsEmpty'),
    filterOperatorIsNotEmpty: t('filterOperatorIsNotEmpty'),
    columnMenuLabel: t('columnMenuLabel'),
    columnMenuShowColumns: t('columnMenuShowColumns'),
    columnMenuFilter: t('columnMenuFilter'),
    columnMenuHideColumn: t('columnMenuHideColumn'),
    columnMenuUnsort: t('columnMenuUnsort'),
    columnMenuSortAsc: t('columnMenuSortAsc'),
    columnMenuSortDesc: t('columnMenuSortDesc'),
    columnHeaderFiltersLabel: t('columnHeaderFiltersLabel'),
    columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
    footerTotalRows: t('footerTotalRows'),
    columnHeaderFiltersTooltipActive: (count: number) =>
      count > 1
        ? `${count} ${t('activeFilters')}`
        : `${count} ${t('activeFilter')}`,
    footerRowSelected: (count: number) =>
      count > 1
        ? `${count.toLocaleString()} ${t('rowsSelected')}`
        : `${count.toLocaleString()} ${t('rowSelected')}`,
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
      `${visibleCount.toLocaleString()} ${t(
        'of'
      )} ${totalCount.toLocaleString()}`,
    toolbarFiltersTooltipActive: (count: number) =>
      count > 1
        ? `${count} ${t('activeFilters')}`
        : `${count} ${t('activeFilter')}`
  }
}

type GeneratePathProps = {
  pageSize: number
  page: number
  sortModel?: GridSortModel
  filterModel?: GridFilterModel
}

const generatePath = ({
  pageSize,
  page,
  sortModel,
  filterModel
}: GeneratePathProps): string =>
  `?pageSize=${pageSize}&page=${page}&sortModel=${JSON.stringify(
    sortModel
  )}&filterModel=${JSON.stringify(filterModel)}`

export default {
  initialWidth,
  initialPage,
  initialPageSize,
  initialSortModel,
  getOrderBy,
  getHasuraValue,
  getHasuraOperator,
  getWhere,
  getLocales,
  generatePath
}
