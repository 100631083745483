import React from 'react'
import { ApolloError } from '@apollo/client'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Replay as ReplayIcon } from '@mui/icons-material'

import { MuiButton } from 'components'

type QueryErrorProps = {
  error: ApolloError | undefined
  refetch(): void
  loading: boolean
}

const QueryError: React.FC<QueryErrorProps> = ({
  error,
  loading,
  refetch
}: QueryErrorProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant='h2' component='div'>
        <Box my={3}>{`${t('somethingWentWrong')} ${error}`}</Box>
      </Typography>

      <MuiButton
        fullWidth={false}
        disabled={loading}
        onClick={refetch}
        startIcon={<ReplayIcon />}
      >
        {t('retry')}
      </MuiButton>
    </>
  )
}

export default QueryError
