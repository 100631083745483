import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark'
  }
}

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light'
  }
}

export default (type: 'light' | 'dark'): Theme =>
  type === 'dark'
    ? createTheme(darkThemeOptions)
    : createTheme(lightThemeOptions)
