import React from 'react'
import { CardHeader, CardContent } from '@mui/material'

import { MuiCard, PieChart } from 'components'
import { PieChartComponentProps } from '../PieChart'

type PieChartCardProps = PieChartComponentProps & {
  title: JSX.Element | JSX.Element[]
}

const PieChartCard: React.FC<PieChartCardProps> = ({
  title,
  data,
  ...props
}: PieChartCardProps) => {
  return (
    <MuiCard>
      <CardHeader title={title} />
      <CardContent>
        {data?.length ? <PieChart data={data} {...props} /> : null}
      </CardContent>
    </MuiCard>
  )
}

export default PieChartCard
