import React from 'react'
import {
  CircularProgress,
  CircularProgressProps,
  Typography,
  Box
} from '@mui/material'

type MuiCircularProgressProps = CircularProgressProps & {
  value: number
}

const MuiCircularProgress: React.FC<MuiCircularProgressProps> = ({
  value,
  ...props
}: MuiCircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' value={value} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color='text.secondary'
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default MuiCircularProgress
