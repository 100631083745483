import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import { useThemeType } from 'context/theme'

export type LineChartData = {
  [x: string]: string | number
  time: string
}

type LineChartProps = {
  data: LineChartData[]
  type: string
}

const LineChartComponent: React.FC<LineChartProps> = ({
  data,
  type
}: LineChartProps) => {
  const { t } = useTranslation()
  const {
    palette: {
      primary: { main: primaryColor },
      common: { white },
      background: { paper }
    }
  } = useTheme()
  const { isDarkTheme } = useThemeType()

  return (
    <ResponsiveContainer width='100%' height={250}>
      <LineChart
        width={730}
        height={250}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3' />
        <XAxis dataKey='time' />
        <YAxis />
        <Tooltip
          contentStyle={{ backgroundColor: paper }}
          labelStyle={{ backgroundColor: paper }}
        />
        <Legend />
        <Line
          type='monotone'
          dataKey={t(type || '') as string}
          stroke={isDarkTheme ? white : primaryColor}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartComponent
