import * as yup from 'yup'

import { Agent_Type_Enum, Metric_Type_Enum } from 'api/generated'

export type UpdateAgentSchemaType = {
  id?: string
  name: string
  type: Agent_Type_Enum
  pin_id: string
  metric_types: Metric_Type_Enum[]
}

export type SendAgentCommandSchemaType = {
  command: string
  waitTime: number
}

export const updateAgentSchema = yup.object().shape({
  id: yup.string().uuid().required('requiredField'),
  name: yup.string().required('requiredField'),
  type: yup.string().required('requiredField'),
  pin_id: yup.string().required('requiredField'),
  metric_types: yup
    .array()
    .of(yup.string().required('requiredField'))
    .required('requiredField')
    .test('atLeastOne', 'requiredField', metricTypes => {
      return !!metricTypes?.length
    })
})

export const sendAgentCommandSchema = yup.object().shape({
  command: yup.string().required('requiredField'),
  waitTime: yup.number().typeError('requiredField').required('requiredField')
})
