import React from 'react'
import { Box } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  getGridDateOperators
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import { dataGridUtils } from 'utils'
import { MuiGridToolbar } from 'components'

type MuiDataGridProps = {
  height?: string
  width?: string
  Toolbar?: React.FC
} & DataGridProProps

const dateFilterOperators = getGridDateOperators().filter(({ value }) =>
  ['before', 'onOrBefore', 'after', 'onOrAfter'].includes(value)
)

const MuiDataGrid: React.FC<MuiDataGridProps> = ({
  height = '80vh',
  width = '100%',
  Toolbar = MuiGridToolbar,
  ...props
}: MuiDataGridProps) => {
  const { t } = useTranslation()
  const localeText = dataGridUtils.getLocales(t)

  return (
    <Box height={height} width={width}>
      <DataGridPro
        rowsPerPageOptions={[10, 50, 100, 200]}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        showCellRightBorder={true}
        localeText={localeText}
        columnTypes={{
          customDate: {
            extendType: 'date',
            filterOperators: dateFilterOperators
          }
        }}
        components={{
          Toolbar
        }}
        {...props}
      />
    </Box>
  )
}

export default MuiDataGrid
