import React, { Fragment } from 'react'
import { Typography, Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash.groupby'
import { format } from 'date-fns'

import { LineChartMetric } from 'components'
import { GetAgentStatusSubscription, Metric_Type_Enum } from 'api/generated'

type AgentMetricChartsProps = {
  data: GetAgentStatusSubscription | undefined
  loading: boolean
}

const AgentMetricCharts: React.FC<AgentMetricChartsProps> = ({
  data,
  loading
}: AgentMetricChartsProps) => {
  const { t } = useTranslation()
  const agent = data?.agent
  const metricsGroupedByType = Object.fromEntries(
    Object.entries(groupBy(agent?.metrics, 'type')).sort()
  )

  return (
    <>
      {loading ? (
        [...Array(4)].map((_time, key) => {
          return (
            <Box key={key} width='100%' height={250} mb={1}>
              <Skeleton variant='rectangular' width='100%' height={250} />
            </Box>
          )
        })
      ) : agent ? (
        <>
          <Typography variant='h5' component='div'>
            <Box mb={1}>{agent?.name}</Box>
          </Typography>

          {Object.keys(metricsGroupedByType).map(type => {
            const metrics = metricsGroupedByType[type]
            const lineChartData = metrics
              ?.map(({ created_at, value }) => {
                return {
                  time: format(new Date(created_at), 'HH:mm:ss'),
                  [t(type)]: value,
                  createdAt: created_at
                }
              })
              ?.sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )

            return (
              <LineChartMetric
                key={type}
                data={agent}
                lineChartData={lineChartData}
                type={type as Metric_Type_Enum}
              />
            )
          })}
        </>
      ) : null}
    </>
  )
}

export default AgentMetricCharts
